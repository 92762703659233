import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { UsersService } from "core/users/users.service";
import { ItemService } from "core/items/items.service";
import { LeadService } from "core/leads/leads.service";

@Component({
	selector: "kt-overview-start",
	templateUrl: "./overview-start.component.html",
	styleUrls: ["./overview-start.component.scss"],
})
export class OverviewStartComponent implements OnInit {
	items: any[];
	leadsUnanswered: any[];
	errors: any[] = [];
	me: any;
	todo: any;

	statsYear = new Date().getFullYear();
	stats: any;

	constructor(
		public itemService: ItemService,
		public leadService: LeadService,
		public userService: UsersService,
		private change: ChangeDetectorRef
	) {}

	async ngOnInit() {
		try {
			this.stats = await this.itemService.loadStatsByYear(this.statsYear);
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
		try {
			this.items = await this.itemService.loadLatest();
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
		try {
			this.leadsUnanswered = await this.leadService.getMineUnanswered();
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
		try {
			this.me = await this.userService.loadMe();
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
		try {
			this.todo = await this.userService.loadTodo();
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
	}
}
