import { Component, Input, Output, EventEmitter } from "@angular/core";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "user-select",
	templateUrl: "./user-select.component.html",
})
export class UserSelectComponent {
	@Input() btnClass: string;
	@Input() title: string;

	@Output() change: EventEmitter<any> = new EventEmitter();

	constructor(public users: UsersService) {}

	setSelection(option: any) {
		this.change.emit(option);
	}
}
