// Angular
import {
	Component,
	ViewEncapsulation,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
} from "@angular/core";

import { UsersService } from "core/users/users.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { AuthService } from "core/auth";

@Component({
	selector: "kt-base",
	templateUrl: "./base.component.html",
	styleUrls: ["./base.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
	today: number = Date.now();
	testVersion: boolean = false;
	error: string = null;

	mobile: MediaQueryList;
	private _mobileQueryListener: () => void;

	constructor(
		public users: UsersService,
		private change: ChangeDetectorRef,
		private authService: AuthService,
		media: MediaMatcher
	) {
		this.mobile = media.matchMedia("(max-width: 800px)");
		this._mobileQueryListener = () => change.detectChanges();
		this.mobile.addListener(this._mobileQueryListener);
	}

	ngOnInit() {
		if (
			window.location.host.includes("dev") ||
			window.location.host.includes("localhost")
		) {
			this.testVersion = true;
			window.document.title = "Testversion | NP";
		}
		this.users.loadMe().then(() => this.change.detectChanges());
	}

	ngOnDestroy(): void {
		this.mobile.removeListener(this._mobileQueryListener);
	}

	openLink(url): void {
		window.open(url, "_blank");
	}

	overrideAuth(): void {
		let token = prompt("Enter:");
		if (!token) {
			return;
		}
		this.authService.setAuthToken(token);
		top.location.reload();
	}
}
