<div class="page-padding">
	<div class="alert alert-warning" *ngIf="error">
		{{ error }}
	</div>

	<div class="alert alert-info" *ngIf="!user && !error">Laddar...</div>

	<div *ngIf="user">
		<mat-card class="card-block">
			<mat-card-header>
				<mat-card-title>{{ user.fullname }} </mat-card-title>
				<mat-card-subtitle class="card-subtitle">{{
					user.email
				}}</mat-card-subtitle>
				<div class="card-header-toolbar right">
					<ul class="card-header-nav">
						<li>
							<a
								[routerLink]="['/salary', user.id]"
								class="fas fa-money-bill"
								routerLinkActive="active"
								title="Lön"
							></a>
							<a
								[href]="'/items?state=any&user=' + user.id"
								class="fas fa-building"
								routerLinkActive="active"
								title="Objekt"
							></a>
						</li>
					</ul>
				</div>
			</mat-card-header>
		</mat-card>
		<div class="row">
			<div class="col">
				<mat-card class="card-block">
					<mat-card-header>
						<mat-card-title>Bild</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<div>
							<img
								src="{{ user.picture?.url }}"
								alt="Profilbild saknas"
								class="user-picture"
							/>
						</div>
						<div class="upload-form">
							<input
								type="file"
								name="picture"
								(change)="uploadPicture('picture', $event)"
							/>
						</div>
					</mat-card-content>
				</mat-card>
				<mat-card class="card-block">
					<mat-card-header>
						<mat-card-title>Närbild</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<div>
							<img
								src="{{ user.closeup?.url }}"
								alt="Närbild saknas"
								class="user-picture"
							/>
						</div>
						<div class="upload-form">
							<input
								type="file"
								name="closeup"
								(change)="uploadPicture('closeup', $event)"
							/>
						</div>
					</mat-card-content>
				</mat-card>
				<mat-card class="card-block">
					<mat-card-header>
						<mat-card-title>Signatur</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<div>
							<img
								src="{{ user.signature?.url }}"
								alt="Signatur saknas"
								class="user-signature"
							/>
						</div>
						<div class="upload-form">
							<input
								type="file"
								name="signature"
								(change)="uploadSignature($event)"
							/>
						</div>
					</mat-card-content>
				</mat-card>
				<user-profile
					[user]="user"
					key="budget"
					title="Budget"
				></user-profile>
				<user-profile
					[user]="user"
					key="phone"
					title="Telefonnummer"
				></user-profile>
				<user-profile
					[user]="user"
					key="linkedin"
					title="LinkedIn-profil"
				></user-profile>
				<user-profile
					[user]="user"
					key="position"
					title="Position"
				></user-profile>
				<user-profile
					[user]="user"
					key="pitch"
					title="Ingress"
					block="true"
				></user-profile>
				<user-profile
					[user]="user"
					key="presentation"
					title="Presentation"
					block="true"
				></user-profile>
			</div>
			<div class="col">
				<user-roles [user]="user"></user-roles>
				<user-salary-breakpoints
					[user]="user"
				></user-salary-breakpoints>
			</div>
		</div>
	</div>
</div>
