import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UsersService } from "core/users/users.service";

@Component({
	selector: "goal-stats",
	templateUrl: "./goal-stats.component.html",
	styleUrls: ["./goal-stats.component.scss"],
})
export class GoalStatsComponent implements OnInit {
	errors: any[] = [];
	goal: any;

	constructor(
		private userService: UsersService,
		private change: ChangeDetectorRef
	) {}

	async ngOnInit() {
		try {
			this.goal = await this.userService.loadGoal();
		} catch (error) {
			this.errors.push(error.error);
		} finally {
			this.change.detectChanges();
		}
	}

	getMyPercentage() {
		if (this.goal.budget > 0) {
			return Math.round(
				(this.goal.earnedThisYear / this.goal.budget) * 100
			);
		}
		return "--";
	}

	getTeamPercentage() {
		if (this.goal.budget > 0) {
			return Math.round(
				(this.goal.teamThisYear / this.goal.teamBudget) * 100
			);
		}
		return "--";
	}
}
