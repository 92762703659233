<div *ngIf="item">
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>Intressenter</mat-card-title>
			<div class="card-header-toolbar right"></div>
		</mat-card-header>
		<mat-card-content>
			<table class="table" *ngIf="!loading && !error">
				<tr class="header">
					<td>
						<div>Företag</div>
						<div>E-post</div>
					</td>
					<td>
						<div>Namn</div>
						<div>Telefon</div>
					</td>
					<td>Rubrik</td>
					<td class="user-link">
						<i class="fas fa-user fa-lg"></i>
					</td>
					<td>Status</td>
				</tr>
				<tr *ngFor="let l of leads">
					<td>
						<div>{{ l.company }}</div>
						<div>
							<a class="table-link" href="mailto:{{ l.email }}">{{
								l.email
							}}</a>
						</div>
					</td>
					<td>
						<div>{{ l.fullname }}</div>
						<div>
							<i>{{ l.phone }}</i>
						</div>
					</td>
					<td>
						<table-link path="leads" [entity]="l"></table-link>
					</td>
					<td class="user-link">
						<user-link [user]="l.agent"></user-link>
					</td>
					<td>
						<lead-state-label [lead]="l"></lead-state-label>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>

<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>Registrera intressent</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<add-lead-form
			(created)="leadCreated($event)"
			[item]="item"
		></add-lead-form>
	</mat-card-content>
</mat-card>
