<mat-card>
	<mat-card-header>
		<mat-card-title>Materialmeny</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div class="row">
			<div class="col-md-6 col-xl">
				<h3>Uthyrning: Kontor</h3>
				<ul>
					<li>Företagspresentation</li>
					<li>Pitch: Fastighetsägare</li>
					<li>Pitch: Sittande hyresgäst</li>
					<li>Pitch: Lokalsök</li>
					<li>Pitch lång</li>
					<li>Onepager</li>
					<li>Prospekt</li>
					<li>Hyresanalys</li>
					<li>Marknadsrapport</li>
				</ul>
			</div>
			<div class="col-md-6 col-xl">
				<h3>Uthyrning: Retail</h3>
				<ul>
					<li>Företagspresentation</li>
					<li><i>Material under framtagande</i></li>
				</ul>
			</div>
			<div class="col-md-6 col-xl">
				<h3>Transaktion</h3>
				<ul>
					<li>Prepitch</li>
					<li>Guffas</li>
					<li>Guffas +</li>
					<li>Pitch</li>
					<li>Onepager</li>
					<li>Teaser</li>
					<li>IM</li>
				</ul>
			</div>
			<div class="col-md-6 col-xl">
				<h3>Finansiering</h3>
				<ul>
					<li>Pitch</li>
					<li>Finansieringsunderlag</li>
				</ul>
			</div>
		</div>
		<div class="row steps">
			<div class="col-lg-6">
				<div class="step">
					<div class="step-number badge badge-primary">1</div>
					<div class="row">
						<div class="col">
							Beställning till<br />
							<a href="mailto:alexander@newproperty.se"
								>alexander@newproperty.se</a
							><br />
							med följande info:
						</div>
						<div class="col">
							<ul>
								<li>Typ av material</li>
								<li>Deadline</li>
								<li>Övrigt</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg">
				<div class="step">
					<div class="step-number badge badge-primary">2</div>
					<div>
						Beställare bokar uppstartsmöte när all information finns
						på plats.
					</div>
				</div>
			</div>
			<div class="col-lg">
				<div class="step">
					<div class="step-number badge badge-primary">3</div>
					<div>Leverans av marknadens bästa material.</div>
				</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>
