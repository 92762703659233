<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>{{ title }}</mat-card-title>
		<div class="card-header-toolbar right">
			<div *ngIf="commissions && commissions.length == 0">
				<button
					mat-button
					[matMenuTriggerFor]="cornerMenu"
					class="btn btn-secondary"
				>
					Ändra
				</button>
				<mat-menu #cornerMenu="matMenu">
					<button mat-menu-item (click)="addZeroFee()">
						Registrera noll
					</button>
				</mat-menu>
			</div>
		</div>
	</mat-card-header>
	<mat-card-content *ngIf="commissions && commissions.length > 0">
		<table class="table">
			<tr class="header">
				<td><i class="fas fa-user fa-lg"></i></td>
				<td>Bolag</td>
				<td colspan="4">Faktura</td>
			</tr>
			<tr *ngFor="let c of commissions">
				<td>{{ c.user.fullname }}</td>
				<td *ngIf="c.invoice">{{ c.invoice.company.name }}</td>
				<td *ngIf="c.invoice">
					<table-link
						path="invoices"
						[entity]="c.invoice"
					></table-link>
				</td>
				<td *ngIf="c.invoice" class="invoice-amount">
					{{ c.amount | numberSv }}&nbsp;kr
				</td>
				<td *ngIf="c.invoice" class="invoice-state">
					<invoice-state-label
						[invoice]="c.invoice"
						(change)="change.detectChanges()"
					></invoice-state-label>
				</td>
				<td *ngIf="c.invoice" class="invoice-remove">
					<div *ngIf="c.invoice.state == 'credited'">
						<span
							class="badge badge-danger clickable"
							(click)="removeCommission(c)"
							>Ta&nbsp;bort</span
						>
					</div>
				</td>
				<td *ngIf="!c.invoice" colspan="3">&nbsp;</td>
				<td *ngIf="!c.invoice"><em>Inget arvode</em></td>
			</tr>
		</table>
	</mat-card-content>
	<mat-card-content *ngIf="commissions">
		<hr />
		<form [formGroup]="invoiceForm" (ngSubmit)="addFee()">
			<div class="row">
				<div class="col">
					<p>
						<label>Företag</label>
						<select
							formControlName="companyId"
							class="form-control"
						>
							<option *ngFor="let c of companies" [value]="c.id">
								{{ c.name }}
							</option>
						</select>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Fakturanummer</label>
						<input
							formControlName="invoiceNumber"
							type="text"
							class="form-control"
						/>
					</p>
				</div>
				<div class="col">
					<p>
						<label>{{ title }} (ex moms)</label>
						<input
							formControlName="fee"
							type="text"
							class="form-control"
						/>
					</p>
				</div>
				<div class="col">
					<p>
						<label>&nbsp;</label>
						<input
							type="submit"
							value="Registrera"
							[disabled]="!invoiceForm.valid || working"
							class="form-control btn btn-primary"
						/>
					</p>
				</div>
				<!--
		-->
			</div>
			<p *ngIf="error">
				<span class="alert alert-warning">
					{{ error }}
					<a
						*ngIf="invoiceLink"
						class="btn btn-sm btn-warning"
						href="/invoices/{{ invoiceLink.id }}"
						>Visa faktura</a
					>
				</span>
			</p>
		</form>
	</mat-card-content>
</mat-card>
