<div
	*ngIf="
		item &&
		!item.estimatedCommission &&
		item.state != 'withdrawn' &&
		item.state != 'rented'
	"
>
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>Uppskattat arvode</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div class="form-group">
				<label
					>För att publicera, ange först uppskattat totalt arvode för
					hela affären:</label
				>
				<input #estimate type="text" class="form-control" />
			</div>
			<div class="form-group">
				<label
					>Eventuell minnesanteckning om fördelning av
					provision:</label
				>
				<input #cnote type="text" class="form-control" />
			</div>
			<div class="form-group">
				<button
					class="form-control btn-primary"
					(click)="
						setEstimatedCommission(estimate.value, cnote.value)
					"
				>
					Spara
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
<div *ngIf="item && item.estimatedCommission && item.state != 'withdrawn'">
	<item-publish></item-publish>
</div>

<onepager [item]="item"></onepager>

<item-copy></item-copy>

<div *ngIf="item && item.estimatedCommission && item.state != 'withdrawn'">
	<mat-card
		class="card-block"
		*ngIf="
			item &&
			!item.contract &&
			(item.state == 'new' || item.state == 'available')
		"
	>
		<mat-card-header>
			<mat-card-title>Registrera uthyrning</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<form [formGroup]="contractForm" (ngSubmit)="createContract()">
				<div class="row">
					<div class="col form-group">
						<form-label
							t="Hyresgäst"
							[c]="ctls.tenantName"
						></form-label>
						<input
							formControlName="tenantName"
							type="text"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Orgnummer"
							[c]="ctls.orgNumber"
						></form-label>
						<input
							formControlName="orgNumber"
							type="text"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Uthyrd area, m²"
							[c]="ctls.size"
						></form-label>
						<input
							formControlName="size"
							type="number"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Avtalad bashyra, kr/m²/år"
							[c]="ctls.rent"
						></form-label>
						<input
							formControlName="rent"
							type="number"
							class="form-control"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col form-group">
						<form-label
							t="Förnamn kontaktperson"
							[c]="ctls.firstname"
						></form-label>
						<input
							formControlName="firstname"
							type="text"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Efternamn kontaktperson"
							[c]="ctls.lastname"
						></form-label>
						<input
							formControlName="lastname"
							type="text"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="E-post kontaktperson"
							[c]="ctls.email"
						></form-label>
						<input
							formControlName="email"
							type="email"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Telefonnummer"
							[c]="ctls.phone"
						></form-label>
						<input
							formControlName="phone"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col form-group">
						<form-label
							t="Startdatum"
							[c]="ctls.start"
						></form-label>
						<input
							formControlName="start"
							type="date"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label t="Slutdatum" [c]="ctls.end"></form-label>
						<input
							formControlName="end"
							type="date"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<form-label
							t="Uppsägningstid, månader"
							[c]="ctls.notice"
						></form-label>
						<input
							formControlName="notice"
							type="number"
							class="form-control"
						/>
					</div>
					<div class="col form-group">
						<br />
						<input type="checkbox" formControlName="oneShot" />
						<label>&nbsp;One shot, one kill</label>
					</div>
				</div>
				<div class="form-group">
					<label>Fritext</label>
					<textarea
						formControlName="comments"
						class="form-control"
					></textarea>
				</div>
				<hr />
				<div formGroupName="tenantAudit">
					<div class="row">
						<div class="col form-group">
							<label>
								Har bolaget kontor i Sverige idag?
								<span
									*ngIf="
										invalid(
											ctls.tenantAudit.controls
												.currentSwedishOffice
										)
									"
									class="invalid-form-field-symbol"
									>😵</span
								>
							</label>
							<input
								type="text"
								class="form-control"
								formControlName="currentSwedishOffice"
							/>
						</div>
						<div class="col form-group">
							<label>
								Har bolaget onormalt hög budget?
								<span
									*ngIf="
										invalid(
											ctls.tenantAudit.controls
												.excessiveBudget
										)
									"
									class="invalid-form-field-symbol"
									>😵</span
								>
							</label>
							<input
								type="text"
								class="form-control"
								formControlName="excessiveBudget"
							/>
						</div>
						<div class="col form-group">
							<label>
								Är bolaget svenskt?
								<span
									*ngIf="
										invalid(
											ctls.tenantAudit.controls
												.swedishCompany
										)
									"
									class="invalid-form-field-symbol"
									>😵</span
								>
							</label>
							<input
								type="text"
								class="form-control"
								formControlName="swedishCompany"
							/>
						</div>
						<div class="col form-group">
							<label>
								Vad är anledningen till att bolaget flyttar?
								<span
									*ngIf="
										invalid(
											ctls.tenantAudit.controls
												.reasonForMoving
										)
									"
									class="invalid-form-field-symbol"
									>😵</span
								>
							</label>
							<input
								type="text"
								class="form-control"
								formControlName="reasonForMoving"
							/>
						</div>
					</div>
				</div>
				<hr />
				<p *ngIf="error">
					<span class="alert alert-warning">{{ error }}</span>
				</p>
				<p>
					<input
						type="submit"
						value="Registrera"
						[disabled]="!contractForm.valid || working"
						class="form-control btn btn-primary"
					/>
				</p>
			</form>
		</mat-card-content>
	</mat-card>

	<mat-card class="card-block" *ngIf="item && item.state == 'rented'">
		<mat-card-header>
			<mat-card-title>Avslutad affär</mat-card-title>
			<div class="card-header-toolbar right">
				<span class="badge badge-primary" *ngIf="item.contract.oneShot"
					>One shot, one kill</span
				>
			</div>
		</mat-card-header>
		<mat-card-content>
			<div class="row">
				<div class="col">
					<p>
						<label>Hyresgäst</label><br />
						<span>{{ item.contract.tenantName }}</span>
					</p>
				</div>
				<div class="col">
					<p>
						<label>&nbsp;</label><br />
						<span>{{ item.contract.orgNumber }}</span>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Kontaktperson</label><br />
						<a href="mailto:{{ item.contract.email }}">
							{{ item.contract.contact }} &lt;{{
								item.contract.email
							}}&gt;
						</a>
					</p>
				</div>
				<div class="col">
					<p>
						<label>&nbsp;</label><br />
						<span>{{ item.contract.phone }}</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<p>
						<label>Avtalad bashyra</label><br />
						<span
							>{{ item.contract.rent | numberSv }} kr/m²/år</span
						>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Uthyrd area</label><br />
						<span>{{ item.contract.size | numberSv }} m²</span>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Startdatum</label><br />
						<span>{{ item.contract.start }}</span>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Slutdatum</label><br />
						<span>{{ item.contract.end }}</span>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Uppsägningstid</label><br />
						<span *ngIf="item.contract.notice"
							>{{ item.contract.notice }} månader</span
						>
					</p>
				</div>
			</div>
			<p>
				<label>Fritext</label><br />
				<span>{{ item.contract.comments }}</span>
			</p>
		</mat-card-content>
	</mat-card>
</div>

<div
	class="alert alert-secondary"
	*ngIf="item && item.assignments?.length == 0"
>
	Uppdragsavtal krävs för att kunna registrera avslutsarvode.
</div>

<item-invoice
	title="Uppstartsarvode"
	type="startup"
	[item]="item"
	*ngIf="item && item.assignments?.length > 0"
></item-invoice>

<item-invoice
	title="Provision"
	type="closing"
	[item]="item"
	*ngIf="item && item.state == 'rented' && item.assignments?.length > 0"
></item-invoice>

<mat-card class="card-block" *ngIf="canBeWithdrawn()">
	<mat-card-header>
		<mat-card-title>Återtagning</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<p>
			Återtag lokalen om uppdraget avbrutits och affären inte kommer
			fullföljas.
		</p>
		<hr />
		<p *ngIf="withdrawError">
			<span class="alert alert-warning">{{ withdrawError }}</span>
		</p>
		<p>
			<button class="form-control btn btn-secondary" (click)="withdraw()">
				Återtag
			</button>
		</p>
	</mat-card-content>
</mat-card>
