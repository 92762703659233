<div *ngIf="item">
	<div class="row">
		<div class="col">
			<mat-card class="full-height">
				<mat-card-header>
					<mat-card-title>Kopiera objektet</mat-card-title>
				</mat-card-header>
				<mat-card-content *ngIf="!copy.active">
					<button
						class="btn btn-block btn-primary"
						(click)="copy.active = true"
					>
						Skapa kopia
					</button>
				</mat-card-content>
				<mat-card-content *ngIf="copy.active">
					<div class="alert alert-primary">
						Detta kommer skapa ett nytt objekt med samma
						beskrivning.
					</div>
					<form [formGroup]="copyForm" (ngSubmit)="submitItemCopy()">
						<div class="form-group">
							<label>Adress/benämning</label>
							<input
								type="text"
								class="form-control"
								formControlName="name"
							/>
						</div>
						<div class="form-group">
							<label>Fastighetsbeteckning</label>
							<input
								type="text"
								class="form-control"
								formControlName="property"
							/>
						</div>
						<div class="form-group">
							<label>Kommun</label>
							<input
								type="text"
								class="form-control"
								formControlName="municipality"
							/>
						</div>
						<div class="form-group" *ngIf="!copy.working">
							<input
								type="submit"
								class="form-control btn btn-primary"
								value="Skapa"
								*ngIf="copyForm.valid; else invalid"
							/>
							<ng-template #invalid>
								<div class="alert alert-secondary">
									Fyll i fälten ovan.
								</div>
							</ng-template>
						</div>
						<div class="alert alert-secondary" *ngIf="copy.working">
							Sparar...
						</div>
						<div class="alert alert-danger" *ngIf="copy.error">
							{{ copy.error }}
						</div>
					</form>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
