<form
	[formGroup]="form.controls"
	(ngSubmit)="submitProspect()"
	*ngIf="!form.working && !form.success"
>
	<div class="row" formGroupName="landlord">
		<div class="col form-group">
			<label>Hyresvärd</label>
			<input type="text" class="form-control" formControlName="name" />
		</div>
		<div class="col form-group">
			<label>Organisationsnummer</label>
			<input
				type="text"
				class="form-control"
				formControlName="orgNumber"
			/>
		</div>
		<div class="col form-group">
			<label>Kontaktperson</label>
			<input type="text" class="form-control" formControlName="contact" />
		</div>
		<div class="col form-group">
			<label>E-post</label>
			<input type="text" class="form-control" formControlName="email" />
		</div>
		<div class="col form-group">
			<label>Telefonnummer</label>
			<input type="text" class="form-control" formControlName="phone" />
		</div>
	</div>
	<div class="row">
		<div class="col-6 form-group">
			<label>Adress/benämning</label>
			<input type="text" class="form-control" formControlName="title" />
		</div>
		<div class="col form-group">
			<label>Storlek (m²)</label>
			<input type="text" class="form-control" formControlName="size" />
		</div>
	</div>
	<div class="row">
		<div class="col form-group">
			<label>Nuvarande hyresgäst</label>
			<input
				type="text"
				class="form-control"
				formControlName="tenantName"
			/>
		</div>
		<div class="col form-group">
			<label>Slutdatum</label>
			<input
				type="text"
				class="form-control"
				formControlName="expirationDate"
			/>
		</div>
		<div class="col form-group">
			<label>Uppsägningstid (månader)</label>
			<input type="text" class="form-control" formControlName="notice" />
		</div>
		<div class="col form-group">
			<label>Hyra</label>
			<input type="text" class="form-control" formControlName="rent" />
		</div>
	</div>
	<div class="row">
		<div class="col form-group">
			<label>Fritext</label>
			<textarea class="form-control" formControlName="message"></textarea>
		</div>
	</div>
	<div class="form-group" *ngIf="!error && !working">
		<form-state [form]="form"></form-state>
		<input
			type="submit"
			class="form-control btn btn-primary"
			value="Skapa"
			[disabled]="!form.isValid()"
		/>
	</div>
	<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
</form>
