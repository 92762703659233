<mat-card class="full-height">
	<mat-card-header>
		<mat-card-title>{{ name }}</mat-card-title>
		<div class="card-header-toolbar right">
			<a *ngIf="getLink()" [href]="getLink()" target="_blank"
				><i class="fas fa-lg fa-external-link-alt"></i
			></a>
		</div>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="error">
			<span class="badge badge-danger">{{ error }}</span>
		</div>
		<div *ngIf="working">
			<i class="fas fa-lg fa-sync fa-spin"></i>
		</div>
		<div *ngIf="!working && !error">
			<div class="button-group" *ngIf="isPublished()">
				<button
					class="btn btn-outline-danger"
					(click)="setPublish(false)"
				>
					Ta bort
				</button>
				<button
					*ngIf="republish && isAvailable()"
					class="btn btn-outline-primary"
					(click)="setPublish(true)"
				>
					Uppdatera
				</button>
			</div>
			<div *ngIf="!isPublished() && isAvailable()">
				<button
					class="btn btn-block btn-outline-success"
					(click)="setPublish(true)"
				>
					Publicera
				</button>
			</div>
		</div>
	</mat-card-content>
</mat-card>
