import { Component, OnInit, ChangeDetectorRef, Input } from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { RequestForm } from "../../../../partials/form-state/form-state.component";

import { Observable, of } from "rxjs";
import {
	catchError,
	map,
	switchMap,
	debounceTime,
	distinctUntilChanged,
} from "rxjs/operators";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "item-assignment",
	templateUrl: "./item-assignment.component.html",
})
export class ItemAssignmentComponent implements OnInit {
	@Input("item")
	item: any;

	documentUpload = new RequestForm(true);
	assignmentForm = new RequestForm(
		true,
		new UntypedFormGroup({
			customerOrgNumber: new UntypedFormControl("", [
				Validators.required,
			]),
			customerName: new UntypedFormControl("", [Validators.required]),
			document: new UntypedFormControl("", [Validators.required]),
		})
	);

	enableOrgNumber: boolean = false;
	onCustomerNameChange(event: any) {
		if (!this.assignmentForm.controls.value.customerName) {
			this.enableOrgNumber = false;
			this.assignmentForm.controls.patchValue({ customerOrgNumber: "" });
		} else if (!this.assignmentForm.controls.value.customerOrgNumber) {
			this.enableOrgNumber = true;
		}
		console.log("the value was changed");
		console.log(this.assignmentForm.controls.value);
	}

	working: boolean = false;
	error: string = null;

	constructor(
		private http: HttpClient,
		private itemService: ItemService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {}

	uploadDocument(files: File[]) {
		const formData = new FormData();
		formData.append("file", files[0]);
		this.documentUpload.setWorking();
		this.http
			.post("/api/files", formData)
			.subscribe(
				(file: any) => {
					this.documentUpload.setSuccess(file.name);
					this.assignmentForm.controls.patchValue({
						document: file.hash,
					});
				},
				(error) => {
					this.documentUpload.setError(error.error);
				}
			)
			.add(() => this.change.detectChanges());
	}

	submitAssignment() {
		let value = this.assignmentForm.controls.value;
		this.http
			.post(`/api/items/${this.item.id}/assignment`, value)
			.subscribe(
				(assignment: any) => {
					this.item.assignments.push(assignment);
					this.itemService.itemChanged.next({
						assignment: assignment,
					});
					this.assignmentForm.reset();
					this.documentUpload.reset();
				},
				(error) => {
					this.assignmentForm.setError(error.error);
				}
			)
			.add(() => this.change.detectChanges());
	}

	generateCustomerAutocomplete() {
		let PARAMS = new HttpParams();
		return (text$: Observable<string>) =>
			text$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				switchMap((term) =>
					this.http
						.get("/api/contracts/autocomplete", {
							params: PARAMS.set("search", term),
						})
						.pipe(map((response) => response))
						.pipe(
							catchError(() => {
								return of([]);
							})
						)
				)
			);
	}
	matchCustomers = this.generateCustomerAutocomplete();

	selectedCustomerName($event) {
		// Prevent json item from being stored in text field
		$event.preventDefault();
		// Update customer org number and name in form
		this.assignmentForm.controls.patchValue($event.item);
	}
}
