import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "user-link",
	templateUrl: "./user-link.component.html",
	styleUrls: ["./user-link.component.scss"],
})
export class UserLinkComponent implements OnInit {
	@Input("user")
	user: any;

	constructor() {}

	ngOnInit() {}

	getPictureUrl() {
		console.log(this.user.picture);
		if (this.user.closeup) {
			return this.user.closeup.url;
		} else if (this.user.picture) {
			return this.user.picture.url;
		} else {
			return null;
		}
	}
}
