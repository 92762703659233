import { Component, Input } from "@angular/core";

@Component({
	selector: "item-state-warning",
	templateUrl: "./item-state-warning.component.html",
	styleUrls: ["./item-state-warning.component.scss"],
})
export class ItemStateWarningComponent {
	@Input("item")
	item: any;
}
