import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthService {
	constructor(private http: HttpClient) {}

	logout() {
		localStorage.removeItem(environment.authTokenKey);
	}

	isLoggedIn(): boolean {
		return localStorage.getItem(environment.authTokenKey) ? true : false;
	}

	getAuthToken(): string {
		return localStorage.getItem(environment.authTokenKey);
	}

	setAuthToken(token: string): void {
		localStorage.setItem(environment.authTokenKey, token);
	}

	getOAuthURL(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.get("/api/auth/oauthurl", { responseType: "text" })
				.subscribe(
					(data: string) => resolve(data),
					(error) => reject(error)
				);
		});
	}

	loginOAuth(code: string) {
		return new Promise<string>((resolve, reject) => {
			this.http.post("/api/auth/oauthlogin", { code: code }).subscribe(
				(user: any) => {
					this.setAuthToken(user.accessToken);
					resolve(user);
				},
				(error) => reject(error)
			);
		});
	}
}
