import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "item-list-probability",
	templateUrl: "./item-list-probability.component.html",
	styleUrls: ["./item-list-probability.component.scss"],
})
export class ItemListProbabilityComponent {
	@Input()
	item: any;

	errorMessage = "";

	constructor(
		private service: ItemService,
		private change: ChangeDetectorRef
	) {}

	getLabel() {
		if (this.item.probability >= 75) {
			return { class: "badge-success", text: " 75+ " };
		} else if (this.item.probability >= 50) {
			return { class: "badge-warning", text: " 50+ " };
		} else if (this.item.probability > 0) {
			return { class: "badge-danger", text: "0-50" };
		} else {
			return { class: "badge-light", text: "   ?   " };
		}
	}

	setProbability(value: number) {
		this.service
			.setProbability(this.item.id, value)
			.then((item: any) => {
				this.item = item;
			})
			.catch((error) => (this.errorMessage = error.errorMessage))
			.then(() => this.change.detectChanges());
		this.item.probability = value;
	}
}
