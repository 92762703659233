import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
	selector: "form-state",
	templateUrl: "./form-state.component.html",
})
export class FormStateComponent {
	@Input() form: RequestForm;

	@Input() workingMessage: string = "Sparar";
}

export class RequestForm {
	working: boolean = false;
	error: string = null;
	success: string = null;

	display: boolean;

	constructor(
		private defaultDisplay: boolean,
		public controls: UntypedFormGroup = null
	) {
		this.display = this.defaultDisplay;
	}

	public show() {
		this.display = true;
	}

	public reset() {
		if (this.controls) {
			this.controls.reset();
		}
		this.display = this.defaultDisplay;
		this.working = false;
		this.error = null;
		this.success = null;
	}

	public setWorking() {
		this.working = true;
		this.error = null;
		this.success = null;
	}

	public setError(error: any) {
		if (typeof error === "string") {
			this.error = error;
		} else if (error) {
			for (let k of ["error", "message", "statusText", "status"]) {
				if (typeof error[k] === "string") {
					this.error = error[k];
					break;
				}
			}
		} else {
			this.error = error;
		}
		this.working = false;
		this.success = null;
	}

	public setSuccess(success: string) {
		this.working = false;
		this.error = null;
		this.success = success;
		if (this.controls) {
			this.controls.reset();
		}
	}

	public getValue(): any {
		if (this.controls) {
			return this.controls.value;
		}
		return {};
	}

	public isValid(): boolean {
		if (this.controls) {
			return this.controls.valid;
		}
		return false;
	}
}
