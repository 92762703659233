import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "item-publish",
	templateUrl: "./item-publish.component.html",
	styleUrls: ["./item-publish.component.scss"],
})
export class ItemPublishComponent implements OnInit {
	item;
	validation = {};

	copy = {
		active: false,
		working: false,
		error: null,
	};

	copyForm = new UntypedFormGroup({
		name: new UntypedFormControl("", [Validators.required]),
		property: new UntypedFormControl("", [Validators.required]),
		municipality: new UntypedFormControl("", [Validators.required]),
	});

	constructor(
		private itemService: ItemService,
		private route: ActivatedRoute,
		private http: HttpClient,
		public change: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.http
				.get("/api/items/" + params.get("itemID"))
				.subscribe((data) => {
					this.item = data;
					this.change.detectChanges();
					this.validate();
					this.getValues();
				});
		});
	}

	validate() {
		this.http
			.get("/api/items/" + this.item.id + "/publish/validation")
			.subscribe((data) => {
				this.validation = data;
				this.change.detectChanges();
			})
			.add(() => {
				this.change.detectChanges();
			});
	}

	getValues() {
		this.http
			.get("/api/items/" + this.item.id + "/values")
			.subscribe((data: any[]) => {
				for (let value of data) {
					switch (value.attribute.templateKey) {
						case "ITEM_TITLE":
							this.copyForm.controls["name"].setValue(value.text);
							break;
						case "PROPERTY_NAME":
							this.copyForm.controls["property"].setValue(
								value.text
							);
							break;
						case "MUNICIPALITY":
							this.copyForm.controls["municipality"].setValue(
								value.text
							);
							break;
					}
				}
				this.change.detectChanges();
			});
	}

	submitItemCopy() {
		this.copy.error = null;
		this.copy.working = true;
		this.http
			.post("/api/items/" + this.item.id + "/copy", this.copyForm.value)
			.subscribe(
				(data: any) => this.router.navigate(["/items", data.id]),
				(error) => (this.copy.error = "Fel: " + error.status)
			)
			.add(() => {
				this.copy.working = false;
				this.change.detectChanges();
			});
	}

	publishChanged(item) {
		console.log(item);
		this.item = item;
		this.change.detectChanges();
		this.itemService.itemChanged.next(item);
	}
}
