import { Component, OnInit } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { RequestForm } from "../../../partials/form-state/form-state.component";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "fetch-invoice",
	templateUrl: "./fetch-invoice.component.html",
})
export class FetchInvoiceComponent implements OnInit {
	companies: any[];

	form = new RequestForm(
		true,
		new UntypedFormGroup({
			companyId: new UntypedFormControl(null, [
				Validators.required,
				Validators.min(1),
			]),
			invoiceNumber: new UntypedFormControl("", [Validators.required]),
		})
	);

	constructor(
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private userService: UsersService,
		private router: Router
	) {}

	ngOnInit() {
		this.loadCompanies();
		this.form.controls.controls.companyId.setValue(3); // NP Group
	}

	loadCompanies() {
		this.http.get("/api/companies").subscribe((data: any[]) => {
			this.companies = data;
			this.change.detectChanges();
		});
	}

	fetchInvoice() {
		this.form.setWorking();
		this.http.post("/api/invoices", this.form.getValue()).subscribe(
			(invoice: any) => {
				this.router.navigate(["/invoices", invoice.id]);
			},
			(error) => {
				this.form.setError(error.error);
				this.change.detectChanges();
			}
		);
	}
}
