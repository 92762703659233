import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { debounce } from "ts-debounce";
import { MatDialog } from "@angular/material/dialog";

import { UsersService } from "core/users/users.service";
import { LeadService } from "core/leads/leads.service";

import { LeadListExportDialog } from "./export/lead-list-export.component";

@Component({
	selector: "kt-lead-list",
	templateUrl: "./lead-list.component.html",
	styleUrls: ["./lead-list.component.scss"],
})
export class LeadListComponent implements OnInit {
	public loading: boolean = false;
	public importing: boolean = false;
	public error: string = null;
	public query: Params = null;
	public showAddForm = false;
	private leads: any[];

	stateOptions = [
		{ value: "unanswered", title: "Obesvarad" },
		{ value: "open", title: "Öppen" },
		{ value: "closed", title: "Stängd" },
	];

	constructor(
		private change: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private leadService: LeadService
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe((query) => {
			this.load(query);
		});
	}

	private load(query: Params) {
		this.query = query;
		this.loading = true;
		this.error = null;
		this.leadService
			.getLeads(query)
			.then((data: any[]) => (this.leads = data))
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.loading = false;
				this.change.detectChanges();
			});
	}

	search(value: string) {
		let query: any = { search: value };
		this.router.navigate([], {
			queryParams: query,
			queryParamsHandling: "merge",
		});
	}

	searchChange = debounce(this.search, 1000);

	import(source: string) {
		this.importing = true;
		this.error = null;
		this.leadService
			.import(source)
			.then((leads: any[]) => {
				for (let l of leads) {
					this.leads.unshift(l);
				}
			})
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.importing = false;
				this.change.detectChanges();
			});
	}

	importFile(source: string, fileInput: any) {
		let file: File = fileInput.target.files[0];
		this.importing = true;
		this.error = null;
		this.leadService
			.importFile(source, file)
			.then((leads: any[]) => {
				for (let l of leads) {
					this.leads.unshift(l);
				}
			})
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.importing = false;
				this.change.detectChanges();
			});
	}
	exportListCSV() {
		let exportDialog = this.dialog.open(LeadListExportDialog, {
			data: { leads: this.leads },
		});
	}

	leadCreated(lead: any) {
		this.leads.unshift(lead);
		this.change.detectChanges();
	}

	getNote(lead: any) {
		return getNote(lead);
	}
}
export function getNote(lead: any) {
	if (lead.notes) {
		let latest = lead.notes.reduce((a, b) => {
			return a.id > b.id ? a : b;
		});
		if (latest) {
			if (latest.message.length > 30) {
				return latest.message.substring(0, 25) + "...";
			} else {
				return latest.message;
			}
		}
	}
}
