<div *ngIf="user">
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>{{title}}</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<form [formGroup]="form.controls" (ngSubmit)="setValue()">
				<div class="row" *ngIf="!block">
					<div class="col-9">
						<input
							type="text"
							class="form-control"
							formControlName="value"
						/>
					</div>
					<div class="col-3">
						<input
							type="submit"
							class="btn btn-primary btn-block"
							value="Uppdatera"
							[disabled]="!form.isValid()"
						/>
					</div>
				</div>
				<div *ngIf="block">
					<div>
						<textarea
							type="text"
							class="form-control"
							formControlName="value"
						></textarea>
					</div>
					<br/>
					<div>
						<input
							type="submit"
							class="btn btn-primary btn-block"
							value="Uppdatera"
							[disabled]="!form.isValid()"
						/>
					</div>
				</div>
				<div style="padding-top: 10px">
					<form-state [form]="form"></form-state>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>
