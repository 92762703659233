<div class="error-list" *ngIf="errors.length > 0">
	<div class="alert alert-danger" *ngFor="let error of errors">
		{{ error }}
	</div>
</div>
<mat-card>
	<mat-card-content>
		<div class="wrapper" *ngIf="goal">
			<div class="metric">
				<div class="first">Min budget</div>
				<pie-chart
					[values]="[getMyPercentage()]"
					color="default"
				></pie-chart>
				<div class="second">{{ getMyPercentage() }} %</div>
				<div class="third">{{ goal.earnedThisYear | numberSv }} kr</div>
			</div>
			<div class="metric">
				<div class="first">Teamets budget</div>
				<pie-chart
					[values]="[getTeamPercentage()]"
					color="default"
				></pie-chart>
				<div class="second" *ngIf="!getTeamPercentage()">---</div>
				<div class="second" *ngIf="getTeamPercentage()">
					{{ getTeamPercentage() }} %
				</div>
				<div class="third" *ngIf="getTeamPercentage()">
					{{ goal.teamThisYear | numberSv }} kr
				</div>
			</div>
			<div class="metric">
				<div class="first">Heta uppdrag</div>
				<pie-chart
					[values]="[
						goal.count25,
						goal.count50,
						goal.count75,
						goal.count0
					]"
					color="traffic"
					type="absolute"
				></pie-chart>
				<div class="second">
					{{ goal.count25 }} - {{ goal.count50 }} - {{ goal.count75 }}
				</div>
				<div class="third">25 - 50 - 75 %</div>
			</div>
			<div class="metric" style="display: none">
				<div class="first">Momentum</div>
				<ul>
					<li>ett</li>
					<li>två</li>
					<li>tre</li>
				</ul>
			</div>
			<div class="metric">
				<div class="first">Uthyrda av NP</div>
				<div class="chart-placeholder">
					<i class="fas fa-file-signature"></i>
				</div>
				<div class="second">{{ goal.rentedThisYear }} i år</div>
				<div class="third">({{ goal.rentedLastYear }} förra året)</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>
