<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>Skapa objekt</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="createForm" (ngSubmit)="submitItem()">
			<div class="row">
				<div class="col form-group">
					<label>Adress/benämning</label>
					<input
						type="text"
						class="form-control"
						formControlName="name"
					/>
				</div>
				<div class="col form-group">
					<label>Fastighetsbeteckning</label>
					<input
						type="text"
						class="form-control"
						formControlName="property"
					/>
				</div>
				<div class="col form-group">
					<label>Kommun</label>
					<input
						type="text"
						class="form-control"
						formControlName="municipality"
					/>
				</div>
			</div>
			<div class="form-group" *ngIf="!error && !working">
				<input
					type="submit"
					class="form-control btn btn-primary"
					value="Skapa"
					*ngIf="createForm.valid; else invalid"
				/>
				<ng-template #invalid>
					<div class="alert alert-secondary">Fyll i fälten ovan.</div>
				</ng-template>
			</div>
			<div class="alert alert-secondary" *ngIf="working">Sparar...</div>
			<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
		</form>
	</mat-card-content>
</mat-card>
