import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ItemService } from "core/items/items.service";
import { MatDialog } from "@angular/material/dialog";

import { debounce } from "ts-debounce";

import { ItemListExportDialog } from "./export/item-list-export.component";

@Component({
	selector: "kt-item-list",
	templateUrl: "./item-list.component.html",
	styleUrls: ["./item-list.component.scss"],
})
export class ItemListComponent implements OnInit {
	items: any = [];
	public attributes: any;
	columns = [];
	publishedOnly: boolean = false;

	filters = {
		state: {
			title: "Status",
			options: [
				{ value: "any", title: "Alla" },
				{ value: "new", title: "Nya" },
				{ value: "new-available", title: "Nya/Lediga" },
				{ value: "available", title: "Lediga" },
				{ value: "rented", title: "Uthyrda" },
				{ value: "withdrawn", title: "Återtagna" },
			],
			queryParam: "state",
		},
	};
	dropdowns = [this.filters.state];
	public query: any = {};

	constructor(
		private change: ChangeDetectorRef,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private itemService: ItemService
	) {}

	ngOnInit() {
		let loadAttributes = this.itemService
			.loadAttributes()
			.then((attributes: any) => {
				this.attributes = attributes;
				this.attributes.sort((a1, a2) => (a1.name > a2.name ? 1 : -1));
				this.columns = [];
				for (let a of this.attributes) {
					if (a.listColumn > 0) {
						let col = {
							attribute: a,
							index: a.listColumn,
						};
						this.columns.push(col);
					}
				}
				this.columns.sort((a1, a2) => a1.index - a2.index);
			});
		Promise.all([loadAttributes]).then(() => {
			this.route.queryParams.subscribe((query) => this.navigate(query));
		});
	}

	navigate(query: any) {
		this.query = query;
		this.loadItems();
	}

	private loadItems() {
		let query = {
			...this.query,
			values: [],
			filters: [],
		};
		for (let c of this.columns) {
			query.values.push(c.attribute.id);
			query.filters.push(c.filter);
		}
		this.itemService
			.loadItems(query)
			.then((items: any[]) => this.filterItems(items));
	}

	private filterItems(items: any[]) {
		this.items = items.filter((item: any) => {
			return !this.publishedOnly || item.publishCount > 0;
		});
		this.change.detectChanges();
	}

	private reload = debounce(this.loadItems, 500);

	setColumn(column, id) {
		for (let a of this.attributes) {
			if (a.id == id) {
				column.attribute = a;
			}
		}
		this.reload();
	}

	setFilter(column, text) {
		column.filter = text;
		this.reload();
	}

	columnLimit() {
		if (["rented", "withdrawn"].includes(this.query.state)) {
			return 2;
		}
		return undefined;
	}

	exportList(type: string) {
		let exportDialog = this.dialog.open(ItemListExportDialog, {
			data: { type: type, items: this.items },
		});
	}

	togglePublishedOnly() {
		this.publishedOnly = !this.publishedOnly;
		this.loadItems();
	}
}
