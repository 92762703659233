import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Page } from "../Page";

@Component({
	selector: "kt-showroom",
	templateUrl: "./showroom.component.html",
	styleUrls: ["./showroom.component.scss"],
})
export class ShowroomComponent extends Page implements OnInit {
	item = null;
	nextItem = null;
	error: string = null;

	appVersionChanged = false;
	appVersionData = null;

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		this.loadNext();
	}

	loadNext() {
		let time = this.item == null ? 100 : 8000;
		this.checkAppVersion();
		this.http
			.get("/api/items/showroom/random")
			.subscribe(
				(response: any) => {
					let item = response.item;
					this.error = null;
					for (let c of item.contacts) {
						c.user.fullname = c.user.fullname.replace(" ", "\n");
					}
					this.nextItem = item;
					this.timeout(() => this.fadeOut(), time);
				},
				(error) => {
					this.error = error.error;
					this.nextItem = null;
					this.timeout(() => this.fadeOut(), 1000);
				}
			)
			.add(() => this.change.detectChanges());
	}

	private fadeOut() {
		if (this.item) {
			this.item.removed = true;
			this.change.detectChanges();
		}
		this.timeout(() => this.fadeIn(), 1000);
	}

	private fadeIn() {
		if (this.appVersionChanged) {
			console.log("App version changed");
			window.location.reload();
			return;
		}
		this.item = this.nextItem;
		this.change.detectChanges();
		this.timeout(() => this.loadNext(), 2000);
	}

	private checkAppVersion() {
		this.http
			.get("/", { responseType: "text" })
			.subscribe((response: string) => {
				if (this.appVersionData && this.appVersionData != response) {
					this.appVersionChanged = true;
					console.log("App version change detected");
				}
				this.appVersionData = response;
			});
	}
}
