import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { debounce } from "ts-debounce";

import { ContractService } from "core/contracts/contracts.service";

@Component({
	selector: "kt-contract-list",
	templateUrl: "./contract-list.component.html",
	styleUrls: ["./contract-list.component.scss"],
})
export class ContractListComponent implements OnInit {
	public loading: boolean = false;
	public error: string = null;
	public query: Params = null;
	public contracts: any[];

	stateOptions = [
		{ value: "open", title: "Öppen" },
		{ value: "closed", title: "Stängd" },
	];

	constructor(
		private change: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
		private contractService: ContractService
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe((query) => {
			this.load(query);
		});
	}

	private load(query: Params) {
		this.query = query;
		this.loading = true;
		this.error = null;
		this.contractService
			.getContracts(query)
			.then((data: any[]) => (this.contracts = data))
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.loading = false;
				this.change.detectChanges();
			});
	}

	search(value: string) {
		let query: any = { search: value };
		this.router.navigate([], {
			queryParams: query,
			queryParamsHandling: "merge",
		});
	}

	searchChange = debounce(this.search, 1000);
}
