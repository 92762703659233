import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "csv-download-button",
	templateUrl: "./csv-download-button.component.html",
	styleUrls: ["./csv-download-button.component.scss"],
})
export class CSVDownloadButtonComponent implements OnInit {
	url: string = "";
	@Input() content: string;
	@Input() filename: string;
	@Input() btnClass: string;

	constructor() {}

	ngOnInit() {
		const blob = new Blob([this.content], {
			type: "text/csv;charset=utf-8;",
		});
		this.url += URL.createObjectURL(blob);
	}
}
