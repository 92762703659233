import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";

export interface DialogData {
	items: Number[];
}

@Component({
	selector: "kt-map-start-export",
	templateUrl: "./map-start-export.component.html",
	styleUrls: ["./map-start-export.component.scss"],
})
export class MapStartExportDialog implements OnInit {
	working: boolean = true;
	error: null;
	mapurl: "";
	mapwidth: Number;
	mapheight: Number;
	items: any[] = [];
	warnings: string[] = [];
	reader: FileReader;

	zoomLevels = [
		{ value: "auto", title: "Zoom: auto" },
		{ value: 14, title: "Zoom: 14" },
		{ value: 13, title: "Zoom: 13" },
		{ value: 12, title: "Zoom: 12" },
		{ value: 11, title: "Zoom: 11" },
		{ value: 10, title: "Zoom: 10" },
	];
	mapZoomForm = new UntypedFormGroup({
		zoomLevel: new UntypedFormControl("auto", [Validators.required]),
	});

	constructor(
		private http: HttpClient,
		public dialogRef: MatDialogRef<MapStartExportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		let hidden = false;
		this.items = data.items;
	}

	ngOnInit() {
		this.reader = new FileReader();
		this.updateMap();
	}
	private updateMap() {
		this.working = true;
		this.mapurl = "";
		this.mapwidth = 640;
		this.mapheight = 640;
		const params = {
			items: this.items.join(","),
			size: `${this.mapwidth}x${this.mapheight}`,
			zoom: this.mapZoomForm.value.zoomLevel,
		};
		this.http
			.get(`/api/items/export/map`, {
				params: params,
				responseType: "blob",
			})
			.subscribe(
				(data) => {
					this.reader.readAsDataURL(data);
					this.reader.onload = (_event) => {
						this.mapurl += this.reader.result;
					};
				},
				(error) => {
					this.error = error.message;
				}
			);
		this.working = false;
	}
}
