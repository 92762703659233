import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { InvoiceService } from "core/invoices/invoices.service";

@Component({
	selector: "invoice-commissions",
	templateUrl: "./invoice-commissions.component.html",
	styleUrls: ["./invoice-commissions.component.scss"],
})
export class InvoiceCommissionsComponent implements OnInit {
	@Input("invoice")
	invoice: any;
	types: any[];

	constructor(private invoiceService: InvoiceService) {}

	ngOnInit() {
		this.types = this.invoiceService.types;
	}
}
