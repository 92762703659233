// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { LeadDetailComponent } from "./detail/lead-detail.component";
import { LeadDetailEditDialog } from "./detail/edit/lead-detail-edit.component";
import { LeadListComponent } from "./list/lead-list.component";
import { LeadListExportDialog } from "./list/export/lead-list-export.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		DragDropModule,
		MatCardModule,
		MatDialogModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: LeadListComponent,
			},
			{
				path: ":id",
				component: LeadDetailComponent,
			},
		]),
	],
	providers: [],
	declarations: [
		LeadDetailComponent,
		LeadDetailEditDialog,
		LeadListComponent,
		LeadListExportDialog,
	],
})
export class LeadsModule {}
