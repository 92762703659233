<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>Fotobeställning</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form-state [form]="form"></form-state>
		<div></div>
		<div *ngIf="item.photoOrders?.length == 0">
			<button
				class="btn btn-primary btn-block"
				[disabled]="form.working"
				(click)="createOrder()"
			>
				Skapa beställning
			</button>
		</div>
		<div *ngIf="item.photoOrders?.length > 0">
			<table class="table">
				<tr>
					<th>Referens</th>
					<th>Skapad</th>
					<th>Status</th>
				</tr>
				<tr *ngFor="let o of item.photoOrders">
					<td>
						<a href="{{ o.website }}" target="_blank">{{
							o.reference
						}}</a>
					</td>
					<td>{{ o.created | date : "yyyy-MM-dd" }}</td>
					<td [ngSwitch]="o.state">
						<div
							*ngSwitchCase="'draft'"
							class="badge badge-primary"
						>
							Utkast
						</div>
						<div
							*ngSwitchCase="'confirmed'"
							class="badge badge-warning"
						>
							Bekräftad
						</div>
						<div
							*ngSwitchCase="'delivered'"
							class="badge badge-success"
						>
							Levererad
						</div>
						<div
							*ngSwitchCase="'rejected'"
							class="badge badge-danger"
						>
							Avbruten
						</div>
						<div *ngSwitchDefault>{{ o.state }}</div>
					</td>
				</tr>
			</table>
		</div>
	</mat-card-content>
</mat-card>
