import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Params, Router, ActivatedRoute } from "@angular/router";
import { debounce } from "ts-debounce";

import { ProspectService } from "core/prospects/prospects.service";

@Component({
	selector: "kt-prospect-list",
	templateUrl: "./prospect-list.component.html",
	styleUrls: ["./prospect-list.component.scss"],
})
export class ProspectListComponent implements OnInit {
	public loading: boolean = false;
	public error: string = null;
	public showAddForm = false;
	public query: Params = null;
	private prospects: any[];

	constructor(
		private change: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
		private prospectService: ProspectService
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe((query) => {
			this.load(query);
		});
	}

	private load(query: Params) {
		this.query = query;
		this.loading = true;
		this.error = null;
		this.prospectService
			.getProspects(query)
			.then((data: any[]) => (this.prospects = data))
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.loading = false;
				this.change.detectChanges();
			});
	}

	search(value: string) {
		let query: any = { search: value };
		this.router.navigate([], {
			queryParams: query,
			queryParamsHandling: "merge",
		});
	}

	searchChange = debounce(this.search, 1000);

	prospectCreated(prospect: any) {
		this.prospects.unshift(prospect);
		this.change.detectChanges();
	}
}
