import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "table-link",
	templateUrl: "./table-link.component.html",
})
export class TableLinkComponent implements OnInit {
	@Input("path")
	path: string;

	@Input("entity")
	entity: any;

	@Input("suffix")
	suffix: string;

	constructor() {}

	ngOnInit() {}

	title(): string {
		if (this.entity.title) {
			return this.entity.title;
		} else if (this.entity.name) {
			return this.entity.name;
		} else if (this.entity.fullname) {
			return this.entity.fullname;
		} else if (this.entity.invoiceNumber) {
			return (
				this.entity.invoiceNumber +
				(this.entity.customer ? " " + this.entity.customer.name : "")
			);
		} else if (this.entity.id) {
			return this.entity.id;
		} else {
			return "---";
		}
	}

	link(): string[] {
		let link = ["/", this.path, this.entity.id];
		if (this.suffix) {
			link.push(this.suffix);
		}
		return link;
	}
}
