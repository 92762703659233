<mat-card>
	<mat-card-header>
		<mat-card-title>Registrera kundrelation</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form
			[formGroup]="form.controls"
			(ngSubmit)="submitSubscriber()"
			*ngIf="!form.working"
		>
			<div class="row">
				<div class="col form-group">
					<label>Förnamn</label>
					<input
						type="text"
						class="form-control"
						formControlName="firstname"
					/>
				</div>
				<div class="col form-group">
					<label>Efternamn</label>
					<input
						type="text"
						class="form-control"
						formControlName="lastname"
					/>
				</div>
			</div>
			<div class="form-group">
				<label>E-post</label>
				<input
					type="text"
					class="form-control"
					formControlName="email"
				/>
			</div>
			<div class="row">
				<div class="col form-group">
					<label>Relation</label>
					<select class="form-control" formControlName="relationship">
						<option
							*ngFor="let r of service.RELATIONSHIPS"
							[value]="r.value"
						>
							{{ r.title }}
						</option>
					</select>
				</div>
				<div class="col form-group">
					<label>Nivå</label>
					<select class="form-control" formControlName="level">
						<option
							*ngFor="let l of service.LEVELS"
							[value]="l.value"
						>
							{{ l.title }}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group" *ngIf="!error && !working">
				<form-state [form]="form"></form-state>
				<input
					type="submit"
					class="form-control btn btn-primary"
					value="Skapa"
					[disabled]="!form.isValid()"
				/>
			</div>
			<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
		</form>
	</mat-card-content>
</mat-card>
