import { Component, Input, OnChanges } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { RequestForm } from "../../../../partials/form-state/form-state.component";

@Component({
	selector: "user-profile",
	templateUrl: "./user-profile.component.html",
})
export class UserProfileComponent implements OnChanges {
	@Input() user: any;
	@Input() key: string;
	@Input() title: string;
	@Input() block: boolean;

	form = new RequestForm(
		true,
		new UntypedFormGroup({
			value: new UntypedFormControl("", Validators.required),
		})
	);

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}

	ngOnChanges() {
		if (this.user) {
			this.form.controls.setValue({ value: this.user[this.key] });
		}
	}

	setValue() {
		if (this.form.working) return;

		this.form.setWorking();
		let value = this.form.getValue().value;
		let payload = {};
		payload[this.key] = value;
		this.http
			.put(`/api/users/${this.user.id}/${this.key}`, payload)
			.subscribe(
				(_response: any) => {
					this.form.setSuccess(`${this.title} uppdaterades`);
					this.form.controls.setValue({ value: value });
				},
				(error) => this.form.setError(error.error)
			)
			.add(() => this.change.detectChanges());
	}
}
