<div class="page-padding">
	<mat-card *ngIf="showAddForm">
		<mat-card-header>
			<mat-card-title>Registrera intressent</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<add-lead-form (created)="leadCreated($event)"></add-lead-form>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-header>
			<mat-card-title>Intressenter</mat-card-title>
			<div class="card-header-toolbar">
				<ul class="card-header-nav dropdown-filters">
					<li>
						<div>Ansvarig</div>
						<div>
							<user-filter roles="item_user"></user-filter>
						</div>
					</li>
					<li>
						<div>Status</div>
						<div>
							<dropdown-select
								[options]="stateOptions"
								queryParam="state"
							></dropdown-select>
						</div>
					</li>
					<li>
						<div>Area m²</div>
						<div>
							<text-search
								placeholder="Min"
								queryParam="minsize"
								width="60px"
							></text-search>
							<text-search
								placeholder="Max"
								queryParam="maxsize"
								width="60px"
							></text-search>
						</div>
					</li>
				</ul>
			</div>
			<div class="card-header-toolbar right">
				<ul class="card-header-nav">
					<li>
						<i
							class="fas fa-table clickable"
							title="Exportera"
							(click)="exportListCSV()"
						></i>
					</li>
					<li>
						<i
							*ngIf="!importing"
							mat-button
							[matMenuTriggerFor]="importMenu"
							class="fas fa-plus-circle clickable"
							title="Importera"
						></i>
						<mat-menu #importMenu="matMenu">
							<button mat-menu-item class="import-citymark">
								<label>Citymark</label>
								<input
									type="file"
									name="leadfile"
									(change)="importFile('citymark', $event)"
								/>
							</button>
							<button mat-menu-item (click)="showAddForm = true">
								Registrera manuellt
							</button>
						</mat-menu>
					</li>
				</ul>
			</div>
		</mat-card-header>
		<mat-card-content>
			<p>
				<input
					type="text"
					class="form-control"
					#searchQuery
					(change)="search(searchQuery.value)"
					(keyup)="searchChange(searchQuery.value)"
					[value]="query.search || ''"
					placeholder="Sök"
				/>
			</p>
			<div class="alert alert-primary" *ngIf="loading">Laddar</div>
			<div class="alert alert-primary" *ngIf="importing">Importerar</div>
			<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
			<table class="table" *ngIf="!loading && !error">
				<tr class="header">
					<td>
						<div>Företag</div>
						<div>Namn</div>
					</td>
					<td>
						<div>Tillträde</div>
						<div>Önskad storlek</div>
					</td>
					<td>
						<div>Rubrik</div>
						<div>Notering</div>
					</td>
					<td>
						<div>Inkommit</div>
						<div>Objekt</div>
					</td>
					<td>Ansvarig</td>
					<td>
						<div>Status</div>
					</td>
				</tr>
				<tr *ngFor="let l of leads">
					<td>
						<div>
							{{ l.company }}&nbsp;
							<a *ngIf="l.email" href="mailto:{{ l.email }}"
								><i class="fas fa-paper-plane"></i
							></a>
						</div>
						<div>{{ l.fullname }}</div>
					</td>
					<td>
						<div>
							<div>{{ l.access_date || "&nbsp;" }}</div>
							<div>
								<number-range
									[min]="l.minsize"
									[max]="l.maxsize"
									unit="m²"
								></number-range>
							</div>
						</div>
					</td>
					<td>
						<div>
							<table-link path="leads" [entity]="l"></table-link>
						</div>
						<div class="latest-note">
							<div>{{ getNote(l) }}</div>
						</div>
					</td>
					<td>
						<div>
							<div>{{ l.created }}</div>
							<div>
								<table-link
									path="items"
									[entity]="l.item"
									suffix="leads"
								></table-link>
							</div>
						</div>
					</td>
					<td>
						<user-link [user]="l.user"></user-link>
					</td>
					<td>
						<lead-state-label [lead]="l"></lead-state-label>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
