<div class="page">
	<div>&nbsp;</div>
	<div>
		<div class="logo">
			<img src="./assets/media/logos/logo-dark.svg" alt="" />
		</div>
		<p>
			<a href="{{ oAuthUrl }}" *ngIf="oAuthUrl" class="btn btn-primary"
				>Logga in</a
			>
		</p>
	</div>
	<div class="copyright">© {{ today | date : "yyyy" }} Elmér Solutions</div>
</div>
