import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";

import { InvoiceService } from "core/invoices/invoices.service";
import { UsersService } from "core/users/users.service";
import { Invoice } from "core/entity/invoice";

@Component({
	selector: "kt-invoice-list",
	templateUrl: "./invoice-list.component.html",
	styleUrls: ["./invoice-list.component.scss"],
})
export class InvoiceListComponent implements OnInit {
	public invoices: any = [];
	types: any[];

	public filters = {
		user: {
			options: [],
			queryParam: "user",
		},
		state: {
			options: Invoice.STATES,
			queryParam: "state",
		},
	};
	public dropdowns = [this.filters.user, this.filters.state];

	public showFetchForm = false;

	private query: any = {};

	constructor(
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UsersService,
		private invoiceService: InvoiceService
	) {}

	ngOnInit() {
		let loadUsers = this.userService
			.usersAsOptions()
			.then((options: any[]) => {
				let filter = this.filters.user;
				filter.options = options;
				filter.options.unshift({ value: 0, title: "Alla användare" });
			});
		Promise.all([loadUsers]).then(() => {
			this.route.queryParams.subscribe((query) => this.navigate(query));
		});
		this.types = this.invoiceService.types;
	}

	navigate(query) {
		this.query = query;
		this.loadInvoices();
	}

	private loadInvoices() {
		this.invoiceService
			.loadInvoices(this.query)
			.then((invoices: any[]) => {
				this.invoices = invoices;
				this.change.detectChanges();
			})
			.catch((error) => {
				/*TODO*/
			});
	}
}
