import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class SalaryService {
	constructor(private http: HttpClient) {}

	getYear(userId: number, yearId: number): Promise<any> {
		let url = `/api/users/${userId}/salary/years/${yearId}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any) => {
					resolve(data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getSplits(userId: number, yearId: number | "draft"): Promise<any[]> {
		let url = `/api/users/${userId}/splits?year=${yearId}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					resolve(data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getTransactions(userId: number, yearId: number): Promise<any[]> {
		let url = `/api/users/${userId}/salary/transactions?year=${yearId}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					resolve(data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}
