import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";

import { ItemAutocompleteService } from "../../item-autocomplete.service";
import { ItemService } from "core/items/items.service";

class Value {
	attribute;
	text;
}

@Component({
	selector: "kt-item-attributes",
	templateUrl: "./item-attributes.component.html",
	styleUrls: ["./item-attributes.component.scss"],
})
export class ItemAttributesComponent implements OnInit {
	item;
	sections: any[];
	values;
	model;

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private itemService: ItemService,
		private change: ChangeDetectorRef,
		private autocomplete: ItemAutocompleteService
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.http
				.get("/api/items/" + params.get("itemID"))
				.subscribe((data) => {
					this.item = data;
					this.change.detectChanges();
					this.loadValues();
				});
		});
	}

	loadValues() {
		this.http
			.get("/api/items/" + this.item.id + "/values")
			.subscribe((data: any[]) => {
				this.values = {};
				for (let value of data) {
					this.values[value.attribute.id] = value;
				}
				this.change.detectChanges();
				this.loadSections();
			});
	}

	loadSections() {
		this.http.get("/api/sections").subscribe((data: any[]) => {
			this.sections = data;
			this.sections.sort((s1, s2) => s1.sort - s2.sort);
			for (let s of this.sections) {
				s.attributes.sort((a1, a2) => a1.sort - a2.sort);
				s.rows = [];
				for (let a of s.attributes) {
					this.buildOptions(a);
					a.autocomplete = this.autocomplete.generate({
						attribute: a,
					});
					if (!s.rows[a.sort]) {
						s.rows[a.sort] = [];
					}
					s.rows[a.sort].push(a);
				}
			}
			this.change.detectChanges();
		});
	}

	buildOptions(a) {
		a.otherOptions = [];
		for (let o of a.options) {
			let currentValue = this.values[a.id];
			let currentText = currentValue ? currentValue.text : "";
			if (o.text != currentText) {
				a.otherOptions.push(o);
			}
		}
	}

	saveValue(attribute, newText) {
		if (newText == "_SELECT_CUSTOM_TEXT") {
			attribute.type = "text";
			return; // Special dropdown case
		}
		let current = this.values[attribute.id];
		let currentText = current ? current.text : "";
		if (currentText == newText) {
			attribute.error = null;
			return; // No change
		}
		if (newText && attribute.requiredPattern) {
			if (!new RegExp(attribute.requiredPattern).test(newText)) {
				attribute.error = "Felaktigt format";
				console.error(
					"Value " +
						newText +
						" does not match pattern " +
						attribute.requiredPattern
				);
				return;
			}
		}
		attribute.working = true;
		attribute.error = null;
		let payload = {
			attributeId: attribute.id,
			text: newText,
		};
		this.http
			.put("/api/items/" + this.item.id + "/values", payload)
			.subscribe(
				(data: Value) => {
					this.values[data.attribute.id] = data;
					this.buildOptions(attribute);
					this.itemService.itemChanged.next(this.item);
				},
				(error) => {
					attribute.error = "Fel: " + error.status;
				}
			)
			.add(() => {
				attribute.working = false;
				this.change.detectChanges();
			});
	}

	saveRange(attribute, min, max) {
		if (min || max) {
			this.saveValue(attribute, min + "-" + max);
		} else {
			this.saveValue(attribute, "");
		}
	}
}
