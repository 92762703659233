import { Component, OnInit, Input } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";

@Component({
	selector: "item-tags",
	templateUrl: "./item-tags.component.html",
	styleUrls: ["./item-tags.component.scss"],
})
export class ItemTagsComponent implements OnInit {
	item: any;
	tags: any[];
	iTags: any;

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.http
				.get("/api/items/" + params.get("itemID"))
				.subscribe((data) => {
					this.item = data;
					this.change.detectChanges();
					this.loadItemTags();
				});
		});
	}

	loadItemTags() {
		this.http
			.get("/api/items/" + this.item.id + "/tags")
			.subscribe((data: any[]) => {
				this.iTags = {};
				for (let iTag of data) {
					this.iTags[iTag.tag.id] = iTag.tag;
				}
				this.change.detectChanges();
				this.loadTags();
			});
	}

	loadTags() {
		this.http.get("/api/tags").subscribe((data: any[]) => {
			this.tags = data;
			this.change.detectChanges();
		});
	}

	hasTag(t: any) {
		return this.iTags[t.id]?.id == t.id;
	}

	setTag(t: any, value: boolean) {
		let url = "/api/items/" + this.item.id + "/tags/" + t.id;
		let request: Observable<any>;
		if (value) {
			request = this.http.put(url, null);
		} else {
			request = this.http.delete(url);
		}
		request.subscribe((data: any) => {
			if (value) {
				this.iTags[t.id] = t;
			} else {
				delete this.iTags[t.id];
			}
			this.change.detectChanges();
		});
	}
}
