import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "kt-company-list",
	templateUrl: "./company-list.component.html",
	styleUrls: ["./company-list.component.scss"],
})
export class CompanyListComponent implements OnInit {
	companies: any[] = [];

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}

	ngOnInit() {
		this.http
			.get("/api/companies")
			.subscribe((companies: any[]) => {
				this.companies = companies;
			})
			.add(() => this.change.detectChanges());
	}

	authorizeFortnox(company: any) {
		console.log(company);
		this.http
			.get(`/api/companies/${company.id}/authorizeFortnox`)
			.subscribe((response: any) => {
				console.log(response);
				window.open(response.url, "_blank");
			})
			.add(() => this.change.detectChanges());
	}
}
