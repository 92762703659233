<div class="page-padding">
	<mat-card *ngIf="showFetchForm">
		<mat-card-content>
			<fetch-invoice></fetch-invoice>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Fakturor & Arvoden</mat-card-title>
			<div class="card-header-toolbar">
				<ul class="card-header-nav">
					<li *ngFor="let filter of dropdowns">
						<dropdown-select
							[options]="filter.options"
							[queryParam]="filter.queryParam"
						></dropdown-select>
					</li>
				</ul>
			</div>
			<div class="card-header-toolbar right">
				<button
					class="btn btn-secondary btn-sm"
					(click)="showFetchForm = true"
				>
					Importera faktura
				</button>
			</div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td class="user-link"><i class="fas fa-user fa-lg"></i></td>
					<td>Bolag</td>
					<td class="invoice-number">Faktura</td>
					<td>Avser</td>
					<td class="invoice-amount">Belopp</td>
					<td>Status</td>
					<td>Att fördela</td>
				</tr>
				<tr *ngFor="let invoice of invoices">
					<td class="user-link">
						<user-link [user]="invoice.user"></user-link>
					</td>
					<td>{{ invoice.company.name }}</td>
					<td class="invoice-number">
						<table-link
							path="invoices"
							[entity]="invoice"
						></table-link>
					</td>
					<td>
						<invoice-commissions
							[invoice]="invoice"
						></invoice-commissions>
					</td>
					<td class="invoice-amount">
						{{ invoice.amount | numberSv }}
					</td>
					<td class="invoice-state">
						<invoice-state-label
							[invoice]="invoice"
							(change)="change.detectChanges()"
						></invoice-state-label>
					</td>
					<td>
						<div *ngIf="invoice.remainingCommissions > 0">
							<span class="badge badge-primary"
								>{{
									invoice.remainingCommissions
								}}&nbsp;kr</span
							>
						</div>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
