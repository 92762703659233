<mat-card class="card-block" *ngIf="item">
	<mat-card-header>
		<mat-card-title>Uppdragsavtal</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngFor="let assignment of item.assignments">
		<hr />
		<div class="row">
			<div class="col">
				<p>
					{{ assignment.customerOrgNumber }}
				</p>
			</div>
			<div class="col">
				<p>
					{{ assignment.customerName }}
				</p>
			</div>
			<div class="col">
				<p>
					<a href="{{ assignment.document.url }}"
						><i class="fas fa-file-pdf"></i> Ladda ned</a
					>
				</p>
			</div>
		</div>
	</mat-card-content>
	<mat-card-content>
		<hr />
		<form
			[formGroup]="assignmentForm.controls"
			(ngSubmit)="submitAssignment()"
		>
			<div class="row">
				<ng-template #rt let-r="result" let-t="term">
					<ngb-highlight
						[result]="r.customerName"
						[term]="t"
					></ngb-highlight
					>,
					<span style="font-size: small">
						<ngb-highlight
							[result]="r.customerOrgNumber"
							[term]="t"
						></ngb-highlight>
					</span>
				</ng-template>
				<div class="col">
					<p>
						<label>Organisationsnummer</label>
						<input
							type="text"
							class="form-control"
							formControlName="customerOrgNumber"
							[readonly]="!enableOrgNumber"
						/>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Företagsnamn</label>
						<input
							type="text"
							class="form-control"
							formControlName="customerName"
							[ngbTypeahead]="matchCustomers"
							[resultTemplate]="rt"
							(change)="onCustomerNameChange($event)"
							(selectItem)="selectedCustomerName($event)"
						/>
					</p>
				</div>
				<div class="col">
					<p>
						<label>Signerat avtal (PDF)</label>
						<br />
						<input
							type="file"
							name="document"
							(change)="uploadDocument($event.target.files)"
							*ngIf="
								!documentUpload.working &&
								!documentUpload.success
							"
						/>
						<form-state [form]="documentUpload"></form-state>
					</p>
				</div>
			</div>
			<p>
				<form-state [form]="assignmentForm"></form-state>
				<input
					type="submit"
					value="Registrera"
					[disabled]="!assignmentForm.controls.valid"
					*ngIf="!assignmentForm.working"
					class="form-control btn btn-primary"
				/>
			</p>
		</form>
	</mat-card-content>
</mat-card>
