import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, Subscription } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ItemService {
	itemChanged = new Subject<any>();

	constructor(private http: HttpClient) {}

	loadItems(query: any): Promise<any[]> {
		let url = "/api/items";

		let conditions: string[] = [];
		if (query.user) {
			conditions.push("user=" + query.user);
		}
		if (query.title) {
			conditions.push("title=" + query.title);
		}
		if (query.values.length > 0) {
			conditions.push("values=" + query.values.join(","));
			conditions.push("filters=" + query.filters.join(","));
		}
		if (query.state && query.state != "any") {
			conditions.push("state=" + query.state);
		}
		if (query.districts) {
			conditions.push("districts=" + query.districts);
		}
		if (query.types) {
			conditions.push("types=" + query.types);
		}

		if (query.minsize) {
			conditions.push("minsize=" + query.minsize);
		}
		if (query.maxsize) {
			conditions.push("maxsize=" + query.maxsize);
		}
		if (query.minrent) {
			conditions.push("minrent=" + query.minrent);
		}
		if (query.maxrent) {
			conditions.push("maxrent=" + query.maxrent);
		}
		if (query.totalrent) {
			conditions.push("totalrent=" + query.totalrent);
		}
		if (query.startdate && query.state == "rented") {
			conditions.push("startdate=" + query.startdate);
		}
		if (query.enddate && query.state == "rented") {
			conditions.push("enddate=" + query.enddate);
		}

		if (conditions.length > 0) {
			url += "?" + conditions.join("&");
		}
		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					data.forEach((item) => {
						item.publishCount = 0;
						if (item.publishWeb) {
							item.publishCount++;
						}
						if (item.publishLokalguiden) {
							item.publishCount++;
						}
						if (item.publishObjektvision) {
							item.publishCount++;
						}
					});
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	loadAttributes(): Promise<any[]> {
		let url = "/api/attributes";

		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					data.sort((a1, a2) => (a1.name > a2.name ? 1 : -1));
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	loadDistricts(): Promise<any[]> {
		let url = "/api/v1/districts";

		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	districtsAsOptions(): Promise<any[]> {
		return this.loadDistricts().then((districts) => {
			let options = [];
			for (let d of districts) {
				options.push({ value: d, title: d });
			}
			return options;
		});
	}

	loadItemTypes(): Promise<any[]> {
		let url = "/api/itemtypes";

		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => resolve(data),
				(error) => reject(error)
			);
		});
	}

	async itemTypesAsOptions(): Promise<any[]> {
		const types = await this.loadItemTypes();
		let options = [];
		for (let d of types) {
			options.push({ value: d, title: d });
		}
		return options;
	}

	getItem(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.get(`/api/items/${id}`).subscribe(
				(item: any) => resolve(item),
				(error) => reject(error)
			);
		});
	}

	getSimilar(id: number): Promise<any[]> {
		return new Promise<any>((resolve, reject) => {
			this.http.get(`/api/items/${id}/similar`).subscribe(
				(data: any) => resolve(data.similar),
				(error) => reject(error)
			);
		});
	}

	getCost(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.get(`/api/items/${id}/cost`).subscribe(
				(cost: any) => resolve(cost),
				(error) => reject(error)
			);
		});
	}

	loadLatest(): Promise<any[]> {
		let url = "/api/items/latest";
		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => resolve(data),
				(error) => reject(error)
			);
		});
	}

	loadStats(months: number): Promise<any> {
		let url = `/api/stats/items?months=${months}`;
		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any) => resolve(data),
				(error) => reject(error)
			);
		});
	}

	loadStatsByYear(year: number): Promise<any> {
		let url = `/api/stats/items?year=${year}`;
		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any) => resolve(data),
				(error) => reject(error)
			);
		});
	}

	setProbability(id: number, value: number): Promise<any> {
		let url = `/api/items/${id}/probability`;
		let payload = { probability: value };
		return new Promise<any>((resolve, reject) => {
			this.http.put(url, payload).subscribe(
				(data) => resolve(data),
				(error) => reject(error)
			);
		});
	}
}
