<mat-card class="card-block" id="imglist">
	<mat-card-header>
		<mat-card-title>{{ header }}</mat-card-title>
		<div class="card-header-toolbar right"></div>
	</mat-card-header>
	<mat-card-content *ngIf="this.item">
		<div *ngIf="view == 'thumbnails' && type == 'image'">
			<div cdkDropListGroup>
				<div
					class="image-list-dragdrop"
					cdkDropList
					#imageList="cdkDropList"
					[cdkDropListData]="rowList"
					cdkDropListOrientation="horizontal"
					(cdkDropListDropped)="dragdrop2($event)"
					*ngFor="let rowList of this.imageLists"
				>
					<div *ngFor="let image of rowList" class="image" cdkDrag>
						<img
							src="{{ image.url }}?width=500"
							alt="{{ image.name }}"
						/>
						<div class="image-name" (click)="renameFile(image)">
							{{ image.name }}
						</div>
						<div class="hidden-overlay" *ngIf="!image.visible">
							<i class="fas fa-eye-slash"></i>
						</div>
						<div class="image-icons">
							<div
								title="Ändra ordning"
								class="reorder-handle"
								*ngIf="users.hasRole('item_user')"
							>
								<i class="fas fa-bars"></i>
							</div>
							<div title="Hämta original">
								<a href="{{ image.url }}" target="_blank">
									<i class="fas fa-download"></i
								></a>
							</div>
							<div
								title="{{ image.visible ? 'Dölj' : 'Visa' }}"
								(click)="setVisible(image, !image.visible)"
								*ngIf="users.hasRole('item_user')"
							>
								<i
									class="fas fa-eye-slash"
									*ngIf="image.visible"
								></i>
								<i
									class="fas fa-eye"
									*ngIf="!image.visible"
								></i>
							</div>
							<div
								title="Ta bort"
								(click)="removeFile(image)"
								*ngIf="users.hasRole('item_user')"
							>
								<i class="fas fa-trash-alt"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="view == 'thumbnails' && type != 'image'"
			class="image-list-dragdrop"
			cdkDropList
			#imageList="cdkDropList"
			[cdkDropListData]="this.files()"
			cdkDropListOrientation="vertical"
			(cdkDropListDropped)="dragdrop($event)"
		>
			<div *ngFor="let image of this.files()" class="image" cdkDrag>
				<img src="{{ image.url }}?width=300" alt="{{ image.name }}" />
				<div class="image-name" (click)="renameFile(image)">
					{{ image.name }}
				</div>
				<div class="image-icons">
					<div
						title="Ändra ordning"
						class="reorder-handle"
						*ngIf="users.hasRole('item_user')"
					>
						<i class="fas fa-bars"></i>
					</div>
					<div title="Hämta original">
						<a href="{{ image.url }}" target="_blank">
							<i class="fas fa-download"></i
						></a>
					</div>
					<div
						title="Ta bort"
						(click)="removeFile(image)"
						*ngIf="users.hasRole('item_user')"
					>
						<i class="fas fa-trash-alt"></i>
					</div>
				</div>
			</div>
		</div>
		<table class="table" *ngIf="view == 'list' && files().length > 0">
			<tr class="header">
				<th>Namn</th>
				<th>&nbsp;</th>
			</tr>
			<tr *ngFor="let a of files()" class="attachment">
				<td>
					<a href="{{ a.url }}" target="_blank" class="table-link">{{
						a.name
					}}</a>
				</td>
				<td class="remove-attachment">
					<span
						[matMenuTriggerFor]="copyMenu"
						class="fas fa-ellipsis-v clickable fa-fw"
					>
					</span>
					<mat-menu #copyMenu="matMenu">
						<button mat-menu-item (click)="renameFile(a)">
							<i class="fas fa-edit fa-fw"></i> Byt namn
						</button>
						<button mat-menu-item (click)="removeFile(a)">
							<i class="fas fa-trash fa-fw"></i> Ta bort
						</button>
					</mat-menu>
				</td>
			</tr>
		</table>
		<div class="upload-form">
			<div *ngIf="this.uploadError" class="badge badge-danger">
				{{ this.uploadError }}
			</div>
			<button
				*ngIf="uploadQueue.length == 0"
				class="btn btn-primary btn-block file-upload-overlay"
			>
				<input
					type="file"
					multiple
					name="image"
					(change)="uploadFile($event)"
				/>
				<div>Ladda upp</div>
			</button>
			<button
				*ngIf="uploadQueue.length > 0"
				class="btn btn-primary btn-block"
				disabled
			>
				<div>Laddar upp {{ uploadQueue.length }} filer...</div>
			</button>
		</div>
	</mat-card-content>
</mat-card>
