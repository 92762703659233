import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "kt-item-files",
	templateUrl: "./item-files.component.html",
	styleUrls: ["./item-files.component.scss"],
})
export class ItemFilesComponent implements OnInit {
	item: any;

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		public users: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.http
				.get("/api/items/" + params.get("itemID"))
				.subscribe((data) => {
					this.item = data;
					this.change.detectChanges();
				});
		});
	}

	changed() {
		this.change.detectChanges();
	}

	hasFiles(type: string) {
		return this.item[type] && this.item[type].length > 0;
	}
}
