<div>
	<svg height="200" width="200" viewBox="0 0 200 200">
		<circle
			r="100"
			cx="100"
			cy="100"
			fill="#BECDD6"
			*ngIf="color == 'default'"
		/>
		<circle
			*ngFor="let v of values; index as i"
			r="50"
			cx="100"
			cy="100"
			class="chartfill"
			fill="transparent"
			[attr.stroke]="getColor(i)"
			stroke-width="100"
			[attr.stroke-dasharray]="getStroke(i)"
			transform="rotate(-90) translate(-200)"
		/>
	</svg>
</div>
