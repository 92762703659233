<div class="page-padding">
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>Objekt</mat-card-title>
			<mat-card-subtitle *ngIf="items && items.length > 0"
				>{{ items.length }} träff<span *ngIf="items.length > 1"
					>ar</span
				></mat-card-subtitle
			>
			<div class="card-header-toolbar right">
				<ul class="card-header-nav">
					<li>
						<a
							class="fas fa-map"
							title="Visa på karta"
							[routerLink]="['/map']"
							[queryParams]="query"
						></a>
					</li>
					<li>
						<i
							(click)="exportList('email')"
							*ngIf="items.length > 0"
							class="fas fa-envelope clickable"
							title="Exportera för e-post"
						></i>
					</li>
					<li>
						<i
							(click)="exportList('csv')"
							*ngIf="items.length > 0"
							class="fas fa-table clickable"
							title="Exportera CSV"
						></i>
					</li>
					<li>
						<a
							class="fas fa-plus-circle"
							[routerLink]="['new']"
							title="Skapa nytt"
						></a>
					</li>
				</ul>
			</div>
		</mat-card-header>
		<mat-card-content>
			<div class="card-header-toolbar">
				<ul class="card-header-nav dropdown-filters">
					<li>
						<div>Adress</div>
						<div>
							<text-search
								placeholder=""
								queryParam="title"
								width="120px"
							></text-search>
						</div>
					</li>
					<li>
						<div>Ansvarig</div>
						<div>
							<user-filter roles="item_user"></user-filter>
						</div>
					</li>
					<li *ngFor="let filter of dropdowns">
						<div>
							{{ filter.title }}
						</div>
						<div>
							<dropdown-select
								[options]="filter.options"
								[queryParam]="filter.queryParam"
							></dropdown-select>
						</div>
					</li>
					<li>
						<div>Område</div>
						<div>
							<district-select
								queryParam="districts"
							></district-select>
						</div>
					</li>
					<li>
						<div>Typ</div>
						<div>
							<item-type-select
								queryParam="types"
							></item-type-select>
						</div>
					</li>
					<li>
						<div>Area m²</div>
						<div>
							<text-search
								placeholder="Min"
								queryParam="minsize"
								width="60px"
							></text-search>
							<text-search
								placeholder="Max"
								queryParam="maxsize"
								width="60px"
							></text-search>
						</div>
					</li>
					<li>
						<div>Hyra per m²</div>
						<div>
							<text-search
								placeholder="Min"
								queryParam="minrent"
								width="60px"
							></text-search>
							<text-search
								placeholder="Max"
								queryParam="maxrent"
								width="60px"
							></text-search>
						</div>
					</li>
					<li>
						<div>Månadshyra</div>
						<div>
							<text-search
								placeholder=""
								queryParam="totalrent"
								width="90px"
							></text-search>
						</div>
					</li>
					<li *ngIf="query.state == 'rented'">
						<div>Tillträdesdatum</div>
						<div>
							<date-search
								placeholder="Startdatum"
								queryParam="startdate"
								width="140px"
							></date-search>
							<date-search
								placeholder="Slutdatum"
								queryParam="enddate"
								width="140px"
							></date-search>
						</div>
					</li>
				</ul>
			</div>
			<table class="table">
				<tr class="header">
					<td>Adress</td>
					<td>Sannolikhet</td>
					<td>Område</td>
					<td
						*ngFor="let c of columns | slice : 1 : columnLimit()"
						class="column-{{ c.index }}"
					>
						<div>
							<select
								class="form-control form-control-sm"
								#newAttribute
								(change)="setColumn(c, newAttribute.value)"
							>
								<option
									*ngFor="let a of attributes"
									value="{{ a.id }}"
									[selected]="a.id == c.attribute.id"
								>
									{{ a.name }}
								</option>
							</select>
						</div>
					</td>
					<th *ngIf="query.state == 'rented'">Bashyra</th>
					<th *ngIf="query.state == 'rented'">Tillträdesdatum</th>
					<th *ngIf="['rented', 'withdrawn'].includes(query.state)">
						Uppstart
					</th>
					<th *ngIf="query.state == 'rented'">Provision</th>
					<th class="pub-button">
						<span
							class="badge clickable"
							[ngClass]="{
								'badge-secondary': !publishedOnly,
								'badge-primary': publishedOnly
							}"
							(click)="togglePublishedOnly()"
							title="Visa endast publicerade"
						>
							Pub
						</span>
					</th>
				</tr>
				<tr *ngFor="let item of items">
					<td class="item-name">
						<item-list-title [item]="item"></item-list-title>
					</td>
					<td class="deal-probability">
						<item-list-probability
							[item]="item"
						></item-list-probability>
					</td>
					<td class="item-districts">
						<span
							*ngFor="let d of item.districts"
							class="badge badge-secondary"
							>{{ d.name }}</span
						>
					</td>
					<td
						*ngFor="let c of columns | slice : 1 : columnLimit()"
						class="column-{{ c.index }}"
					>
						<div>
							{{ item["value_" + c.attribute.id]?.text }}
						</div>
					</td>
					<td *ngIf="query.state == 'rented'">
						<span>{{ item.contract?.rent | numberSv }}</span>
					</td>
					<td *ngIf="query.state == 'rented'">
						<span>{{ item.contract?.start }}</span>
					</td>
					<td *ngIf="['rented', 'withdrawn'].includes(query.state)">
						<div
							*ngIf="
								item.startupCommissions.length == 0;
								else hasStartup
							"
						>
							<a
								href="/items/{{ item.id }}/contract"
								class="badge badge-danger clickable"
								>Saknas</a
							>
						</div>
						<ng-template #hasStartup>
							<span class="badge badge-success">Färdig</span>
						</ng-template>
					</td>
					<td *ngIf="query.state == 'rented'">
						<div
							*ngIf="
								item.closingCommissions.length == 0;
								else hasClosing
							"
						>
							<a
								href="/items/{{ item.id }}/contract"
								class="badge badge-danger clickable"
								>Saknas</a
							>
						</div>
						<ng-template #hasClosing>
							<span class="badge badge-success">Färdig</span>
						</ng-template>
					</td>
					<td>
						<span
							class="badge badge-primary"
							*ngIf="item.publishCount > 0"
							>{{ item.publishCount }}</span
						>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
