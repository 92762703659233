import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
} from "@angular/core";

import { Prospect } from "core/entity/prospect";
import { ProspectService } from "core/prospects/prospects.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";

@Component({
	selector: "prospect-state-label",
	templateUrl: "./prospect-state-label.component.html",
	styleUrls: ["./prospect-state-label.component.scss"],
})
export class ProspectStateLabelComponent implements OnInit {
	@Input("prospect")
	prospect: any;

	@Output() change: EventEmitter<any> = new EventEmitter();

	states = Prospect.STATES;

	stateForm = new RequestForm(true);

	constructor(
		private changeDetector: ChangeDetectorRef,
		private prospectService: ProspectService
	) {}

	ngOnInit() {}

	setState(state: any) {
		if (state == this.prospect.state) return;
		this.stateForm.setWorking();
		this.prospectService
			.setState(this.prospect.id, state)
			.then((prospect: any) => {
				this.prospect.state = prospect.state;
				this.stateForm.reset();
				this.change.emit(prospect);
			})
			.catch((error: any) => this.stateForm.setError(error.error))
			.then(() => {
				this.changeDetector.detectChanges();
			});
		this.prospect.state = state;
	}
}
