import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class ContractService {
	constructor(private http: HttpClient) {}

	getContracts(query: any = {}): Promise<any[]> {
		let url = "/api/contracts";
		return new Promise((resolve, reject) => {
			this.http.get(url, { params: query }).subscribe(
				(contracts: any[]) => resolve(contracts),
				(error) => reject(error)
			);
		});
	}

	getContract(id: number): Promise<any> {
		let url = `/api/contracts/${id}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(contract: any) => resolve(contract),
				(error) => reject(error)
			);
		});
	}
}
