import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
} from "@angular/core";

import { Lead } from "core/entity/lead";
import { LeadService } from "core/leads/leads.service";
import { RequestForm } from "../form-state/form-state.component";

@Component({
	selector: "lead-state-label",
	templateUrl: "./lead-state-label.component.html",
	styleUrls: ["./lead-state-label.component.scss"],
})
export class LeadStateLabelComponent implements OnInit {
	@Input("lead")
	public lead: any;

	@Output() change: EventEmitter<any> = new EventEmitter();

	public states = Lead.STATES;

	public stateForm = new RequestForm(true);

	constructor(
		private changeDetector: ChangeDetectorRef,
		private leadService: LeadService
	) {}

	ngOnInit() {}

	setState(state: any) {
		if (state == this.lead.state) return;
		this.stateForm.setWorking();
		this.leadService
			.setState(this.lead.id, state)
			.then((lead: any) => {
				this.lead.state = lead.state;
				this.stateForm.reset();
				this.change.emit(lead);
			})
			.catch((error: any) => this.stateForm.setError(error.error))
			.then(() => {
				this.changeDetector.detectChanges();
			});
		this.lead.state = state;
	}
}
