<table>
	<tr *ngFor="let c of invoice.commissions">
		<td>
			<span *ngFor="let t of types">
				<span
					*ngIf="c.type == t.value"
					[className]="'badge badge-' + t.badge"
					>{{ t.title }}</span
				>
			</span>
		</td>
		<td>
			<div *ngIf="c.item">
				<table-link path="items" [entity]="c.item"></table-link>
			</div>
			<div *ngIf="!c.item">
				<span>{{ c.description }}</span>
			</div>
		</td>
	</tr>
</table>
