import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { PartialsModule } from "../../partials/partials.module";
import { ShowroomComponent } from "./showroom.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		NgbModule,
		MatCardModule,
		RouterModule.forChild([
			{
				path: "showroom",
				component: ShowroomComponent,
			},
		]),
	],
	providers: [],
	declarations: [ShowroomComponent],
})
export class DashboardModule {}
