// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { SalaryGenerateComponent } from "./generate/salary-generate.component";
import { SalaryListComponent } from "./list/salary-list.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		DragDropModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "generate",
				component: SalaryGenerateComponent,
			},
			{
				path: ":userID",
				component: SalaryListComponent,
			},
		]),
	],
	providers: [],
	declarations: [SalaryListComponent, SalaryGenerateComponent],
})
export class SalaryModule {}
