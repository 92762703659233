import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";

import { ItemService } from "core/items/items.service";
import { LeadService } from "core/leads/leads.service";

@Component({
	selector: "kt-item-leads",
	templateUrl: "./item-leads.component.html",
	styleUrls: ["./item-leads.component.scss"],
})
export class ItemLeadsComponent implements OnInit {
	item: any;
	leads: any[] = [];
	error: string = null;

	constructor(
		private route: ActivatedRoute,
		private change: ChangeDetectorRef,
		private itemService: ItemService,
		private leadService: LeadService
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			Promise.all([
				this.itemService
					.getItem(parseInt(params.get("itemID")))
					.then((item: any) => (this.item = item)),
				this.leadService
					.getLeads({ item: params.get("itemID") })
					.then((leads: any[]) => (this.leads = leads)),
			])
				.catch((error: any) => (this.error = error.error))
				.then(() => {
					this.change.detectChanges();
				});
		});
	}

	leadCreated(lead: any) {
		this.leads.push(lead);
		this.change.detectChanges();
	}
}
