// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
// Pages
import { DashboardModule } from "views/pages/dashboard/dashboard.module";
import { OverviewModule } from "views/pages/overview/overview.module";
import { InvoicesModule } from "views/pages/invoices/invoices.module";
import { ItemsModule } from "views/pages/items/items.module";
import { ContractsModule } from "views/pages/contracts/contracts.module";
import { CompanyModule } from "views/pages/company/company.module";
import { LeadsModule } from "views/pages/leads/leads.module";
import { ProspectsModule } from "views/pages/prospects/prospects.module";
import { UsersModule } from "views/pages/users/users.module";
import { StatsModule } from "views/pages/stats/stats.module";
import { SalaryModule } from "views/pages/salary/salary.module";
import { DocumentsModule } from "views/pages/documents/documents.module";
import { MapModule } from "views/pages/map/map.module";
import { AuthModule } from "views/pages/auth/auth.module";

const routes: Routes = [
	{ path: "auth", loadChildren: () => AuthModule },
	{
		path: "dashboard",
		loadChildren: () => DashboardModule,
	},
	{
		path: "",
		component: BaseComponent,
		children: [
			{
				path: "overview",
				loadChildren: () => OverviewModule,
			},
			{
				path: "invoices",
				loadChildren: () => InvoicesModule,
			},
			{
				path: "items",
				loadChildren: () => ItemsModule,
			},
			{
				path: "companies",
				loadChildren: () => CompanyModule,
			},
			{
				path: "contracts",
				loadChildren: () => ContractsModule,
			},
			{
				path: "leads",
				loadChildren: () => LeadsModule,
			},
			{
				path: "prospects",
				loadChildren: () => ProspectsModule,
			},
			{
				path: "users",
				loadChildren: () => UsersModule,
			},
			{
				path: "stats",
				loadChildren: () => StatsModule,
			},
			{
				path: "salary",
				loadChildren: () => SalaryModule,
			},
			{
				path: "documents",
				loadChildren: () => DocumentsModule,
			},
			{
				path: "map",
				loadChildren: () => MapModule,
			},
			{ path: "", redirectTo: "overview", pathMatch: "full" },
			{ path: "**", redirectTo: "overview", pathMatch: "full" },
		],
	},

	{ path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
