import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import { ItemService } from "core/items/items.service";

@Component({
	selector: "district-select",
	templateUrl: "./district-select.component.html",
})
export class DistrictSelectComponent implements OnInit {
	@Input() queryParam: string;

	@Output() change: EventEmitter<any> = new EventEmitter();

	options: any[] = [];

	constructor(private itemService: ItemService) {}

	ngOnInit() {
		this.itemService.districtsAsOptions().then((options: any[]) => {
			this.options = options;
		});
	}

	setSelection(option: any) {
		this.change.emit(option);
	}
}
