export class Invoice {
	static STATES: any[] = [
		{ value: "any", title: "Alla" },
		{ value: "draft", title: "Utkast", badge: "secondary" },
		{ value: "booked", title: "Obetald", badge: "warning" },
		{ value: "overdue", title: "Förfallen", badge: "danger" },
		{ value: "paid", title: "Betald", badge: "success" },
		{ value: "credited", title: "Krediterad", badge: "dark" },
	];
}
