<button
	[matMenuTriggerFor]="selectMenu"
	class="btn btn-secondary btn-outline btn-sm"
>
	<span *ngIf="!multiple">
		{{ selections[0]?.title || "Välj" }}
	</span>
	<span *ngIf="multiple">
		{{ values?.join(", ") || "Välj" }}
	</span>
</button>
<mat-menu #selectMenu="matMenu">
	<button mat-menu-item *ngFor="let o of selections">
		<div class="selected-option" (click)="removeSelection(o)">
			<div class="selected-title">{{ o.title }}</div>
			<div class="selected-icon"><i class="fas fa-check"></i></div>
		</div>
	</button>
	<button mat-menu-item *ngFor="let o of options" (click)="makeSelection(o)">
		{{ o.title }}
	</button>
</mat-menu>
