<div class="alert alert-warning" *ngIf="error">
	{{ error }}
</div>

<div class="alert alert-info" *ngIf="!user && !error">Laddar...</div>

<div *ngIf="user">
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>Bonustrappa</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<table class="table points">
				<tr>
					<th>År</th>
					<th>Brytpunkt</th>
					<th>Bonus</th>
					<th>&nbsp;</th>
				</tr>
				<tr *ngFor="let point of points">
					<td>{{ point.year }}</td>
					<td>{{ point.grossAmount | numberSv }}</td>
					<td>{{ point.percentage }} %</td>
					<td class="delete-button">
						<button
							class="btn btn-danger btn-sm btn-outline"
							(click)="removePoint(point)"
						>
							Radera
						</button>
					</td>
				</tr>
			</table>
			<form [formGroup]="pointForm.controls" (ngSubmit)="addPoint()">
				<div class="row">
					<div class="col">
						<input
							type="text"
							class="form-control"
							formControlName="year"
							placeholder="År"
						/>
					</div>
					<div class="col">
						<input
							type="text"
							class="form-control"
							formControlName="grossAmount"
							placeholder="Brytpunkt"
						/>
					</div>
					<div class="col">
						<input
							type="text"
							class="form-control"
							formControlName="percentage"
							placeholder="Procentsats"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col" style="margin-top: 10px">
						<input
							type="submit"
							class="btn btn-success btn-block"
							value="Lägg till"
							[disabled]="!pointForm.isValid()"
						/>
					</div>
				</div>
				<div style="margin-top: 10px">
					<form-state [form]="pointForm"></form-state>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>
