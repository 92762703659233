<div class="alert alert-warning" *ngIf="error">
	{{ error }}
</div>

<div class="alert alert-info" *ngIf="!user && !error">Laddar...</div>

<div *ngIf="user">
	<mat-card class="card-block">
		<mat-card-header>
			<mat-card-title>Roller</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<table class="table user-roles">
				<tr *ngFor="let role of user.roles">
					<td>{{ role.role }}</td>
					<td class="delete-button">
						<button
							class="btn btn-danger btn-sm btn-outline"
							(click)="removeRole(role)"
						>
							Radera
						</button>
					</td>
				</tr>
			</table>
			<form [formGroup]="roleForm.controls" (ngSubmit)="addRole()">
				<div class="row">
					<div class="col-9">
						<input
							type="text"
							class="form-control"
							formControlName="role"
						/>
					</div>
					<div class="col-3">
						<input
							type="submit"
							class="btn btn-success btn-block"
							value="Lägg till"
							[disabled]="!roleForm.isValid()"
						/>
					</div>
				</div>
				<div>
					<form-state [form]="roleForm"></form-state>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>
