import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";

@Component({
	selector: "item-publish-button",
	templateUrl: "./item-publish-button.component.html",
	styleUrls: ["./item-publish-button.component.scss"],
})
export class ItemPublishButtonComponent implements OnInit {
	@Input("item")
	item: any;

	@Input("channel")
	channel: string;

	@Input("name")
	name: string;

	@Input("republish")
	republish: boolean;

	@Output() change: EventEmitter<any> = new EventEmitter();

	working: boolean = false;
	error: string = null;

	constructor(
		private http: HttpClient,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {}

	setPublish(state: boolean) {
		this.working = true;
		this.error = null;
		let request;
		if (state) {
			request = this.http.post(
				"/api/items/" +
					this.item.id +
					"/publish/" +
					this.channel.toLowerCase(),
				null
			);
		} else {
			request = this.http.delete(
				"/api/items/" +
					this.item.id +
					"/publish/" +
					this.channel.toLowerCase()
			);
		}
		request
			.subscribe(
				(data) => {
					this.item = data;
					this.change.emit(this.item);
				},
				(error) => {
					this.error = error.error;
				}
			)
			.add(() => {
				this.working = false;
				this.changeDetector.detectChanges();
			});
	}

	isPublished(): boolean {
		return this.item[`publish${this.channel}`];
	}

	isAvailable(): boolean {
		return ["new", "available"].includes(this.item.state);
	}

	getLink(): boolean {
		return this.item.links[this.channel.toLowerCase()];
	}
}
