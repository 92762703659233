import { Component, OnDestroy } from "@angular/core";

@Component({ template: "" })
export class Page implements OnDestroy {
	protected destroyed = false;

	ngOnDestroy() {
		this.destroyed = true;
	}

	protected timeout(callback: (...args: any[]) => void, ms: number) {
		setTimeout(() => {
			if (!this.destroyed) callback();
		}, ms);
	}
}
