<a #downloadLink style="display: none" type="application/pdf"></a>

<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>Objektbeskrivning</mat-card-title>
		<mat-card-subtitle>&nbsp;</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<form-state
			[form]="downloadForm"
			workingMessage="Skapar PDF"
		></form-state>
		<button
			class="btn btn-primary btn-block"
			[disabled]="!item || downloadForm.working"
			(click)="downloadOnepager()"
		>
			Vikbar A3
		</button>
	</mat-card-content>
</mat-card>
