// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
// RxJS
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Auth
import { AuthService } from "../../../../core/auth";

@Component({
	selector: "oauth-page",
	templateUrl: "./oauth.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class OAuthComponent implements OnInit {
	working = false;
	error: any = null;

	constructor(
		private change: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((query) => {
			if (query.code && !this.working) {
				console.log(query);
				this.working = true;
				this.auth
					.loginOAuth(query.code)
					.then(() => {
						console.log("Logged in");
						this.router.navigateByUrl("/");
					})
					.catch((error) => (this.error = error.error))
					.then(() => {
						this.working = false;
						this.change.detectChanges();
					});
			}
		});
	}
}
