import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
} from "@angular/core";

import { ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "item-cost",
	templateUrl: "./item-cost.component.html",
	styleUrls: ["./item-cost.component.scss"],
})
export class ItemCostComponent implements OnInit, OnChanges {
	@Input()
	item: any;

	cost: any = null;
	private itemChangedSubscription: Subscription = null;

	constructor(
		private itemService: ItemService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.item) {
			if (this.itemChangedSubscription == null) {
				this.itemChangedSubscription = this.itemService.itemChanged
					.asObservable()
					.subscribe((item: any) => {
						if (this.item?.id == item.id) {
							this.reload();
						}
					});
			}
			this.reload();
			console.log(changes);
		}
	}

	private reload() {
		if (this.item) {
			this.itemService.getCost(this.item.id).then((cost) => {
				this.cost = cost;
				this.change.detectChanges();
			});
		}
	}
}
