<div class="page-padding">
	<div class="alert alert-warning" *ngIf="error">
		{{ error }}
	</div>

	<div class="alert alert-info" *ngIf="!invoice && !error">Laddar...</div>

	<div *ngIf="invoice">
		<mat-card class="card-block">
			<mat-card-header>
				<mat-card-title
					>Faktura {{ invoice.invoiceNumber }}
					{{ invoice.company.name }}</mat-card-title
				>
				<div class="card-header-toolbar right invoice-state">
					<invoice-state-label
						[invoice]="invoice"
						(change)="change.detectChanges()"
					></invoice-state-label>
				</div>
				<mat-card-subtitle class="card-subtitle"
					>{{ invoice.customer.name }},
					{{ invoice.amount | numberSv }} kr</mat-card-subtitle
				>
			</mat-card-header>
		</mat-card>
		<mat-card class="card-block">
			<mat-card-header>
				<mat-card-title>Arvoden</mat-card-title>
				<div class="card-header-toolbar right">
					<button
						class="btn btn-secondary btn-sm"
						(click)="commissionForm.show()"
						*ngIf="!commissionForm.display"
					>
						Registrera
					</button>
				</div>
			</mat-card-header>
			<mat-card-content>
				<form
					[formGroup]="commissionForm.controls"
					(ngSubmit)="addCommission()"
					*ngIf="commissionForm.display"
				>
					<div class="row">
						<div class="col-3">
							<div>
								<label>Typ</label>
							</div>
							<div>
								<select
									class="form-control"
									formControlName="type"
								>
									<option [value]="null"></option>
									<option value="item">Uthyrning</option>
									<option value="transaction">
										Transaktion
									</option>
									<option value="analysis">Analys</option>
									<option value="ongoing">Löpande</option>
									<option value="referral">Tips</option>
									<option value="other">Övrigt</option>
								</select>
							</div>
						</div>
						<div class="col-2">
							<div>
								<label>Belopp</label>
							</div>
							<div>
								<input
									type="text"
									class="form-control"
									formControlName="amount"
								/>
							</div>
						</div>
						<div class="col-5">
							<div>
								<label>Beskrivning</label>
							</div>
							<div>
								<input
									type="text"
									class="form-control"
									formControlName="description"
								/>
							</div>
						</div>
						<div class="col-2">
							<div>
								<label>&nbsp;</label>
							</div>
							<div>
								<input
									type="submit"
									value="Registrera"
									class="form-control btn btn-primary"
									[disabled]="
										!commissionForm.isValid() ||
										commissionForm.working
									"
								/>
							</div>
						</div>
					</div>
					<br />
					<div
						*ngIf="commissionForm.getValue().type == 'item'"
						class="alert alert-warning"
					>
						Arvode för objekt registreras via objektsidan.
					</div>
					<form-state [form]="commissionForm"></form-state>
					<br />
				</form>
				<table class="table">
					<tr class="header">
						<td>Registrerat av</td>
						<td>Avser</td>
						<td>Typ</td>
						<td>Belopp</td>
					</tr>
					<tr *ngFor="let c of invoice.commissions">
						<td>{{ c.user.fullname }}</td>
						<td>
							<table-link
								path="items"
								[entity]="c.item"
								suffix="contract"
							></table-link>
							<p *ngIf="c.item?.commissionNote">
								<i>{{ c.item.commissionNote }}</i>
							</p>
							<p>{{ c.description }}</p>
						</td>
						<td>
							<div *ngFor="let t of types">
								<span
									*ngIf="c.type == t.value"
									[className]="'badge badge-' + t.badge"
									>{{ t.title }}</span
								>
							</div>
						</td>
						<td>{{ c.amount | numberSv }}</td>
					</tr>
				</table>
			</mat-card-content>
		</mat-card>
		<invoice-split [invoice]="invoice"></invoice-split>
	</div>
</div>
