import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { registerLocaleData } from "@angular/common";
import sv from "@angular/common/locales/sv";
registerLocaleData(sv);

@Pipe({ name: "distanceKm" })
export class DistanceKm implements PipeTransform {
	private pipe = new DecimalPipe("sv");

	transform(
		value: string,
		zero: string = "0",
		fallback: string = "-"
	): string {
		let floatValue = parseFloat(value);
		if (floatValue == 0) {
			return zero;
		}
		return (
			(this.pipe.transform(Math.round(floatValue * 0.01) * 0.1) ||
				fallback) + " km"
		);
	}
}
