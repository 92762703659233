<div *ngIf="!stateForm.working && !stateForm.error">
	<div *ngFor="let s of states">
		<span
			*ngIf="prospect.state == s.value"
			[className]="'badge badge-' + s.badge"
			[matMenuTriggerFor]="stateMenu"
			>{{ s.title }}</span
		>
	</div>
</div>
<mat-menu #stateMenu="matMenu">
	<button mat-menu-item *ngFor="let s of states" (click)="setState(s.value)">
		{{ s.title }}
	</button>
</mat-menu>
