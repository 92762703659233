// Angular
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
// NgBootstrap
import {
	NgbDropdownModule,
	NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
// Core module
import { CoreModule } from "../../core/core.module";
// SVG inline
import { AddLeadFormComponent } from "./add-lead-form/add-lead-form.component";
import { AddProspectFormComponent } from "./add-prospect-form/add-prospect-form.component";
import { DropdownSelectComponent } from "./dropdown-select/dropdown-select.component";
import { TextSearchComponent } from "./text-search/text-search.component";
import { NumberRangeComponent } from "./number-range/number-range.component";
import { TextCopyButtonComponent } from "./text-copy-button/text-copy-button.component";
import { InvoiceStateLabelComponent } from "./invoice-state-label/invoice-state-label.component";
import { InvoiceCommissionsComponent } from "./invoice-commissions/invoice-commissions.component";
import { TableLinkComponent } from "./table-link/table-link.component";
import { UserLinkComponent } from "./user-link/user-link.component";
import { FormStateComponent } from "./form-state/form-state.component";
import { DistrictSelectComponent } from "./district-select/district-select.component";
import { ItemTypeSelectComponent } from "./item-type-select/item-type-select.component";
import { UserSelectComponent } from "./user-select/user-select.component";
import { UserFilterComponent } from "./user-filter/user-filter.component";
import { CardSpacerComponent } from "./card-spacer/card-spacer.component";
import { LeadStateLabelComponent } from "./lead-state-label/lead-state-label.component";
import { SubscribeFormComponent } from "./subscribe-form/subscribe-form.component";
import { DateSearchComponent } from "./date-search/date-search.component";
import { CSVDownloadButtonComponent } from "./csv-download-button/csv-download-button.component";
import { PieChartComponent } from "./pie-chart/pie-chart.component";

import { NumberSv } from "./pipe/number-sv";
import { NumberTkr } from "./pipe/number-tkr";
import { DistanceKm } from "./pipe/distance-km";
import { DatetimeSv } from "./pipe/datetime-sv";

@NgModule({
	declarations: [
		TextCopyButtonComponent,
		DropdownSelectComponent,
		TextSearchComponent,
		InvoiceStateLabelComponent,
		InvoiceCommissionsComponent,
		AddLeadFormComponent,
		AddProspectFormComponent,
		SubscribeFormComponent,
		NumberRangeComponent,
		TableLinkComponent,
		UserLinkComponent,
		FormStateComponent,
		DistrictSelectComponent,
		ItemTypeSelectComponent,
		UserSelectComponent,
		UserFilterComponent,
		CardSpacerComponent,
		LeadStateLabelComponent,
		DateSearchComponent,
		CSVDownloadButtonComponent,
		PieChartComponent,
		NumberSv,
		NumberTkr,
		DistanceKm,
		DatetimeSv,
	],
	exports: [
		TextCopyButtonComponent,
		DropdownSelectComponent,
		TextSearchComponent,
		InvoiceCommissionsComponent,
		InvoiceStateLabelComponent,
		AddLeadFormComponent,
		AddProspectFormComponent,
		SubscribeFormComponent,
		NumberRangeComponent,
		TableLinkComponent,
		UserLinkComponent,
		FormStateComponent,
		DistrictSelectComponent,
		ItemTypeSelectComponent,
		UserSelectComponent,
		UserFilterComponent,
		CardSpacerComponent,
		LeadStateLabelComponent,
		DateSearchComponent,
		CSVDownloadButtonComponent,
		PieChartComponent,
		NumberSv,
		NumberTkr,
		DistanceKm,
		DatetimeSv,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,

		// angular material modules
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,

		// ng-bootstrap modules
		NgbDropdownModule,
		NgbTooltipModule,
	],
})
export class PartialsModule {}
