<div class="page-padding">
	<mat-card>
		<mat-card-header>
			<mat-card-title>Utbetalningar</mat-card-title>
			<div class="card-header-toolbar right"></div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>Löneår</td>
					<td>#</td>
					<td>Användare</td>
					<td>Underlag</td>
					<td>Andel</td>
					<td>Bonus</td>
					<td>Datum</td>
					<td>Fortnox</td>
				</tr>
				<tr *ngFor="let t of transactions">
					<td>{{ t.year }}</td>
					<td>{{ t.id }}</td>
					<td>
						<table-link
							path="salary"
							[entity]="t.user"
						></table-link>
					</td>
					<td>{{ t.grossAmount | numberSv }} kr</td>
					<td>{{ t.percentage }} %</td>
					<td>{{ t.netAmount | numberSv }} kr</td>
					<td>{{ t.date }}</td>
					<td>
						<div *ngIf="t.completed">
							<span class="badge badge-success"
								>Registrerad {{ t.completed }}</span
							>
						</div>
						<div *ngIf="t.working">
							<i class="fas fa-sync fa-spin fa-2x"></i>
						</div>
						<div *ngIf="!t.completed && !t.working && !t.error">
							<button
								class="btn btn-default btn-primary"
								(click)="completeTransaction(t)"
							>
								Registrera
							</button>
						</div>
						<div *ngIf="t.error">
							<div class="badge badge-danger">{{ t.error }}</div>
						</div>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
