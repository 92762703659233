import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";

import { InvoiceService } from "core/invoices/invoices.service";
import { Invoice } from "core/entity/invoice";

@Component({
	selector: "invoice-state-label",
	templateUrl: "./invoice-state-label.component.html",
	styleUrls: ["./invoice-state-label.component.scss"],
})
export class InvoiceStateLabelComponent implements OnInit {
	@Input("invoice")
	invoice: any;

	@Output() change: EventEmitter<any> = new EventEmitter();

	states = Invoice.STATES;

	working: boolean = false;
	error: string = null;

	constructor(
		private http: HttpClient,
		private changeDetector: ChangeDetectorRef,
		private invoiceService: InvoiceService
	) {}

	ngOnInit() {}

	refresh() {
		this.working = true;
		this.invoiceService
			.refresh(this.invoice)
			.catch((error) => (this.error = error.error))
			.then(() => {
				this.working = false;
				this.changeDetector.detectChanges();
				this.change.emit(this.invoice);
			});
	}
}
