import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "text-copy-button",
	templateUrl: "./text-copy-button.component.html",
	styleUrls: ["./text-copy-button.component.scss"],
})
export class TextCopyButtonComponent implements OnInit {
	@Input() value: any;
	@Input() btnClass: string;

	constructor() {}

	ngOnInit() {}

	copyToClipboard(element) {
		element.select();
		document.execCommand("copy");
		element.setSelectionRange(0, 0);
	}
}
