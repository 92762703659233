<mat-card>
	<mat-card-header>
		<mat-card-title>Hyresavtal</mat-card-title>
		<div class="card-header-toolbar"></div>
		<div class="card-header-toolbar right"></div>
	</mat-card-header>
	<mat-card-content>
		<p>
			<input
				type="text"
				class="form-control"
				#searchQuery
				(change)="search(searchQuery.value)"
				(keyup)="searchChange(searchQuery.value)"
				[value]="query.search || ''"
				placeholder="Sök"
			/>
		</p>
		<div class="alert alert-primary" *ngIf="loading">Laddar</div>
		<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
		<table class="table" *ngIf="!loading && !error">
			<tr class="header">
				<td>#</td>
				<td>Objekt</td>
				<td>Hyresgäst</td>
				<td>E-post</td>
				<td>Kontaktperson</td>
				<td>Mailchimp</td>
			</tr>
			<tr *ngFor="let c of contracts">
				<td>{{ c.id }}</td>
				<td>
					<table-link
						path="items"
						[entity]="c.item"
						suffix="contract"
					></table-link>
				</td>
				<td>{{ c.tenantName }}</td>
				<td>
					<a *ngIf="c.email" href="mailto:{{ c.email }}">{{
						c.email
					}}</a>
				</td>
				<td>{{ c.contact }}</td>
				<td>
					{{ c.mailchimpStatus }}
				</td>
			</tr>
		</table>
	</mat-card-content>
</mat-card>
