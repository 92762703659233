<div class="page-padding">
	<mat-card *ngIf="showAddForm">
		<mat-card-header>
			<mat-card-title>Registrera prospekt</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<add-prospect-form
				(created)="prospectCreated($event)"
			></add-prospect-form>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-header>
			<mat-card-title>Prospekt</mat-card-title>
			<div class="card-header-toolbar">
				<ul class="card-header-nav dropdown-filters">
					<li>
						<div>Ansvarig</div>
						<div>
							<user-filter roles="item_user"></user-filter>
						</div>
					</li>
				</ul>
			</div>
			<div class="card-header-toolbar right">
				<button
					mat-button
					[matMenuTriggerFor]="importMenu"
					class="btn btn-secondary btn-sm"
				>
					Importera
				</button>
				<mat-menu #importMenu="matMenu">
					<button mat-menu-item (click)="showAddForm = true">
						Registrera manuellt
					</button>
				</mat-menu>
			</div>
		</mat-card-header>
		<mat-card-content>
			<p>
				<input
					type="text"
					class="form-control"
					#searchQuery
					(change)="search(searchQuery.value)"
					(keyup)="searchChange(searchQuery.value)"
					[value]="query.search || ''"
					placeholder="Sök"
				/>
			</p>
			<div class="alert alert-primary" *ngIf="loading">Laddar</div>
			<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
			<table class="table" *ngIf="!loading && !error">
				<tr class="header">
					<td>Adress/benämning</td>
					<td>Storlek</td>
					<td>Hyresgäst</td>
					<td>Slutdatum</td>
					<td class="user-link"><i class="fa fa-user fa-lg"></i></td>
					<td>Status</td>
				</tr>
				<tr *ngFor="let p of prospects">
					<td>
						<table-link path="prospects" [entity]="p"></table-link>
					</td>
					<td>{{ p.size | numberSv }} m²</td>
					<td>{{ p.tenantName }}</td>
					<td>{{ p.expirationDate }}</td>
					<td class="user-link">
						<user-link [user]="p.user"></user-link>
					</td>
					<td>
						<prospect-state-label
							[prospect]="p"
						></prospect-state-label>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
