import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from "@angular/core";
import { RequestForm } from "views/partials/form-state/form-state.component";

@Component({
	selector: "onepager",
	templateUrl: "./onepager.component.html",
	styleUrls: ["./onepager.component.scss"],
})
export class OnepagerComponent {
	@Input()
	item: any;

	downloadForm = new RequestForm(true);
	@ViewChild("downloadLink")
	private downloadLink: ElementRef;

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}
	async downloadOnepager() {
		this.downloadForm.setWorking();
		let path = `/api/items/${this.item.id}/onepager`;
		this.http
			.get<Blob>(path, { responseType: "blob" as "json" })
			.subscribe(
				(file) => {
					const url = window.URL.createObjectURL(file);

					const link = this.downloadLink.nativeElement;
					link.href = url;
					link.download =
						"Objektbeskrivning-" + this.item.id + ".pdf";
					link.click();
					window.URL.revokeObjectURL(url);
					this.downloadForm.reset();
				},
				(error) => {
					this.downloadForm.setError(error);
				}
			)
			.add(() => {
				this.change.detectChanges();
			});
	}
}
