// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { OverviewStartComponent } from "./start/overview-start.component";
import { MarketingMaterialComponent } from "./marketing-material/marketing-material.component";
import { GoalStatsComponent } from "./goal-stats/goal-stats.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: OverviewStartComponent,
			},
		]),
	],
	providers: [],
	declarations: [
		OverviewStartComponent,
		MarketingMaterialComponent,
		GoalStatsComponent,
	],
})
export class OverviewModule {}
