// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { UserListComponent } from "./list/user-list.component";
import { UserDetailComponent } from "./detail/user-detail.component";
import { UserRolesComponent } from "./detail/roles/user-roles.component";
import { UserProfileComponent } from "./detail/profile/user-profile.component";
import { UserSalaryBreakpointsComponent } from "./detail/salary-breakpoints/user-salary-breakpoints.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		DragDropModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: UserListComponent,
			},
			{
				path: ":userID",
				component: UserDetailComponent,
			},
		]),
	],
	providers: [],
	declarations: [
		UserListComponent,
		UserDetailComponent,
		UserRolesComponent,
		UserProfileComponent,
		UserSalaryBreakpointsComponent,
	],
})
export class UsersModule {}
