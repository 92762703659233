import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";

import { SalaryService } from "core/salary/salary.service";
import { UsersService } from "core/users/users.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";
import { combineLatest } from "rxjs";

@Component({
	selector: "kt-salary-list",
	templateUrl: "./salary-list.component.html",
	styleUrls: ["./salary-list.component.scss"],
})
export class SalaryListComponent implements OnInit {
	transactions: any[];
	splits: any[];
	year: any;
	userId: number;
	user: any;

	loading = new RequestForm(true);
	makePaymentForm = new RequestForm(true);

	@ViewChild("downloadLink")
	private downloadLink: ElementRef;

	constructor(
		private salary: SalaryService,
		private userService: UsersService,
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		combineLatest(this.route.paramMap, this.route.queryParamMap).subscribe(
			([params, query]) => {
				let year =
					parseInt(query.get("year")) || new Date().getFullYear();
				this.load(parseInt(params.get("userID")), year);
			}
		);
	}

	private load(userID: number, yearId: number) {
		this.userId = userID;
		this.loading.setWorking();
		Promise.all([
			this.salary.getTransactions(userID, yearId),
			this.salary.getSplits(userID, yearId),
			this.salary.getSplits(userID, "draft"),
			this.salary.getYear(userID, yearId),
			this.userService.getUser(userID),
		]).then(([transactions, splits, drafts, year, user]) => {
			this.transactions = transactions;
			if (yearId == new Date().getFullYear()) {
				this.splits = [...drafts, ...splits];
			} else {
				this.splits = [...splits];
			}
			this.splits.sort(
				(a: any, b: any) =>
					b.invoice.invoiceNumber - a.invoice.invoiceNumber
			);
			this.year = year;
			this.user = user;
			this.loading.reset();
			this.change.detectChanges();
		});
	}

	makePayment(year: string, defaultAmount: string) {
		let amount = prompt("Ange belopp", defaultAmount);
		console.log(amount);
		if (!amount) {
			return;
		}
		this.makePaymentForm.setWorking();
		let url = `/api/users/${this.userId}/salary`;
		this.http
			.post(url, { year: year, amount: amount })
			.subscribe(
				(data: any) => {
					data.transactions.forEach((t: any) => {
						this.transactions.unshift(t);
						this.year.salary.net += t.netAmount;
					});
					this.makePaymentForm.setSuccess("Betalning registrerad");
				},
				(error) => {
					this.makePaymentForm.setError(error.error);
				}
			)
			.add(() => {
				this.change.detectChanges();
			});
	}

	setUser(user: any) {
		this.router.navigate(["/salary", user.id]);
	}
	async downloadPDF(transaction: any) {
		transaction.downloadWorking = true;
		try {
			let path = `/api/salary/transactions/${transaction.id}/invoice/pdf`;
			const file = await this.http
				.get<Blob>(path, { responseType: "blob" as "json" })
				.toPromise();
			const url = window.URL.createObjectURL(file);

			const link = this.downloadLink.nativeElement;
			link.href = url;
			link.download = "NP-" + transaction.id + ".pdf";
			link.click();

			window.URL.revokeObjectURL(url);
		} catch (e) {
			transaction.PDFdownloadError =
				(await e.error.text()) ?? e.statusText ?? e.status;
		} finally {
			transaction.downloadWorking = false;
			this.change.detectChanges();
		}
	}
}
