import { Component, Input } from "@angular/core";

@Component({
	selector: "pie-chart",
	templateUrl: "./pie-chart.component.html",
})
export class PieChartComponent {
	@Input()
	values: number[];

	@Input()
	color: string = "default";

	@Input()
	type: string = "normalized";

	colors = {
		default: ["#697B7D", "#FFF", "#F00", "#0F0"],
		traffic: ["#F8F9FA", "#28A745", "#FFC107", "#DC3545"],
	};

	constructor() {}

	getStroke(i: number) {
		let total = 100;
		let values = this.values;
		if (this.type == "absolute") {
			values = [];
			let current = 0;
			for (let v of this.values) {
				current += v;
				values.unshift(current);
			}
			total = current;
		}
		let v = values[i];
		let value = 100 * 3.1415 * (v / total);
		return `${value} ${100 * 3.1415}`;
	}

	getColor(i: number) {
		let colors = this.colors[this.color];
		if (!colors) {
			return "#000";
		}
		return colors[i % colors.length];
	}
}
