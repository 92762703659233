// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { ItemListComponent } from "./list/item-list.component";
import { ItemListTitleComponent } from "./list/title/item-list-title.component";
import { ItemListProbabilityComponent } from "./list/probability/item-list-probability.component";
import { ItemDetailComponent } from "./detail/item-detail.component";
import { ItemAttributesComponent } from "./detail/attributes/item-attributes.component";
import { ItemTagsComponent } from "./detail/attributes/tags/item-tags.component";
import { ItemCostComponent } from "./detail/attributes/cost/item-cost.component";
import { ItemDistrictsComponent } from "./detail/attributes/districts/item-districts.component";
import { ItemFilesComponent } from "./detail/files/item-files.component";
import { ItemImagesComponent } from "./detail/files/item-images.component";
import { PhotoOrderComponent } from "./detail/files/order/photo-order.component";
import { ItemLeadsComponent } from "./detail/leads/item-leads.component";
import { ItemPublishComponent } from "./detail/publish/item-publish.component";
import { ItemPublishButtonComponent } from "./detail/publish/publish-button/item-publish-button.component";
import { ItemCopyComponent } from "./detail/copy/item-copy.component";
import { ItemStateWarningComponent } from "./detail/state-warning/item-state-warning.component";
import { FormLabelComponent } from "./detail/form-label/form-label.component";
import { ItemNewComponent } from "./new/item-new.component";
import { ItemContractComponent } from "./detail/contract/item-contract.component";
import { ItemInvoiceComponent } from "./detail/contract/item-invoice.component";
import { ItemAssignmentComponent } from "./detail/contract/item-assignment.component";
import { ItemListExportDialog } from "./list/export/item-list-export.component";
import { ItemStartComponent } from "./detail/start/item-start.component";
import { SimilarItemsComponent } from "./detail/similar/similar-items.component";
import { OnepagerComponent } from "./detail/contract/onepager/onepager.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		DragDropModule,
		MatDialogModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: ItemListComponent,
			},
			{
				path: "user/:userID",
				component: ItemListComponent,
			},
			{
				path: "new",
				component: ItemNewComponent,
			},
			{
				path: ":itemID",
				component: ItemDetailComponent,
				children: [
					{ path: "", redirectTo: "start", pathMatch: "full" },
					{ path: "start", component: ItemStartComponent },
					{ path: "attributes", component: ItemAttributesComponent },
					{ path: "files", component: ItemFilesComponent },
					{ path: "leads", component: ItemLeadsComponent },
					{ path: "publish", component: ItemPublishComponent },
					{ path: "contract", component: ItemContractComponent },
				],
			},
		]),
	],
	providers: [],
	declarations: [
		ItemListComponent,
		ItemListTitleComponent,
		ItemListProbabilityComponent,
		ItemDetailComponent,
		ItemStartComponent,
		ItemAttributesComponent,
		ItemTagsComponent,
		ItemCostComponent,
		ItemDistrictsComponent,
		ItemFilesComponent,
		ItemImagesComponent,
		PhotoOrderComponent,
		ItemLeadsComponent,
		ItemPublishComponent,
		ItemPublishButtonComponent,
		ItemCopyComponent,
		ItemStateWarningComponent,
		FormLabelComponent,
		ItemNewComponent,
		ItemContractComponent,
		ItemInvoiceComponent,
		ItemAssignmentComponent,
		ItemListExportDialog,
		SimilarItemsComponent,
		OnepagerComponent,
	],
})
export class ItemsModule {}
