import {
	Component,
	OnInit,
	OnChanges,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	SimpleChanges,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
	selector: "dropdown-select",
	templateUrl: "./dropdown-select.component.html",
	styleUrls: ["./dropdown-select.component.scss"],
})
export class DropdownSelectComponent implements OnInit, OnChanges {
	@Input() options: any;
	@Input() queryParam: string;
	@Input() multiple: boolean;

	selections: any[] = [{ title: "Välj" }];
	values: string[] = [];

	@Output() change: EventEmitter<any> = new EventEmitter();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		if (this.queryParam) {
			this.route.queryParams.subscribe((query) => {
				let value = query[this.queryParam];
				if (value) {
					this.values = value.split(",");
				} else {
					this.values = [];
				}
				this.updateSelection();
			});
		}
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.updateSelection();
	}

	updateSelection() {
		this.selections = [];
		for (let o of this.options) {
			o.selected = false;
			for (let v of this.values) {
				if (o.value == v) {
					this.selections.push(o);
					o.selected = true;
				}
			}
		}
		this.changeDetector.detectChanges();
	}

	makeSelection(option: any) {
		if (option.selected) {
			return;
		}
		if (!this.multiple) {
			this.selections = [];
			this.values = [];
		}
		this.selections.push(option);
		this.values.push(option.value);
		this.navigate();
		this.change.emit(option);
	}

	removeSelection(option: any) {
		option.selected = false;
		let values = this.values;
		this.values = [];
		let selections = this.selections;
		this.selections = [];
		for (let v of values) {
			if (v != option.value) {
				this.values.push(v);
			}
		}
		for (let s of selections) {
			if (s.value != option.value) {
				this.selections.push(s);
			}
		}
		this.navigate();
		this.change.emit(option);
	}

	private navigate() {
		if (this.queryParam) {
			let query: any = {};
			query[this.queryParam] = this.values.join(",");
			this.router.navigate([], {
				queryParams: query,
				queryParamsHandling: "merge",
			});
		}
	}
}
