// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { DocumentListComponent } from "./list/document-list.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: DocumentListComponent,
			},
		]),
	],
	providers: [],
	declarations: [DocumentListComponent],
})
export class DocumentsModule {}
