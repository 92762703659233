import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
	type: string;
	items: any[];
}

@Component({
	selector: "kt-item-list-export",
	templateUrl: "./item-list-export.component.html",
	styleUrls: ["./item-list-export.component.scss"],
})
export class ItemListExportDialog implements OnInit {
	working: boolean = true;
	error: null;
	text: "";
	queue: any[] = [];
	type: string;
	warnings: string[] = [];

	constructor(
		private http: HttpClient,
		public dialogRef: MatDialogRef<ItemListExportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		let hidden = false;
		data.items.forEach((item) => {
			if (item.publishWeb || data.type === "rented") {
				this.queue.push(item);
			} else {
				hidden = true;
			}
		});
		if (hidden) {
			this.warnings.push(
				"Objekt ej publicerade på newproperty.se har utelämnats."
			);
		}
		this.type = data.type;
	}

	ngOnInit() {
		this.text = "";
		if (this.type === "csv") {
			this.text +=
				"Adress/benämning,Annonsrubrik,Hyra per m²,Tillägg värme,Tillägg kyla,Fastighetsskatt,El,Miljö,Momstilläg,Övrigt tillägg,Löptid,Tillträde,Säkerhet\n";
		}
		this.dialogRef.afterClosed().subscribe((result) => {
			// Abort loading when closed
			this.queue = [];
		});
		this.exportNext();
	}

	private exportNext() {
		if (this.queue.length == 0 || this.error) {
			this.working = false;
			return;
		}
		let item = this.queue.shift();
		this.http
			.get(`/api/items/${item.id}/export/${this.type}`, {
				responseType: "text",
			})
			.subscribe(
				(data) => {
					this.text += data + "\n"; // Extra empty line
				},
				(error) => {
					this.error = error.message;
				}
			)
			.add(() => {
				this.exportNext();
			});
	}
}
