<div class="page-padding">
	<form-state [form]="uploadForm"></form-state>

	<span class="alert alert-danger" *ngFor="let error of errors">{{
		error
	}}</span>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Dokument</mat-card-title>
			<div class="card-header-toolbar right">
				<ul class="card-header-nav">
					<li *ngFor="let d of directories">
						<a
							[routerLink]="['/documents']"
							[queryParams]="{ directory: d.id || 0 }"
							queryParamsHandling="merge"
							routerLinkActive="active"
							>{{ d.name }}</a
						>
					</li>
				</ul>
			</div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr *ngFor="let document of documents">
					<td><i class="fas fa-file"></i></td>
					<td>
						<a href="{{ document.file.url }}">{{
							document.name
						}}</a>
					</td>
				</tr>
			</table>
			<p *ngIf="userService.hasRole('document_admin')">
				<input
					type="file"
					name="leadfile"
					(change)="uploadFile($event)"
				/>
			</p>
		</mat-card-content>
	</mat-card>
</div>
