import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	HostListener,
} from "@angular/core";

import { HttpClient } from "@angular/common/http";

import { UsersService } from "core/users/users.service";

import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from "@angular/cdk/drag-drop";
import { debounce } from "lodash";

@Component({
	selector: "item-images",
	templateUrl: "./item-images.component.html",
	styleUrls: ["./item-images.component.scss"],
})
export class ItemImagesComponent implements OnInit {
	@Input("header")
	header: string;

	@Input("type")
	type: string;

	@Input("item")
	item: any;

	@Input("view")
	view: string = "thumbnails";

	@Output()
	change = new EventEmitter<void>();

	uploadQueue: any[] = [];
	colCount = 0;
	imageLists: any[][] = [];

	uploadError: string;

	constructor(
		public users: UsersService,
		private http: HttpClient,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		if (this.type === "image") {
			this.imageLists = [this.item["images"]];
			this.balanceImageLists();
		}
	}

	uploadFile(fileInput: any) {
		this.uploadError = null;
		let files: File[] = fileInput.target.files;
		if (this.type === "image") {
			let validFileType: boolean = true;
			for (var file of files) {
				validFileType =
					validFileType &&
					(file.type === "image/jpeg" || file.type === "image/png");
			}
			if (!validFileType) {
				this.uploadError = "Ogiltig filtyp 😵";
				return;
			}
		}

		let wasDone = this.uploadQueue.length == 0;
		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			this.uploadQueue.push(file);
		}
		if (wasDone) {
			this.uploadNext();
		}
	}

	uploadNext() {
		if (this.uploadQueue.length == 0) {
			return;
		}
		let file = this.uploadQueue.shift();
		const formData = new FormData();
		formData.append("file", file);
		this.http.post("/api/files", formData).subscribe(
			(data: any) => {
				this.addFile(data);
			},
			(error) => {
				console.log(error);
				// Abort everything
				this.uploadQueue = [];
			}
		);
	}

	addFile(file: any) {
		this.http
			.post("/api/items/" + this.item.id + "/files", {
				type: this.type,
				hash: file.hash,
			})
			.subscribe((data: any) => {
				this.files().push(data);
				this.changed();
				this.uploadNext();
			});
	}

	async renameFile(ifile) {
		let extensionBefore = ifile.name.split(".").pop();
		let name = prompt("Ange nytt namn", ifile.name);
		if (name == null) {
			return; // Pressed Cancel
		}
		let extensionAfter = name.split(".").pop();
		if (extensionAfter != extensionBefore) {
			if (
				!confirm(
					`Är du säker på att du vill ändra filändelse från '${extensionBefore}' till '${extensionAfter}'?`
				)
			) {
				return;
			}
		}
		this.http
			.put(
				"/api/items/" + this.item.id + "/files/" + ifile.id + "/name",
				{ name: name }
			)
			.subscribe((data: any) => {
				ifile.name = data.name;
				this.changed();
			});
	}

	setVisible(ifile: any, state: boolean) {
		this.http
			.put(
				"/api/items/" +
					this.item.id +
					"/files/" +
					ifile.id +
					"/visible",
				{ visible: state }
			)
			.subscribe(() => {
				ifile.visible = state;
				this.changed();
			});
	}

	removeFile(ifile) {
		this.http
			.delete("/api/items/" + this.item.id + "/files/" + ifile.id)
			.subscribe(() => {
				let key = ifile.type + "s";
				this.item[key] = this.item[key].filter((f: any) => {
					return f.id !== ifile.id;
				});
				this.changed();
			});
	}

	dragdrop(event: CdkDragDrop<object[]>) {
		moveItemInArray(
			event.container.data,
			event.previousIndex,
			event.currentIndex
		);
		let sort: number = 1;
		event.container.data.forEach((image: any) => {
			image.sort = sort;
			sort++;
		});
		this.newOrder();
	}
	newOrder() {
		let payload: any[] = [];
		this.files().forEach((file: any) => {
			payload.push({ id: file.id, sort: file.sort });
		});
		this.http
			.put("/api/items/" + this.item.id + "/files/order", {
				files: payload,
			})
			.subscribe(() => {
				this.changed();
			});
	}

	@HostListener("window:resize", ["$event"])
	onResize = debounce((_event) => {
		if (this.getNumberOfColumns() != this.colCount) {
			this.balanceImageLists();
		}
	}, 100);

	private getNumberOfColumns() {
		let width = document.getElementById("imglist").clientWidth;
		return Math.max(1, Math.floor(width / 300));
	}

	balanceImageLists() {
		this.colCount = this.getNumberOfColumns();
		let images = [];
		for (let rowList of this.imageLists) {
			for (let image of rowList) {
				images.push(image);
			}
		}
		this.imageLists = [];
		for (let i = 0; i < images.length; i++) {
			if (i % this.colCount == 0) {
				this.imageLists.push([]);
			}
			this.imageLists[this.imageLists.length - 1].push(images[i]);
		}
		this.changeDetector.detectChanges();
	}
	dragdrop2(event: CdkDragDrop<object[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		}
		this.balanceImageLists();
		let sort: number = 1;
		this.imageLists.forEach((rowList: any[]) => {
			rowList.forEach((image: any) => {
				image.sort = sort;
				sort++;
			});
		});
		this.newOrder();
	}

	files() {
		if (!this.item || !this.type) {
			return [];
		}
		return this.item[this.type + "s"];
	}

	private changed() {
		this.change.emit();
		this.changeDetector.detectChanges();
	}
}
