// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { UserStatsComponent } from "./users/user-stats.component";
import { UserCommissionStatsComponent } from "./users/commission/user-commission-stats.component";
import { UserSalaryStatsComponent } from "./users/salary/user-salary-stats.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		DragDropModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "users",
				component: UserStatsComponent,
			},
		]),
	],
	providers: [],
	declarations: [
		UserStatsComponent,
		UserCommissionStatsComponent,
		UserSalaryStatsComponent,
	],
})
export class StatsModule {}
