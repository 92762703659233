<div mat-dialog-title>Redigera intressent</div>
<hr />
<mat-dialog-content>
	<div *ngIf="error" class="alert alert-danger">{{ error }}</div>
	<div class="row">
		<div class="col">
			<label>Tillträde</label>
			<input
				#newAccess
				type="text"
				value="{{ lead.access_date }}"
				class="form-control"
			/>
		</div>
		<div class="col">
			<label>Storlek min m²</label>
			<input
				#newMinSize
				type="number"
				class="form-control"
				value="{{ lead.minsize }}"
			/>
		</div>
		<div class="col">
			<label>Storlek max m²</label>
			<input
				#newMaxSize
				type="number"
				class="form-control"
				value="{{ lead.maxsize }}"
			/>
		</div>
		<div class="col">
			<label>Hyra max kr/m²/år</label>
			<input
				#newMaxRent
				type="number"
				class="form-control"
				value="{{ lead.maxrent }}"
			/>
		</div>
	</div>
</mat-dialog-content>
<hr />
<mat-dialog-actions>
	<div>
		<button
			class="btn btn-primary"
			(click)="
				save(
					newAccess.value,
					newMinSize.value,
					newMaxSize.value,
					newMaxRent.value
				)
			"
		>
			Spara
		</button>
	</div>
	<div>
		<button class="btn btn-secondary" (click)="dialogRef.close()">
			Avbryt
		</button>
	</div>
</mat-dialog-actions>
