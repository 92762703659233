<form [formGroup]="form.controls" (ngSubmit)="fetchInvoice()">
	<div class="row">
		<div class="col">
			<select formControlName="companyId" class="form-control">
				<option [value]="null" [disabled]="true">Företag</option>
				<option *ngFor="let c of companies" [value]="c.id">
					{{ c.name }}
				</option>
			</select>
		</div>
		<div class="col">
			<input
				formControlName="invoiceNumber"
				type="text"
				class="form-control"
				placeholder="Fakturanummer"
			/>
		</div>
		<div class="col">
			<input
				type="submit"
				class="form-control btn btn-primary"
				value="Hämta faktura"
				[disabled]="!form.isValid() || form.working"
			/>
		</div>
	</div>
	<div>
		<form-state [form]="form" workingMessage="Hämtar..."></form-state>
	</div>
</form>
