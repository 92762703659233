import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { debounce } from "ts-debounce";

@Component({
	selector: "date-search",
	templateUrl: "./date-search.component.html",
	styleUrls: ["./date-search.component.scss"],
})
export class DateSearchComponent implements OnInit {
	@Input() queryParam: string;
	@Input() placeholder: string;
	@Input() width: string;

	value: string = "";

	@Output() change: EventEmitter<string> = new EventEmitter();

	constructor(private router: Router, private route: ActivatedRoute) {}

	ngOnInit() {
		if (this.queryParam) {
			this.route.queryParams.subscribe((query) => {
				this.value = query[this.queryParam] || "";
			});
		}
	}

	doSearch(value: string) {
		this.value = value;
		this.navigate();
		this.change.emit(value);
	}
	search = debounce(this.doSearch, 500);

	private navigate() {
		if (this.queryParam) {
			let query: any = {};
			query[this.queryParam] = this.value;
			this.router.navigate([], {
				queryParams: query,
				queryParamsHandling: "merge",
			});
		}
	}
}
