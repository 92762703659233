// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
// NgBootstrap
import {
	NgbProgressbarModule,
	NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
// Core Module
import { CoreModule } from "../../core/core.module";
import { PartialsModule } from "../partials/partials.module";
import { BaseComponent } from "./base/base.component";
import { PagesModule } from "views/pages/pages.module";

@NgModule({
	declarations: [BaseComponent],
	exports: [BaseComponent],
	imports: [
		CommonModule,
		RouterModule,
		PagesModule,
		PartialsModule,
		CoreModule,
		FormsModule,
		MatProgressBarModule,
		MatTabsModule,
		MatButtonModule,
		MatTooltipModule,
		MatListModule,
		MatSidenavModule,

		// ng-bootstrap modules
		NgbProgressbarModule,
		NgbTooltipModule,
	],
})
export class ThemeModule {}
