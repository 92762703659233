import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { RequestForm } from "../../../../partials/form-state/form-state.component";

@Component({
	selector: "user-salary-breakpoints",
	templateUrl: "./user-salary-breakpoints.component.html",
	styleUrls: ["./user-salary-breakpoints.component.scss"],
})
export class UserSalaryBreakpointsComponent implements OnChanges {
	@Input() user: any;
	error = null;
	points: any[] = [];

	pointForm = new RequestForm(
		true,
		new UntypedFormGroup({
			grossAmount: new UntypedFormControl("", Validators.required),
			percentage: new UntypedFormControl("", Validators.required),
			year: new UntypedFormControl("", Validators.required),
		})
	);

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.user) {
			this.http
				.get(`/api/users/${this.user.id}/salary/breakpoints`)
				.subscribe(
					(response: any) => {
						this.points = response;
					},
					(error) => (this.error = error.error)
				)
				.add(() => this.change.detectChanges());
		}
	}

	addPoint() {
		if (this.pointForm.working) return;

		this.pointForm.setWorking();
		this.http
			.post(
				`/api/users/${this.user.id}/salary/breakpoints`,
				this.pointForm.getValue()
			)
			.subscribe(
				(response: any) => {
					this.points.push(response);
					this.pointForm.reset();
				},
				(error) => this.pointForm.setError(error.error)
			)
			.add(() => this.change.detectChanges());
	}

	removePoint(point: any) {
		if (this.pointForm.working) return;

		if (!confirm("Bekräfta radering.")) return;

		this.pointForm.setWorking();
		this.http
			.delete(`/api/users/${this.user.id}/salary/breakpoints/${point.id}`)
			.subscribe(
				() => {
					const index = this.points.indexOf(point, 0);
					if (index > -1) {
						this.points.splice(index, 1);
					}
					this.pointForm.reset();
				},
				(error) => this.pointForm.setError(error.error)
			)
			.add(() => this.change.detectChanges());
	}
}
