// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
	declarations: [],
	exports: [],
	imports: [CommonModule, HttpClientModule, FormsModule],
	providers: [],
})
export class PagesModule {}
