import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class PhotoOrderService {
	constructor(private http: HttpClient) {}

	public async create(item: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.post(`/api/items/${item.id}/photoOrders`, null).subscribe(
				(order) => {
					resolve(order);
				},
				(error) => reject(error)
			);
		});
	}
}
