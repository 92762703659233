import { Component, Output, EventEmitter } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { ProspectService } from "core/prospects/prospects.service";
import { RequestForm } from "../form-state/form-state.component";

@Component({
	selector: "add-prospect-form",
	templateUrl: "./add-prospect-form.component.html",
	styleUrls: ["./add-prospect-form.component.scss"],
})
export class AddProspectFormComponent {
	@Output() created: EventEmitter<any> = new EventEmitter();

	public form = new RequestForm(
		true,
		new UntypedFormGroup({
			title: new UntypedFormControl("", []),
			message: new UntypedFormControl("", []),
			landlord: new UntypedFormGroup({
				contact: new UntypedFormControl("", []),
				orgNumber: new UntypedFormControl("", []),
				name: new UntypedFormControl("", [Validators.required]),
				email: new UntypedFormControl("", []),
				phone: new UntypedFormControl("", []),
			}),
			size: new UntypedFormControl("", [Validators.pattern("[0-9]*")]),
			tenantName: new UntypedFormControl("", []),
			rent: new UntypedFormControl("", []),
			expirationDate: new UntypedFormControl("", []),
			notice: new UntypedFormControl("", [Validators.pattern("[0-9]*")]),
		})
	);

	constructor(
		private changeDetector: ChangeDetectorRef,
		private prospectService: ProspectService
	) {}

	submitProspect() {
		this.form.setWorking();
		this.prospectService
			.create(this.form.getValue())
			.then((prospect: any) => {
				this.form.setSuccess("Registrerad");
				this.created.emit(prospect);
			})
			.catch((error: any) => this.form.setError(error.error))
			.then(() => this.changeDetector.detectChanges());
	}
}
