import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class SubscriberService {
	constructor(private http: HttpClient) {}

	create(value: any, source: string): Promise<any> {
		let payload = { ...value, source: source };
		return new Promise((resolve, reject) => {
			let url = "/api/subscribers";
			this.http.post(url, payload).subscribe(
				(subscriber: any) => resolve(subscriber),
				(error) => reject(error)
			);
		});
	}

	RELATIONSHIPS = [
		{ value: "Hyresvärd", title: "Hyresvärd" },
		{ value: "Hyresgäst", title: "Hyresgäst" },
		{ value: "Tenrep", title: "Tenrep" },
		{ value: "Transaktion", title: "Transaktion" },
	];

	LEVELS = [
		{ value: 3, title: "Brons" },
		{ value: 2, title: "Silver" },
		{ value: 1, title: "Guld" },
	];
}
