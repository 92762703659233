import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class DocumentService {
	types = { template: "Mall" };

	constructor(private http: HttpClient) {}

	getDocuments(query: any = {}): Promise<any[]> {
		let url = "/api/documents";
		return new Promise((resolve, reject) => {
			this.http.get(url, { params: query }).subscribe(
				(documents: any[]) => resolve(documents),
				(error) => reject(error)
			);
		});
	}

	getDirectories(query: any = {}): Promise<any[]> {
		let url = "/api/documents/directories";
		return new Promise((resolve, reject) => {
			this.http.get(url, { params: query }).subscribe(
				(directories: any[]) => resolve(directories),
				(error) => reject(error)
			);
		});
	}

	create(
		name: string,
		type: string,
		file: any,
		directory: number | string
	): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = "/api/documents";
			let payload = {
				name: name,
				type: type,
				hash: file.hash,
				directory: directory,
			};
			this.http.post(url, payload).subscribe(
				(document: any) => resolve(document),
				(error) => reject(error)
			);
		});
	}
}
