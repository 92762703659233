import { Component, Input } from "@angular/core";

@Component({
	selector: "form-label",
	templateUrl: "./form-label.component.html",
})
export class FormLabelComponent {
	@Input("t")
	title: string;

	@Input("c")
	control: any;
}
