<div mat-dialog-title>
	<div *ngIf="working">Laddar...</div>
	<div *ngIf="!working">Export färdig</div>
</div>
<hr />
<mat-dialog-content>
	<div *ngIf="error" class="aler alert-danger">{{ error }}</div>
	<div *ngFor="let w of warnings" class="alert alert-warning">{{ w }}</div>
	<pre>{{ csvContent }}</pre>
</mat-dialog-content>
<hr />
<mat-dialog-actions>
	<div *ngIf="!working && !error">
		<csv-download-button
			content="{{ csvContent }}"
			filename="leads.csv"
			btnClass="btn btn-primary"
			>Ladda ned</csv-download-button
		>
	</div>
</mat-dialog-actions>
