<div *ngIf="item">
	<item-images
		type="image"
		header="Bildgalleri"
		[item]="item"
		(change)="changed()"
	></item-images>
	<div class="row" *ngIf="item && users.hasRole('item_user')">
		<div class="col">
			<item-images
				type="attachment"
				header="Bilagor"
				view="list"
				[item]="item"
				(change)="changed()"
			></item-images>
		</div>
		<div class="col">
			<photo-order [item]="item"></photo-order>
		</div>
		<div class="col">
			<item-images
				type="drawing"
				header="Ritning"
				[item]="item"
				(change)="changed()"
			></item-images>
		</div>
	</div>
</div>
