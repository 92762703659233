import {
	Component,
	OnInit,
	Input,
	OnDestroy,
	OnChanges,
	SimpleChanges,
} from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "item-districts",
	templateUrl: "./item-districts.component.html",
	styleUrls: ["./item-districts.component.scss"],
})
export class ItemDistrictsComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	item: any;

	districts: any[] = [];
	error: string = "";

	private itemChangedSubscription: Subscription;

	constructor(
		private http: HttpClient,
		private itemService: ItemService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.itemChangedSubscription = this.itemService.itemChanged
			.asObservable()
			.subscribe((item: any) => {
				if (this.item) {
					this.reload();
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.item?.currentValue) {
			this.reload();
		}
	}

	ngOnDestroy() {
		this.itemChangedSubscription.unsubscribe();
	}

	reload() {
		this.http
			.get("/api/items/" + this.item.id + "/districts")
			.subscribe(
				(data: any[]) => {
					this.districts = data;
					this.error = null;
				},
				(error) => {
					this.districts = [];
					this.error = error.error;
				}
			)
			.add(() => this.change.detectChanges());
	}
}
