import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { registerLocaleData } from "@angular/common";
import sv from "@angular/common/locales/sv";
registerLocaleData(sv);

@Pipe({ name: "numberSv" })
export class NumberSv implements PipeTransform {
	private pipe = new DecimalPipe("sv");

	transform(
		value: string,
		zero: string = "0",
		fallback: string = "0"
	): string {
		let intValue = parseInt(value);
		if (intValue == 0) {
			return zero;
		}
		return this.pipe.transform(value) || fallback;
	}
}
