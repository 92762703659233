import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";

import { registerLocaleData } from "@angular/common";
import sv from "@angular/common/locales/sv";
registerLocaleData(sv);

@Pipe({ name: "datetimeSv" })
export class DatetimeSv implements PipeTransform {
	transform(value: string): string {
		try {
			let d = new Date(value);
			return formatDate(d, "yyyy-MM-dd HH:mm", "sv");
		} catch (error) {
			return "Ogiltigt datum";
		}
	}
}
