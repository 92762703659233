// Angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Core Module
import { PartialsModule } from "../../partials/partials.module";
import { MapStartComponent } from "./start/map-start.component";
import { MapStartExportDialog } from "./start/export/map-start-export.component";

@NgModule({
	imports: [
		CommonModule,
		PartialsModule,
		ReactiveFormsModule,
		NgbModule,
		MatDialogModule,
		MatCardModule,
		MatMenuModule,
		RouterModule.forChild([
			{
				path: "",
				component: MapStartComponent,
			},
		]),
	],
	providers: [],
	declarations: [MapStartComponent, MapStartExportDialog],
})
export class MapModule {}
