<mat-card class="card-block" *ngIf="similarItems">
	<mat-card-header>
		<mat-card-title>Liknande objekt i närheten</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<table class="table">
			<tr class="header">
				<td>Adress</td>
				<td>Storlek</td>
				<td>Avstånd</td>
			</tr>
			<tr *ngFor="let item of similarItems">
				<td class="item-name">
					<item-list-title [item]="item"></item-list-title>
				</td>
				<td>
					<span *ngIf="item.size?.text">{{ item.size.text }} m²</span>
				</td>
				<td>
					<span>{{ item.distance | distanceKm }}</span>
				</td>
			</tr>
		</table>
	</mat-card-content>
</mat-card>
