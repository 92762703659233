<form
	[formGroup]="form.controls"
	(ngSubmit)="submitLead()"
	*ngIf="!form.working && !form.success"
>
	<div class="row">
		<div class="col form-group">
			<label>Företag</label>
			<input type="text" class="form-control" formControlName="company" />
		</div>
		<div class="col form-group">
			<label>Kontaktperson</label>
			<input
				type="text"
				class="form-control"
				formControlName="fullname"
			/>
		</div>
		<div class="col form-group">
			<label>E-post</label>
			<input type="text" class="form-control" formControlName="email" />
		</div>
		<div class="col form-group">
			<label>Telefonnummer</label>
			<input type="text" class="form-control" formControlName="phone" />
		</div>
		<div class="col form-group">
			<label>Tillträde</label>
			<input
				type="text"
				class="form-control"
				formControlName="access_date"
			/>
		</div>
	</div>
	<div class="row">
		<div class="col-6 form-group">
			<label>Rubrik</label>
			<input type="text" class="form-control" formControlName="title" />
		</div>
		<div class="col form-group">
			<label>Storlek min (m²)</label>
			<input type="text" class="form-control" formControlName="minsize" />
		</div>
		<div class="col form-group">
			<label>Storlek max (m²)</label>
			<input type="text" class="form-control" formControlName="maxsize" />
		</div>
		<div class="col form-group">
			<label>Hyra max (kr/m²/år)</label>
			<input type="text" class="form-control" formControlName="maxrent" />
		</div>
	</div>
	<div class="row">
		<div class="col form-group">
			<label>Beskrivning</label>
			<textarea class="form-control" formControlName="message"></textarea>
		</div>
	</div>
	<div class="form-group" *ngIf="!error && !working">
		<form-state [form]="form"></form-state>
		<input
			type="submit"
			class="form-control btn btn-primary"
			value="Skapa"
			[disabled]="!form.isValid()"
		/>
	</div>
	<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
</form>
