<div class="page-padding">
	<mat-card *ngIf="prospect">
		<mat-card-header>
			<mat-card-title
				><span>{{ prospect.title }}</span
				><number-range
					prefix=", "
					[min]="prospect.size"
					unit="m²"
				></number-range
			></mat-card-title>
			<div class="card-header-toolbar right">
				<prospect-state-label
					[prospect]="prospect"
					(change)="reload(prospect.id)"
				></prospect-state-label>
			</div>
		</mat-card-header>
		<mat-card-content>
			<hr />
			<div class="row">
				<div class="col">
					<label>Hyresvärd</label>
					<div>{{ prospect.landlord?.orgnumber }}</div>
					<div>{{ prospect.landlord?.name }}</div>
					<div>{{ prospect.landlord?.contact }}</div>
					<div *ngIf="prospect.landlord?.email">
						<a
							href="/prospects?search={{
								prospect.landlord?.email
							}}"
							>{{ prospect.landlord?.email }}</a
						>&nbsp;
						<a href="mailto:{{ prospect.landlord?.email }}"
							><i class="fas fa-paper-plane"></i
						></a>
					</div>
					<div>{{ prospect.landlord?.phone }}</div>
				</div>
				<div class="col">
					<label>Hyresgäst</label>
					<div>{{ prospect.tenantName }}</div>
					<div>till {{ prospect.expirationDate }}</div>
					<div>
						<number-range
							[min]="prospect.rent"
							unit="kr/m²/år"
						></number-range>
					</div>
				</div>
				<div class="col">
					<div>
						<div>
							<label>Ansvarig</label>
						</div>
						<div *ngIf="!userForm.working">
							<div
								*ngIf="prospect.user"
								class="dim-link-container"
							>
								<user-link [user]="prospect.user"></user-link>
								<table-link
									path="users"
									[entity]="prospect.user"
								></table-link>
								<span class="dim-link" (click)="setUser(-1)">
									[ Ta bort ]
								</span>
							</div>
							<div *ngIf="!prospect.user">
								<user-select
									title="Tilldela"
									btnClass="btn btn-primary btn-sm"
									(change)="setUser($event)"
								></user-select>
							</div>
						</div>
						<form-state [form]="userForm"></form-state>
					</div>
				</div>
			</div>
			<div *ngIf="prospect.message" class="prospect-message">
				<hr />
				<p>{{ prospect.message }}</p>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card *ngIf="prospect">
		<mat-card-header>
			<mat-card-title>
				<span>Anteckningar</span>
			</mat-card-title>
			<div class="card-header-toolbar right">
				<button
					class="btn btn-secondary btn-sm"
					(click)="addNote.show()"
				>
					Lägg till
				</button>
			</div>
		</mat-card-header>
		<mat-card-content>
			<div *ngIf="addNote.display">
				<hr />
				<form [formGroup]="addNote.controls" (ngSubmit)="submitNote()">
					<div class="form-group">
						<textarea
							class="form-control"
							formControlName="message"
						></textarea>
					</div>
					<form-state [form]="addNote"></form-state>
					<div class="form-group" *ngIf="!addNote.working">
						<input
							type="submit"
							class="form-control btn btn-primary"
							value="Spara"
							[disabled]="!addNote.isValid()"
						/>
					</div>
				</form>
			</div>
			<table class="table">
				<tr *ngFor="let note of prospect.notes">
					<td>{{ note.created | datetimeSv }}</td>
					<td class="note-message">{{ note.message }}</td>
					<td>
						<user-link [user]="note.agent"></user-link>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
