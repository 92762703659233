<div *ngIf="!working && !error">
	<div *ngFor="let s of states">
		<span
			(click)="refresh(invoice)"
			*ngIf="invoice.state == s.value"
			[className]="'badge badge-' + s.badge"
			>{{ s.title }}</span
		>
	</div>
</div>
<div *ngIf="working">
	<i class="fas fa-sync fa-spin"></i>
</div>
<div *ngIf="error">
	<span class="badge badge-warning">{{ error }}</span>
</div>
