import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ItemService } from "core/items/items.service";
import { DistrictService } from "core/districts/districts.service";
import { MatDialog } from "@angular/material/dialog";

import { Control, ScaleLine } from "ol/control";
import { Coordinate } from "ol/coordinate";
import { never, platformModifierKeyOnly } from "ol/events/condition";
import { getCenter } from "ol/extent";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { DragBox, Draw, Select } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OLMap from "ol/Map";
import Overlay from "ol/Overlay";
import { transform, fromLonLat, toLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import View from "ol/View";
import WKT from "ol/format/WKT";

import { ItemListExportDialog } from "../../items/list/export/item-list-export.component";
import { MapStartExportDialog } from "./export/map-start-export.component";
import Collection from "ol/Collection";
import { Polygon } from "ol/geom";

@Component({
	selector: "kt-map-start",
	templateUrl: "./map-start.component.html",
	styleUrls: ["./map-start.component.scss"],
})
export class MapStartComponent implements OnInit {
	center: Coordinate;
	zoom: number;
	view: View;
	map: OLMap;

	items: any = [];
	districts: any = [];

	itemMarkers: VectorSource;
	itemLayer: VectorLayer<VectorSource>;
	districtMarkers: VectorSource;
	districtLayer: VectorLayer<VectorSource>;
	itemSelect: Select;
	selectedMarkers: Collection<Feature>;
	districtSelect: Select;
	draw: Draw;

	overlayItem: any = null;
	selectedDistrict: Feature = null;
	districtToEdit: Feature = null;

	exportCoordinates = "";
	districtToCreateMarker: VectorSource;
	districtToEditMarker: VectorSource;

	filters = {
		state: {
			title: "Status",
			options: [
				{ value: "any", title: "Alla" },
				{ value: "new", title: "Nya" },
				{ value: "available", title: "Lediga" },
				{ value: "rented", title: "Uthyrda" },
				{ value: "withdrawn", title: "Återtagna" },
			],
			queryParam: "state",
		},
	};
	dropdowns = [this.filters.state];
	public query: any = {};

	exportMenuOpen: boolean = false;

	constructor(
		private change: ChangeDetectorRef,
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private itemService: ItemService,
		private districtService: DistrictService
	) {}

	ngOnInit() {
		this.initMap();
		this.initDistrictMarkers();
		this.initItemMarkers();
		this.initItemOverlay();
		this.initDistrictOverlay();
		this.initDistrictToEditMarker();
		this.initDistrictToCreateMarker();
		this.route.queryParams.subscribe((query) => this.navigate(query));
	}

	navigate(query: any) {
		this.query = query;
		this.map.getOverlayById(1).setPosition(undefined);
		this.loadItems();
		this.loadDistricts();
		this.initDraw();
		if (this.query.devmode) {
			console.log("devmode");
			this.districtLayer.setVisible(this.query.devmode ? true : false);
		}
	}

	private loadItems() {
		let query = {
			...this.query,
			values: [],
		};
		this.itemService.loadItems(query).then((items: any[]) => {
			this.items = items;
			this.itemMarkers.clear();
			this.items.map((item: any) => {
				this.addItemMarker(item);
			});
			this.change.detectChanges();
		});
	}

	private addItemMarker(item: any) {
		let marker = new Feature({
			geometry: new Point(fromLonLat([item.long, item.lat])),
			name: item.title,
			itemId: item.id,
			itemSize: item.size ? item.size.text + " m²" : "-",
			item: item,
		});
		this.itemMarkers.addFeatures([marker]);
	}
	private clearItemMarkers() {
		this.itemLayer.getSource().clear();
	}

	private loadDistricts() {
		this.districtService.loadDistricts().then((districts: any[]) => {
			this.districts = districts;
			this.districtMarkers.clear();
			if (this.query.devmode) {
				this.districts
					.sort((a: any, b: any) => {
						return b.sort - a.sort;
					})
					.map((d: any) => {
						console.log(d);
						this.addDistrictMarker(d);
					});
				this.change.detectChanges();
			}
		});
	}
	private addDistrictMarker(district: any) {
		let format = new WKT();
		let geometry = format.readGeometry(district.coordinates, {
			dataProjection: "EPSG:4326",
			featureProjection: "EPSG:3857",
		});
		let marker = new Feature({
			geometry: geometry,
			name: district.name,
			itemId: district.id,
		});
		this.districtMarkers.addFeatures([marker]);
	}

	private initMap(): void {
		if (
			window.location.host.includes("-dev") ||
			window.location.host.includes("localhost")
		) {
			this.center = transform([13.2, 55.71], "EPSG:4326", "EPSG:3857");
			this.zoom = 14;
		} else {
			this.center = transform(
				[18.0711, 59.3256],
				"EPSG:4326",
				"EPSG:3857"
			);
			this.zoom = 12;
		}
		this.view = new View({
			center: this.center,
			zoom: this.zoom,
		});
		this.map = new OLMap({
			view: this.view,
			target: "ol-map",
			layers: [
				new TileLayer({
					source: new OSM(),
				}),
			],
		});
		this.map.addControl(new ScaleLine());
		this.map.addControl(
			new ResetZoomControl({
				center: this.center,
				zoom: this.zoom,
			})
		);
		this.map.on("pointermove", (evt) => {
			const hit = this.map.hasFeatureAtPixel(evt.pixel);
			this.map.getTargetElement().style.cursor = hit ? "pointer" : "";
		});
	}

	private initDraw() {
		const source = new VectorSource({ wrapX: false });
		const layer = new VectorLayer({ source: source });
		const features = new Collection<Feature>();
		this.draw = new Draw({
			condition: this.query.devmode ? platformModifierKeyOnly : never,
			source: source,
			features: features,
			type: "Polygon",
			freehand: this.query.devmode ? false : true,
		});
		this.draw.on("drawend", async (e) => {
			const geom = e.feature.getGeometry();
			if (this.query.devmode) {
				this.districtToCreateMarker.addFeature(e.feature);
				const polygon = e.feature.getGeometry() as Polygon;
				const source = polygon.getCoordinates()[0];
				let coords = [];
				for (let i = 0; i < source.length; i++) {
					let c = toLonLat(source[i]);
					coords.push(c[0] + " " + c[1]);
				}
				this.exportCoordinates = "POLYGON((" + String(coords) + "))";
				this.change.detectChanges();
			}
			this.itemSelect.setActive(true);
			const itemMarkerArray = this.itemMarkers
				.getFeatures()
				.filter((feat) => {
					return geom.intersectsCoordinate(
						feat.get("geometry").getCoordinates()
					);
				});
			this.selectedMarkers.extend(itemMarkerArray);
			features.clear();
		});
		this.draw.on("drawstart", () => {
			source.clear();
			this.selectedMarkers.clear();
			this.districtToCreateMarker.clear();
			this.itemSelect.setActive(false);
		});
	}
	// Currently unused. Works slightly better than draw so keep code around for now?
	private initDragBox() {
		const dragBox = new DragBox({
			condition: platformModifierKeyOnly,
		});
		this.map.addInteraction(dragBox);
		dragBox.on("boxend", () => {
			const extent = dragBox.getGeometry().getExtent();
			const boxFeatures = this.itemMarkers.getFeaturesInExtent(extent);
			this.selectedMarkers.extend(boxFeatures);
		});
		dragBox.on("boxstart", () => {
			this.selectedMarkers.clear();
		});
	}

	private initItemMarkers() {
		this.itemMarkers = new VectorSource();
		let style = new Style({
			text: new Text({
				text: "\uf3c5",
				font: '900 32px "Font Awesome 5 Free"',
				offsetY: -16,
				fill: new Fill({
					color: "#cc3333",
				}),
				stroke: new Stroke({
					color: "black",
					width: 1,
				}),
			}),
		});
		this.itemLayer = new VectorLayer({
			source: this.itemMarkers,
			style: style,
		});
		this.itemLayer.setVisible(true);
		this.map.addLayer(this.itemLayer);

		let itemSelectStyle = new Style({
			text: new Text({
				text: "\uf3c5",
				font: '900 32px "Font Awesome 5 Free"',
				offsetY: -16,
				fill: new Fill({
					color: "#2255cc",
				}),
				stroke: new Stroke({
					color: "white",
					width: 1,
				}),
			}),
		});
		this.itemSelect = new Select({
			style: itemSelectStyle,
			layers: (layer) => {
				return layer == this.itemLayer;
			},
		});
		this.map.addInteraction(this.itemSelect);
		this.selectedMarkers = this.itemSelect.getFeatures();
	}

	private initItemOverlay(): void {
		const overlay = new Overlay({
			id: 1,
			element: document.getElementById("popup"),
		});

		this.map.addOverlay(overlay);

		this.itemSelect.on("select", (evt) => {
			if (this.exportMenuOpen) {
				return;
			}
			const feat = evt.selected.pop();
			if (!feat) {
				overlay.setPosition(undefined);
				return;
			}
			const coords = feat.get("geometry").getCoordinates();
			this.overlayItem = feat.get("item");
			overlay.setPosition(coords);
			this.change.detectChanges();
		});
	}

	private initDistrictToCreateMarker() {
		this.districtToCreateMarker = new VectorSource();
		let districtToCreateLayer = new VectorLayer({
			source: this.districtToCreateMarker,
			style: new Style({
				stroke: new Stroke({ color: "#33cc33", width: 5 }),
			}),
		});
		this.map.addLayer(districtToCreateLayer);
	}
	private initDistrictToEditMarker() {
		this.districtToEditMarker = new VectorSource();
		let districtToEditLayer = new VectorLayer({
			source: this.districtToEditMarker,
			style: new Style({
				stroke: new Stroke({ color: "#cc3333", width: 5 }),
			}),
		});
		this.map.addLayer(districtToEditLayer);
	}

	private initDistrictMarkers() {
		this.districtMarkers = new VectorSource();
		this.districtLayer = new VectorLayer({
			source: this.districtMarkers,
			style: new Style({
				stroke: new Stroke({ color: "#2255cc" }),
				fill: new Fill({
					color: [255, 255, 255, 0.0],
				}),
			}),
		});
		this.map.addLayer(this.districtLayer);

		let districtSelectStyle = new Style({
			stroke: new Stroke({ color: "#cc3333", width: 3 }),
			fill: new Fill({
				color: [255, 255, 255, 0.3],
			}),
		});
		this.districtSelect = new Select({
			style: districtSelectStyle,
			layers: (layer) => {
				return layer == this.districtLayer;
			},
		});
		this.map.addInteraction(this.districtSelect);
	}

	private initDistrictOverlay(): void {
		const overlay = new Overlay({
			id: 2,
			element: document.getElementById("districtPopup"),
		});
		this.map.addOverlay(overlay);

		this.districtSelect.on("select", (evt) => {
			const feat = evt.selected.pop();
			if (!feat) {
				overlay.setPosition(undefined);
				this.selectedDistrict = null;
				this.change.detectChanges();
				return;
			}
			this.selectedDistrict = feat;
			const extent = feat.get("geometry").getExtent();
			const coords = getCenter(extent);
			overlay.setPosition(coords);
			overlay.setPositioning("bottom-left");
			this.change.detectChanges();
		});
	}

	private getItemsToExport(): any[] {
		const selectedItemIds = {};
		this.selectedMarkers.getArray().map((feat) => {
			const itemId = feat.get("itemId");
			selectedItemIds[itemId] = true;
		});
		const selectedItems: any[] = this.items.filter((item) => {
			return selectedItemIds[item.id];
		});
		return selectedItems.length > 0 ? selectedItems : this.items;
	}
	exportItems(type: string) {
		let itemsToExport = this.getItemsToExport();
		if (type === "rented") {
			itemsToExport = itemsToExport.filter((item) => {
				return item.state === "rented";
			});
		}
		let exportDialog = this.dialog.open(ItemListExportDialog, {
			data: {
				type: type,
				items: itemsToExport,
			},
		});
	}
	exportItemsMap() {
		const itemsToExport = this.getItemsToExport();
		const itemIds: Number[] = itemsToExport.map((item) => {
			return item.id;
		});
		let exportDialog = this.dialog.open(MapStartExportDialog, {
			data: { items: itemIds },
		});
	}
	openExportMenu() {
		this.exportMenuOpen = true;
		this.map.addInteraction(this.draw);
	}
	closeExportMenu() {
		this.exportMenuOpen = false;
		this.map.removeInteraction(this.draw);
		this.selectedMarkers.clear();
		this.districtToCreateMarker.clear();
		this.districtToEditMarker.clear();
		this.exportCoordinates = "";
	}

	createDistrict() {
		let name = prompt("Namn");
		let sort = prompt("Sortering");
		this.districtToCreateMarker.clear();
		this.districtService
			.createDistrict(name, parseInt(sort), this.exportCoordinates)
			.then(() => {
				this.exportCoordinates = "";
				this.change.detectChanges();
				this.loadDistricts();
			});
	}
	beginEditingDistrict() {
		this.closeExportMenu();
		this.districtToEdit = this.selectedDistrict;
		this.districtToEditMarker.addFeature(this.districtToEdit);
		this.exportCoordinates = "";
		this.map.addInteraction(this.draw);
	}
	exitEditingDistrict() {
		this.districtToEdit = null;
		this.closeExportMenu();
	}
	editDistrict() {
		let id = this.districtToEdit.get("itemId");
		this.districtService
			.editDistrict(id, this.exportCoordinates)
			.then(() => {
				this.exportCoordinates = "";
				this.change.detectChanges();
				this.loadDistricts();
			});
		this.exitEditingDistrict();
	}
}

class ResetZoomControl extends Control {
	center: Coordinate;
	zoom: number;
	/**
	 * @param {Object} [opt_options] Control options.
	 */
	constructor(opt_options) {
		const options = opt_options || {};

		const button = document.createElement("button");
		button.innerHTML = '<i class="fas fa-fw fa-arrows-alt"></i>';

		const element = document.createElement("div");
		element.className = "ol-zoom-extent ol-unselectable ol-control";
		element.appendChild(button);

		super({
			element: element,
			target: options.target,
		});

		this.center = options.center;
		this.zoom = options.zoom;

		button.addEventListener(
			"click",
			this.handleResetZoom.bind(this),
			false
		);
	}

	handleResetZoom() {
		this.getMap().getView().setCenter(this.center);
		this.getMap().getView().setZoom(this.zoom);
	}
}
