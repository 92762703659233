<mat-card class="card-block">
	<mat-card-content>
		<div class="tag-list">
			<span *ngFor="let t of tags">
				<span
					class="btn btn-primary"
					*ngIf="hasTag(t)"
					(click)="setTag(t, false)"
					>{{ t.name }}</span
				>
				<span
					class="btn btn-secondary"
					*ngIf="!hasTag(t)"
					(click)="setTag(t, true)"
					>{{ t.name }}</span
				>
			</span>
		</div>
	</mat-card-content>
</mat-card>
