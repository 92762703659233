import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class FileService {
	constructor(private http: HttpClient) {}

	upload(file: File): Promise<any> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("file", file);
			this.http.post("/api/files", formData).subscribe(
				(file: any) => resolve(file),
				(error) => reject(error)
			);
		});
	}
}
