import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class ProspectService {
	constructor(private http: HttpClient) {}

	getProspects(query: any = {}): Promise<any[]> {
		let url = "/api/prospects";
		return new Promise((resolve, reject) => {
			this.http.get(url, { params: query }).subscribe(
				(prospects: any[]) => resolve(prospects),
				(error) => reject(error)
			);
		});
	}

	getProspect(id: number): Promise<any> {
		let url = `/api/prospects/${id}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(prospect: any) => resolve(prospect),
				(error) => reject(error)
			);
		});
	}

	create(source: any): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = "/api/prospects";
			this.http.post(url, source).subscribe(
				(prospect: any) => resolve(prospect),
				(error) => reject(error)
			);
		});
	}

	setUser(id: number, userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/prospects/${id}`;
			let payload = { user: userId };
			this.http.patch(url, payload).subscribe(
				(prospect: any) => resolve(prospect.user),
				(error) => reject(error)
			);
		});
	}

	setState(id: number, state: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/prospects/${id}`;
			let payload = { state: state };
			this.http.patch(url, payload).subscribe(
				(prospect: any) => resolve(prospect),
				(error) => reject(error)
			);
		});
	}

	addNote(id: number, source: any): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/prospects/${id}/notes`;
			this.http.post(url, source).subscribe(
				(note: any) => resolve(note),
				(error) => reject(error)
			);
		});
	}
}
