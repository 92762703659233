<button [matMenuTriggerFor]="selectMenu" [class]="btnClass">
	{{ title }}
</button>
<mat-menu #selectMenu="matMenu">
	<button
		mat-menu-item
		*ngFor="let u of users.all()"
		(click)="setSelection(u)"
	>
		{{ u.fullname }}
	</button>
</mat-menu>
