import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "kt-salary-generate",
	templateUrl: "./salary-generate.component.html",
	styleUrls: ["./salary-generate.component.scss"],
})
export class SalaryGenerateComponent implements OnInit {
	public transactions: any[] = null;

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}

	ngOnInit() {
		this.loadTransactions();
	}

	private loadTransactions() {
		let url = "/api/salary/transactions";
		this.http.get(url).subscribe((data: any[]) => {
			this.transactions = data;
			this.change.detectChanges();
		});
	}

	public completeTransaction(t: any) {
		t.working = true;
		let url = `/api/salary/transactions/${t.id}/complete`;
		this.http
			.post(url, {})
			.subscribe(
				(data: any[]) => {
					Object.assign(t, data);
				},
				(error) => {
					t.error = error.error;
				}
			)
			.add(() => {
				t.working = false;
				this.change.detectChanges();
			});
	}
}
