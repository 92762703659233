import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	tap,
	switchMap,
} from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ItemAutocompleteService {
	constructor(private http: HttpClient) {}

	generate(parent) {
		let PARAMS = new HttpParams();
		return (text$: Observable<string>) =>
			text$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => (parent.attribute.searching = true)),
				switchMap((term) =>
					this.http
						.get(
							"/api/attributes/" +
								parent.attribute.id +
								"/autocomplete",
							{ params: PARAMS.set("search", term) }
						)
						.pipe(map((response) => response))
						.pipe(
							tap(() => (parent.attribute.searchFailed = false)),
							catchError(() => {
								parent.attribute.searchFailed = true;
								return of([]);
							})
						)
				),
				tap(() => (parent.attribute.searching = false))
			);
	}
}
