import { Component, OnInit } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	FormControl,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { SubscriberService } from "core/subscribers/subscribers.service";
import { RequestForm } from "../form-state/form-state.component";

@Component({
	selector: "subscribe-form",
	templateUrl: "./subscribe-form.component.html",
	styleUrls: ["./subscribe-form.component.scss"],
})
export class SubscribeFormComponent implements OnInit {
	public form = new RequestForm(
		true,
		new UntypedFormGroup({
			firstname: new UntypedFormControl("", [Validators.required]),
			lastname: new UntypedFormControl("", [Validators.required]),
			email: new UntypedFormControl("", [
				Validators.required,
				Validators.email,
			]),
			relationship: new UntypedFormControl("", [Validators.required]),
			level: new FormControl<number>(null, [Validators.required]),
		})
	);

	constructor(
		private changeDetector: ChangeDetectorRef,
		public service: SubscriberService
	) {}

	ngOnInit() {}

	submitSubscriber() {
		this.form.setWorking();
		this.service
			.create(this.form.getValue(), "manual")
			.then((subscriber: any) => {
				this.form.setSuccess(
					`${subscriber.firstname} ${subscriber.lastname} <${subscriber.email}> registrerad`
				);
			})
			.catch((error: any) => this.form.setError(error.error))
			.then(() => this.changeDetector.detectChanges());
	}
}
