import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "kt-user-list",
	templateUrl: "./user-list.component.html",
	styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
	public users: any = [];
	public error: string = null;

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {}

	ngOnInit() {
		this.loadUsers();
	}

	private loadUsers() {
		this.error = null;
		this.http.get("/api/users").subscribe(
			(data: any[]) => {
				this.users = data;
				this.change.detectChanges();
			},
			(error) => {
				this.error = error.error;
			}
		);
	}
}
