import { Component, Input } from "@angular/core";

@Component({
	selector: "number-range",
	templateUrl: "./number-range.component.html",
})
export class NumberRangeComponent {
	@Input() min: number;
	@Input() max: number;
	@Input() prefix: string;
	@Input() unit: string;
}
