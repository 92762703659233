import { Component, Input } from "@angular/core";

@Component({
	selector: "item-list-title",
	templateUrl: "./item-list-title.component.html",
	styleUrls: ["./item-list-title.component.scss"],
})
export class ItemListTitleComponent {
	@Input()
	item: any;

	itemLink(): any[] {
		let path = "start";
		if (
			this.item &&
			["new", "available"].includes(this.item.state) &&
			this.item.startupCommissions?.length > 0
		) {
			path = "attributes";
		} else if (["rented", "withdrawn"].includes(this.item.state)) {
			path = "contract";
		}
		return ["/items", this.item.id, path];
	}
}
