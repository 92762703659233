import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { registerLocaleData } from "@angular/common";
import sv from "@angular/common/locales/sv";
registerLocaleData(sv);

@Pipe({ name: "numberTkr" })
export class NumberTkr implements PipeTransform {
	private pipe = new DecimalPipe("sv");

	transform(
		value: string,
		zero: string = "0",
		fallback: string = "0"
	): string {
		let intValue = Math.round(parseInt(value) / 1000);
		if (intValue == 0) {
			return zero;
		}
		return this.pipe.transform(intValue) || fallback;
	}
}
