import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { RequestForm } from "../../../partials/form-state/form-state.component";
import { InvoiceService } from "core/invoices/invoices.service";

@Component({
	selector: "kt-invoice-detail",
	templateUrl: "./invoice-detail.component.html",
	styleUrls: ["./invoice-detail.component.scss"],
})
export class InvoiceDetailComponent implements OnInit {
	invoice: any;
	error = null;
	types: any[];

	commissionForm = new RequestForm(
		false,
		new UntypedFormGroup({
			type: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(
					"(transaction|analysis|ongoing|referral|other)"
				),
			]),
			amount: new UntypedFormControl("", [
				Validators.required,
				Validators.min(1),
			]),
			description: new UntypedFormControl("", [Validators.required]),
		})
	);

	constructor(
		private invoiceService: InvoiceService,
		private route: ActivatedRoute,
		private http: HttpClient,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.reloadInvoice(params.get("invoiceID"));
		});
		this.types = this.invoiceService.types;
	}

	private reloadInvoice(id: number | string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.http.get("/api/invoices/" + id).subscribe(
				(data) => {
					this.invoice = data;
					this.change.detectChanges();
					resolve();
				},
				(error) => {
					console.log("error");
					this.error = "Hämtning misslyckades: " + error.message;
					this.change.detectChanges();
					reject();
				}
			);
		});
	}

	private addCommission() {
		let commission = this.commissionForm.getValue();
		this.http
			.post(`/api/invoices/${this.invoice.id}/commissions`, commission)
			.subscribe(
				(commission: any) => {
					this.reloadInvoice(this.invoice.id).then(() => {
						this.commissionForm.reset();
						this.change.detectChanges();
					});
				},
				(error) =>
					this.commissionForm.setError(
						"Registrering ej genomförd: " + error.error
					)
			)
			.add(() => {
				this.change.detectChanges();
			});
	}
}
