<div class="page-padding">
	<mat-card *ngIf="lead" class="card-block">
		<mat-card-header>
			<mat-card-title>
				<span
					><a href="/leads?search={{ lead.company }}">{{
						lead.company
					}}</a></span
				>
				<span *ngIf="lead.created" class="badge badge-secondary"
					>Inkommit {{ lead.created }}</span
				>
			</mat-card-title>
			<mat-card-subtitle *ngIf="lead.title"
				>{{ lead.title }}
			</mat-card-subtitle>
			<div class="card-header-toolbar right">
				<ul class="card-header-nav">
					<li>
						<lead-state-label
							[lead]="lead"
							(change)="reload(lead.id)"
						></lead-state-label>
					</li>
					<li>
						<button
							class="btn btn-secondary btn-sm"
							title="Redigera"
							(click)="edit()"
						>
							Redigera
						</button>
					</li>
				</ul>
			</div>
		</mat-card-header>
		<mat-card-content>
			<hr />
			<div class="row">
				<div class="col">
					<label>Kontaktperson</label>
					<div *ngIf="lead.fullname">{{ lead.fullname }}</div>
					<div *ngIf="lead.email">
						<a href="/leads?search={{ lead.email }}">{{
							lead.email
						}}</a
						>&nbsp;
						<a href="mailto:{{ lead.email }}"
							><i class="fas fa-paper-plane"></i
						></a>
					</div>
					<div *ngIf="lead.phone">{{ lead.phone }}</div>
				</div>
				<div class="col" *ngIf="lead.access_date">
					<label>Tillträde</label>
					<div>
						{{ lead.access_date }}
					</div>
				</div>
				<div
					class="col"
					*ngIf="lead.minsize || lead.maxsize || lead.maxrent"
				>
					<label>Önskemål</label>
					<div *ngIf="lead.minsize || lead.maxsize">
						<number-range
							[min]="lead.minsize"
							[max]="lead.maxsize"
							unit="m²"
						></number-range>
					</div>
					<div *ngIf="lead.maxrent">
						Max {{ lead.maxrent }} kr/m²/år
					</div>
				</div>
				<div *ngIf="lead.item" class="col">
					<div>
						<div>
							<label>Objekt</label>
						</div>
						<div>
							<table-link
								path="items"
								[entity]="lead.item"
								suffix="leads"
							></table-link>
						</div>
					</div>
				</div>
				<div class="col">
					<div>
						<div>
							<label>Ansvarig</label>
						</div>
						<div *ngIf="!userForm.working">
							<div *ngIf="lead.user" class="dim-link-container">
								<table-link
									path="users"
									[entity]="lead.user"
								></table-link>
								<span class="dim-link" (click)="setUser(null)">
									[ Ta bort ]
								</span>
							</div>
							<div *ngIf="!lead.user">
								<user-select
									title="Tilldela"
									btnClass="btn btn-primary btn-sm"
									(change)="setUser($event)"
								></user-select>
							</div>
						</div>
						<form-state [form]="userForm"></form-state>
					</div>
				</div>
			</div>
			<div *ngIf="lead.message" class="lead-message">
				<hr />
				<p>{{ lead.message }}</p>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card *ngIf="lead">
		<mat-card-header>
			<mat-card-title>
				<span>Anteckningar</span>
			</mat-card-title>
			<div class="card-header-toolbar right">
				<button
					class="btn btn-secondary btn-sm"
					(click)="addNote.show()"
				>
					Lägg till
				</button>
			</div>
		</mat-card-header>
		<mat-card-content>
			<div *ngIf="addNote.display">
				<hr />
				<form [formGroup]="addNote.controls" (ngSubmit)="submitNote()">
					<div class="form-group">
						<textarea
							class="form-control"
							formControlName="message"
						></textarea>
					</div>
					<form-state [form]="addNote"></form-state>
					<div class="form-group" *ngIf="!addNote.working">
						<input
							type="submit"
							class="form-control btn btn-primary"
							value="Spara"
							[disabled]="!addNote.isValid()"
						/>
					</div>
				</form>
			</div>
			<table class="table">
				<tr *ngFor="let note of lead.notes">
					<td>{{ note.created | datetimeSv }}</td>
					<td class="note-message">{{ note.message }}</td>
					<td>
						<user-link [user]="note.agent"></user-link>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
