import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";

import { ProspectService } from "core/prospects/prospects.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";

@Component({
	selector: "kt-prospect-detail",
	templateUrl: "./prospect-detail.component.html",
	styleUrls: ["./prospect-detail.component.scss"],
})
export class ProspectDetailComponent implements OnInit {
	loading: boolean = false;
	error: string = null;
	prospect: any;

	userForm = new RequestForm(true);

	addNote = new RequestForm(
		false,
		new UntypedFormGroup({
			message: new UntypedFormControl("", [Validators.required]),
		})
	);

	constructor(
		private change: ChangeDetectorRef,
		private route: ActivatedRoute,
		private prospectService: ProspectService
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.reload(parseInt(params.get("id")));
		});
	}

	private reload(id: number) {
		this.loading = true;
		this.error = null;
		this.prospectService
			.getProspect(id)
			.then((prospect: any) => (this.prospect = prospect))
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.loading = false;
				this.change.detectChanges();
			});
	}

	setUser(userId: number) {
		this.userForm.setWorking();
		this.prospectService
			.setUser(this.prospect.id, userId)
			.then((user: any) => {
				this.prospect.user = user;
				this.userForm.reset();
				this.reload(this.prospect.id);
			})
			.catch((error: any) => this.userForm.setError(error.error))
			.then(() => {
				this.change.detectChanges();
			});
	}

	submitNote() {
		this.addNote.error = null;
		this.addNote.working = true;
		this.prospectService
			.addNote(this.prospect.id, this.addNote.getValue())
			.then((note: any) => {
				this.prospect.notes.unshift(note);
				this.addNote.reset();
			})
			.catch((error: any) => (this.addNote.error = error.error))
			.then(() => {
				this.addNote.working = false;
				this.change.detectChanges();
			});
	}
}
