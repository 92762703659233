// Angular
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OverlayModule } from "@angular/cdk/overlay";
// Env
import { environment } from "../environments/environment";
// Components
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Auth
import { AuthModule } from "./views/pages/auth/auth.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthModule.forRoot(),
		MatProgressSpinnerModule,
		ThemeModule,
		FontAwesomeModule,
	],
	exports: [],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
