import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { ItemAutocompleteService } from "../../item-autocomplete.service";
import { UsersService } from "core/users/users.service";
import { ItemService } from "core/items/items.service";

class Value {
	attribute;
	text;
}

@Component({
	selector: "kt-item-start",
	templateUrl: "./item-start.component.html",
	styleUrls: ["./item-start.component.scss"],
})
export class ItemStartComponent implements OnInit {
	item;
	sections: any[];
	model;

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private autocomplete: ItemAutocompleteService,
		private itemService: ItemService,
		private users: UsersService
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.itemService
				.getItem(parseInt(params.get("itemID")))
				.then((item) => {
					this.item = item;
					this.change.detectChanges();
				});
		});
	}

	addUser(userID) {
		let user = { id: userID };
		this.http
			.post("/api/items/" + this.item.id + "/users", user)
			.subscribe((data) => {
				this.item.contacts.push(data);
				this.change.detectChanges();
				this.itemService.itemChanged.next(this.item);
			});
	}

	removeUser(user) {
		this.http
			.delete("/api/items/" + this.item.id + "/users/" + user.id)
			.subscribe((data: any) => {
				this.item.contacts = this.item.contacts.filter((c) => {
					return c.user.id !== user.id;
				});
				this.change.detectChanges();
				this.itemService.itemChanged.next(this.item);
			});
	}
}
