import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "user-filter",
	templateUrl: "./user-filter.component.html",
})
export class UserFilterComponent implements OnInit {
	@Input() queryParam: string = "user";
	@Input() roles: string = "";

	options: any[] = [];

	constructor(
		private userService: UsersService,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.userService
			.usersAsOptions({
				roles: this.roles.split(","),
			})
			.then((options: any[]) => {
				this.options = options;
				this.options.unshift({ value: 0, title: "Alla användare" });
				this.options.push({
					value: "none",
					title: "Saknar användare",
				});
				this.changeDetector.detectChanges();
			});
	}
}
