import { Component, OnInit, Inject } from "@angular/core";
import { LeadService } from "core/leads/leads.service";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
	lead: any;
}

@Component({
	selector: "kt-lead-detail-edit",
	templateUrl: "./lead-detail-edit.component.html",
	styleUrls: ["./lead-detail-edit.component.scss"],
})
export class LeadDetailEditDialog implements OnInit {
	working: boolean = true;
	error: null;
	lead: any;
	access: string;
	constructor(
		private leadService: LeadService,
		public dialogRef: MatDialogRef<LeadDetailEditDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		this.lead = data.lead;
	}
	ngOnInit() {
		this.working = false;
	}
	save(
		accessDate: string,
		minSize: number,
		maxSize: number,
		maxRent: number
	) {
		try {
			console.log(accessDate, minSize, maxSize, maxRent);
			let payload = {};
			if (accessDate) {
				// Run the string through a Date object to make sure we get a valid date
				// before comparing to previous value
				const date: Date = new Date(accessDate);
				// Dates such as 2025-11-32 will be considered invalid
				if (date.toString() === "Invalid Date") {
					throw new Error("Ogiltigt datumformat");
				}
				const fixedDateString = date.toISOString().substring(0, 10);
				// Dates such as 2025-11-31 will be automatically "corrected" by Date to a valid date. February is handled.
				if (fixedDateString !== accessDate) {
					throw new Error("Ogiltigt datumformat");
				}
				if (fixedDateString != this.lead.access_date) {
					payload["access_date"] = fixedDateString;
				}
			}
			if (minSize != this.lead.minsize) {
				payload["minsize"] = minSize;
			}
			if (maxSize != this.lead.maxsize) {
				payload["maxsize"] = maxSize;
			}
			if (maxRent != this.lead.maxrent) {
				payload["maxrent"] = maxRent;
			}
			this.leadService
				.setValues(this.lead.id, payload)
				.then(() => {
					this.dialogRef.close();
				})
				.catch((error: any) => {
					console.log(error);
					this.error = error.error;
				});
		} catch (error) {
			console.log(error);
			this.error = error.message;
		}
	}
}
