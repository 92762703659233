import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ItemService } from "core/items/items.service";

@Component({
	selector: "similar-items",
	templateUrl: "./similar-items.component.html",
})
export class SimilarItemsComponent implements OnInit {
	similarItems = null;

	constructor(
		private route: ActivatedRoute,
		private change: ChangeDetectorRef,
		private itemService: ItemService
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			let id = parseInt(params.get("itemID"));
			if (id > 0) {
				this.itemService.getSimilar(id).then((similar: any[]) => {
					this.similarItems = similar;
					this.change.detectChanges();
				});
			}
		});
	}
}
