import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class LeadService {
	getMineUnanswered(): Promise<any[]> {
		let url = "/api/leads";
		return new Promise((resolve, reject) => {
			this.http
				.get(url, { params: { user: "me", state: "unanswered" } })
				.subscribe(
					(leads: any[]) => resolve(leads),
					(error) => reject(error)
				);
		});
	}
	constructor(private http: HttpClient) {}

	getLeads(query: any = {}): Promise<any[]> {
		let url = "/api/leads";
		return new Promise((resolve, reject) => {
			this.http.get(url, { params: query }).subscribe(
				(leads: any[]) => resolve(leads),
				(error) => reject(error)
			);
		});
	}

	getLatest(): Promise<any[]> {
		let url = "/api/leads/latest";
		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	getLead(id: number): Promise<any> {
		let url = `/api/leads/${id}`;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(lead: any) => resolve(lead),
				(error) => reject(error)
			);
		});
	}

	import(source: string): Promise<any[]> {
		return new Promise((resolve, reject) => {
			let url = `/api/leads/import/${source}`;
			this.http.post(url, null).subscribe(
				(leads: any[]) => resolve(leads),
				(error) => reject(error)
			);
		});
	}

	importFile(source: string, file: File): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("file", file);
			this.http.post(`/api/leads/import/${source}`, formData).subscribe(
				(leads: any[]) => resolve(leads),
				(error) => reject(error)
			);
		});
	}

	create(source: any, item: any): Promise<any> {
		let payload = { ...source, item: item ? item.id : null };
		return new Promise((resolve, reject) => {
			let url = "/api/leads";
			this.http.post(url, payload).subscribe(
				(lead: any) => resolve(lead),
				(error) => reject(error)
			);
		});
	}

	setUser(id: number, userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/leads/${id}`;
			let payload = { user: userId };
			this.http.patch(url, payload).subscribe(
				(lead: any) => resolve(lead.user),
				(error) => reject(error)
			);
		});
	}

	setState(id: number, state: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/leads/${id}`;
			let payload = { state: state };
			this.http.patch(url, payload).subscribe(
				(lead: any) => resolve(lead),
				(error) => reject(error)
			);
		});
	}

	addNote(id: number, source: any): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/leads/${id}/notes`;
			this.http.post(url, source).subscribe(
				(note: any) => resolve(note),
				(error) => reject(error)
			);
		});
	}

	setValues(id: number, payload): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `/api/leads/${id}`;
			this.http.patch(url, payload).subscribe(
				(lead: any) => resolve(lead.minsize),
				(error) => reject(error)
			);
		});
	}
}
