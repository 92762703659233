<mat-card class="card-block">
	<mat-card-header>
		<mat-card-title>Provisionsfördelning</mat-card-title>
		<div class="card-header-toolbar right"></div>
	</mat-card-header>
	<mat-card-content
		*ngIf="
			['booked', 'overdue', 'paid', 'credited'].includes(invoice.state)
		"
	>
		<table class="table">
			<tr class="totals">
				<td>Totalt arvode</td>
				<td>{{ totalCommissions | numberSv }}</td>
				<td>Provision</td>
				<td>Status</td>
			</tr>
			<tr *ngFor="let s of invoice.splits">
				<td class="title">
					<div>
						<span *ngIf="s.user" class="username">
							{{ s.user.fullname }}
						</span>
						<span *ngIf="s.user && s.description"> - </span>
						<span *ngIf="s.description" class="description">
							{{ s.description }}
						</span>
					</div>
					<div *ngIf="s.error">
						<span class="alert alert-warning">{{ s.error }}</span>
					</div>
				</td>
				<td class="amount">
					<div *ngIf="!s.editingAmount">
						<span
							[ngClass]="{
								'table-link clickable': !s.completed,
								excluded: !s.salaryBonus
							}"
							(click)="!s.completed && (s.editingAmount = true)"
							>{{ s.amount | numberSv }}</span
						>
					</div>
					<div *ngIf="s.editingAmount">
						<input
							#newValue
							class="form-control"
							value="{{ s.amount }}"
							(focusout)="setAmount(s, newValue.value)"
							(change)="setAmount(s, newValue.value)"
						/>
					</div>
				</td>
				<td *ngIf="!s.working">
					<span
						class="badge badge-primary"
						[ngClass]="{ clickable: !s.completed }"
						*ngIf="s.salaryBonus"
						(click)="setSalaryBonus(s, false)"
						>Ja</span
					>
					<span
						class="badge badge-warning"
						[ngClass]="{ clickable: !s.completed }"
						*ngIf="!s.salaryBonus"
						(click)="setSalaryBonus(s, true)"
						>Nej</span
					>
				</td>
				<td class="buttons" *ngIf="!s.working">
					<div *ngIf="s.completed">
						<span class="badge badge-success">Klar</span>
					</div>
					<div *ngIf="!s.completed">
						<span
							mat-button
							[matMenuTriggerFor]="editSplitMenu"
							class="badge badge-secondary clickable"
						>
							Utkast
						</span>
						<mat-menu #editSplitMenu="matMenu">
							<button mat-menu-item (click)="completeSplit(s)">
								Klarmarkera
							</button>
							<button mat-menu-item (click)="deleteSplit(s)">
								Ta bort
							</button>
						</mat-menu>
					</div>
				</td>
				<td colspan="2" class="buttons" *ngIf="s.working">
					<i class="fas fa-lg fa-sync fa-spin" *ngIf="s.working"></i>
				</td>
			</tr>
			<tr class="remaining">
				<td>Återstår att fördela</td>
				<td>{{ remainingCommissions | numberSv }}</td>
				<td>&nbsp;</td>
				<td><!-- status --></td>
			</tr>
			<tr>
				<td>
					<user-select
						title="Lägg till användare"
						btnClass="btn btn-secondary"
						(change)="addSplit($event)"
					></user-select
					>&nbsp;
					<button
						mat-button
						class="btn btn-secondary"
						(click)="addSpecial()"
					>
						Lägg till extern
					</button>
				</td>
				<td>&nbsp;</td>
				<td>&nbsp;</td>
				<td><!-- status --></td>
			</tr>
		</table>
		<p *ngIf="error">
			<span class="alert alert-warning">{{ error }}</span>
		</p>
	</mat-card-content>
</mat-card>
