<div class="alert alert-warning" *ngIf="error">
	{{ error }}
</div>

<div class="alert alert-info" *ngIf="!item && !error">Laddar...</div>

<div *ngIf="item" class="page-padding">
	<mat-card class="card-block">
		<mat-card-header class="row">
			<mat-card-title class="item-name">
				<span
					*ngIf="item.state == 'rented'"
					class="badge badge-primary"
					title="Uthyrd"
					>U</span
				>
				<span
					*ngIf="item.state == 'withdrawn'"
					class="badge badge-dark"
					title="Återtagen"
					>Å</span
				>
				{{ item.name?.text || "[Namnlös]" }}
			</mat-card-title>
			<div class="card-header-toolbar" *ngIf="users.hasRole('item_user')">
				<ul class="card-header-nav">
					<li *ngIf="item.publishWeb">
						<a
							href="{{ item.website }}"
							target="_blank"
							class="fas fa-globe-europe clickable"
							title="Visa hemsida"
						></a>
					</li>
					<li *ngIf="item.publishWeb && exportTexts">
						<span
							[matMenuTriggerFor]="copyMenu"
							class="fas fa-copy clickable"
						>
						</span>
						<mat-menu #copyMenu="matMenu">
							<text-copy-button
								[value]="exportTexts.tr"
								btnClass=""
								title="Klicka för att kopiera"
							>
								<button mat-menu-item>TR</button>
							</text-copy-button>
							<text-copy-button
								[value]="exportTexts.email"
								btnClass=""
								title="Klicka för att kopiera"
							>
								<button mat-menu-item>
									E-post för hyresgäst
								</button>
							</text-copy-button>
						</mat-menu>
					</li>
					<li class="nav-item-users">
						<user-link
							*ngFor="let c of item.contacts"
							[user]="c.user"
						></user-link>
					</li>
				</ul>
			</div>
			<div
				class="card-header-toolbar right"
				*ngIf="users.hasRole('item_user')"
			>
				<ul class="card-header-nav">
					<li>
						<a
							[routerLink]="['start']"
							class="fas fa-home"
							routerLinkActive="active"
							title="Uppstart"
						></a>
					</li>
					<li>
						<a
							[routerLink]="['attributes']"
							class="fas fa-quote-right"
							routerLinkActive="active"
							title="Egenskaper"
						></a>
					</li>
					<li>
						<a
							[routerLink]="['files']"
							class="fas fa-image"
							routerLinkActive="active"
							title="Bilagor"
						></a>
					</li>
					<li>
						<a
							[routerLink]="['leads']"
							class="fas fa-envelope"
							routerLinkActive="active"
							title="Intressenter"
						></a>
					</li>
					<li>
						<a
							[routerLink]="['contract']"
							class="fas fa-file"
							routerLinkActive="active"
							title="Affär"
						></a>
					</li>
				</ul>
			</div>
		</mat-card-header>
	</mat-card>

	<item-state-warning [item]="item"></item-state-warning>

	<router-outlet></router-outlet>
</div>
