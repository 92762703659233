<item-tags></item-tags>
<div class="row">
	<div class="col-md">
		<item-districts [item]="item"></item-districts>
	</div>
	<div class="col-md">
		<item-cost [item]="item"></item-cost>
	</div>
</div>
<mat-card class="card-block" *ngFor="let section of sections">
	<mat-card-header>
		<mat-card-title>{{ section.name }}</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div *ngFor="let row of section.rows" class="row">
			<ng-container *ngFor="let a of row">
				<div
					class="col form-group"
					*ngIf="
						values &&
						(!a.requirement ||
							values[a.requirement.attributeId]?.text ==
								a.requirement.text)
					"
				>
					<label
						>{{ a.name }}
						<span *ngIf="a.error" class="badge badge-danger">{{
							a.error
						}}</span> </label
					><span
						*ngIf="a.required && !values[a.id]?.text"
						class="invalid-form-field-symbol"
						>😵</span
					>
					<div *ngIf="!a.working">
						<div
							*ngIf="
								a.type == 'select' || a.type == 'select_text'
							"
						>
							<select
								#newValue
								class="form-control"
								(change)="saveValue(a, newValue.value)"
							>
								<option
									selected
									value="{{ values[a.id]?.text }}"
								>
									{{ values[a.id]?.text }}
								</option>
								<option *ngFor="let o of a.otherOptions">
									{{ o.text }}
								</option>
								<option
									disabled
									*ngIf="a.type == 'select_text'"
								>
									&nbsp;
								</option>
								<option
									value="_SELECT_CUSTOM_TEXT"
									*ngIf="a.type == 'select_text'"
								>
									Fritext
								</option>
							</select>
						</div>
						<div *ngIf="a.type == 'int'">
							<input
								#newValue
								type="number"
								class="form-control"
								value="{{ values[a.id]?.text }}"
								(focusout)="saveValue(a, newValue.value)"
								(change)="saveValue(a, newValue.value)"
							/>
						</div>
						<div *ngIf="a.type == 'text'">
							<input
								#newValue
								type="text"
								class="form-control"
								value="{{ values[a.id]?.text }}"
								[ngbTypeahead]="a.autocomplete"
								(focusout)="saveValue(a, newValue.value)"
							/>
						</div>
						<div *ngIf="a.type == 'text_block'">
							<textarea
								#newValue
								type="text"
								class="form-control"
								(focusout)="saveValue(a, newValue.value)"
								>{{ values[a.id]?.text }}</textarea
							>
						</div>
						<div *ngIf="a.type == 'boolean'">
							<div
								*ngIf="values[a.id]?.text"
								(click)="saveValue(a, '')"
							>
								<button
									class="btn btn-outline-success btn-block"
								>
									Ja
								</button>
							</div>
							<div
								*ngIf="!values[a.id]?.text"
								(click)="saveValue(a, 'Ja')"
							>
								<button
									class="btn btn-outline-secondary btn-block"
								>
									&nbsp;
								</button>
							</div>
						</div>
						<div *ngIf="a.type == 'range'" class="row">
							<div class="col">
								<input
									#newValueMin
									type="text"
									class="form-control"
									value="{{ values[a.id]?.rangeMin }}"
									(focusout)="
										saveRange(
											a,
											newValueMin.value,
											newValueMax.value
										)
									"
									placeholder="Min"
								/>
							</div>
							<div class="col">
								<input
									#newValueMax
									type="text"
									class="form-control"
									value="{{ values[a.id]?.rangeMax }}"
									(focusout)="
										saveRange(
											a,
											newValueMin.value,
											newValueMax.value
										)
									"
									placeholder="Max (valfritt)"
								/>
							</div>
						</div>
					</div>
					<div *ngIf="a.working">
						<input
							disabled
							class="form-control"
							value="Sparar..."
						/>
					</div>
				</div>
			</ng-container>
		</div>
	</mat-card-content>
</mat-card>
