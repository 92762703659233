import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";

import { UsersService } from "core/users/users.service";
import { LeadService } from "core/leads/leads.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";
import { LeadDetailEditDialog } from "./edit/lead-detail-edit.component";

@Component({
	selector: "kt-lead-detail",
	templateUrl: "./lead-detail.component.html",
	styleUrls: ["./lead-detail.component.scss"],
})
export class LeadDetailComponent implements OnInit {
	loading: boolean = false;
	importing: boolean = false;
	error: string = null;
	lead: any;

	userForm = new RequestForm(true);

	addNote = new RequestForm(
		false,
		new UntypedFormGroup({
			message: new UntypedFormControl("", [Validators.required]),
		})
	);

	constructor(
		private change: ChangeDetectorRef,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private leadService: LeadService
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.reload(parseInt(params.get("id")));
		});
	}

	private reload(id: number) {
		this.loading = true;
		this.error = null;
		this.leadService
			.getLead(id)
			.then((lead: any) => (this.lead = lead))
			.catch((error: any) => (this.error = error.error))
			.then(() => {
				this.loading = false;
				this.change.detectChanges();
			});
	}

	setUser(user: any) {
		this.userForm.setWorking();
		this.leadService
			.setUser(this.lead.id, user ? user.id : -1)
			.then((user: any) => {
				this.lead.user = user;
				this.userForm.reset();
			})
			.catch((error: any) => this.userForm.setError(error.error))
			.then(() => {
				this.reload(this.lead.id);
			});
	}

	submitNote() {
		this.addNote.error = null;
		this.addNote.working = true;
		this.leadService
			.addNote(this.lead.id, this.addNote.getValue())
			.then((note: any) => {
				this.lead.notes.unshift(note);
				this.addNote.reset();
			})
			.catch((error: any) => (this.addNote.error = error.error))
			.then(() => {
				this.addNote.working = false;
				this.change.detectChanges();
			});
	}
	edit() {
		let editDialog = this.dialog.open(LeadDetailEditDialog, {
			data: { lead: this.lead },
		});
		editDialog.afterClosed().subscribe(() => {
			console.log("closed");
			this.reload(this.lead.id);
		});
	}
}
