import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { FileService } from "core/files/files.service";
import { DocumentService } from "core/documents/documents.service";
import { UsersService } from "core/users/users.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";

@Component({
	selector: "kt-document-list",
	templateUrl: "./document-list.component.html",
	styleUrls: ["./document-list.component.scss"],
})
export class DocumentListComponent implements OnInit {
	documents: any = [];
	directories: any = [];
	errors: any[] = [];

	uploadForm = new RequestForm(true);

	private query: any = {};

	constructor(
		private route: ActivatedRoute,
		private documentService: DocumentService,
		private fileService: FileService,
		public userService: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe((query) => this.navigate(query));
	}

	navigate(query: any) {
		this.query = query;
		this.loadDocuments();
		this.loadDirectories();
	}

	private loadDocuments() {
		this.documentService
			.getDocuments(this.query)
			.then((documents: any[]) => {
				this.documents = documents;
				this.change.detectChanges();
			})
			.catch((error) => {
				this.errors.push(error.error);
			});
	}

	private loadDirectories() {
		this.documentService
			.getDirectories(this.query)
			.then((directories: any[]) => {
				this.directories = directories;
				this.change.detectChanges();
			})
			.catch((error) => {
				this.errors.push(error.error);
			});
	}

	uploadFile(fileInput: any) {
		let file: File = fileInput.target.files[0];
		this.uploadForm.show();
		this.uploadForm.setWorking();
		this.fileService
			.upload(file)
			.then((file: any) => this.createDocument(file))
			.catch((error) => this.uploadForm.setError(error.error))
			.then(() => this.change.detectChanges());
	}

	createDocument(file: any) {
		this.documentService
			.create(file.name, "unknown", file, this.query.directory)
			.then((document: any) => {
				this.documents.push(document);
				this.uploadForm.reset();
			})
			.catch((error) => {
				console.log(error);
				this.uploadForm.setError(error.error);
			})
			.then(() => this.change.detectChanges());
	}
}
