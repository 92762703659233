import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class DistrictService {
	constructor(private http: HttpClient) {}

	loadDistricts(): Promise<any[]> {
		let url = "/api/districts";

		return new Promise<any[]>((resolve, reject) => {
			this.http.get(url).subscribe(
				(data: any[]) => {
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	districtsAsOptions(): Promise<any[]> {
		return this.loadDistricts().then((districts) => {
			let options = [];
			for (let d of districts) {
				options.push({ value: d, title: d });
			}
			return options;
		});
	}

	createDistrict(
		name: string,
		sort: number,
		coordinates: string
	): Promise<any> {
		let payload = { name: name, sort: sort, coordinates: coordinates };
		return new Promise<any>((resolve, reject) => {
			this.http.post("/api/districts", payload).subscribe(
				(data: any) => resolve(data),
				(error) => reject(error)
			);
		});
	}

	editDistrict(id: number, coordinates: string): Promise<any> {
		let payload = { id: id, coordinates: coordinates };
		return new Promise<any>((resolve, reject) => {
			this.http.put("/api/districts", payload).subscribe(
				(data: any) => resolve(data),
				(error) => reject(error)
			);
		});
	}
}
