<div class="wrapper">
	<div *ngIf="error" class="alert alert-warning">{{ error }}</div>
	<div class="item-list">
		<div
			class="item"
			*ngIf="item"
			[ngClass]="{ added: !item.removed, removed: item.removed }"
			[style.background-image]="
				'url(' + item.images[0]?.url + '?width=960)'
			"
		>
			<div class="item-text">
				<div class="logotype">
					<img
						alt="New Property"
						src="./assets/media/logos/logo-light.svg"
					/>
				</div>
				<div
					class="item-title"
					[ngClass]="{ 'long-text': item.title?.length > 20 }"
				>
					{{ item.title || "&nbsp;" }}
				</div>
				<div class="item-size" *ngIf="item.size">
					{{ item.size.text }} m²
				</div>
				<div class="item-pitch" *ngIf="item.pitch">
					{{ item.pitch.text }}
				</div>
				<div class="item-spacing"></div>
				<div class="item-users">
					<div class="user" *ngFor="let c of item.contacts">
						<div
							class="user-image"
							[style.background-image]="
								'url(' +
								c.user.picture?.url +
								'?width=192&height=108)'
							"
						></div>
						<div class="user-fullname">{{ c.user.fullname }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="image-load-hack" *ngIf="nextItem">
	<img src="{{ nextItem.images[0]?.url }}?width=960" />
	<img *ngFor="let c of nextItem.contacts" src="{{ c.user.picture?.url }}" />
</div>
