import { Component, OnInit, Inject } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { getNote } from "../lead-list.component";

export interface DialogData {
	leads: any[];
}

@Component({
	selector: "kt-lead-list-export",
	templateUrl: "./lead-list-export.component.html",
	styleUrls: ["./lead-list-export.component.scss"],
})
export class LeadListExportDialog implements OnInit {
	working: boolean = true;
	error: null;
	csvContent: "";
	csvurl: "";
	warnings: string[] = [];
	leads: any[] = [];

	constructor(
		public dialogRef: MatDialogRef<LeadListExportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		this.leads = data.leads;
	}

	ngOnInit() {
		this.csvurl = "";
		this.csvContent = "";
		this.csvContent +=
			"inkommit,företag,kontaktnamn,kontaktepost,kontakttelefon,tillträde,maxstorlek,minstorlek,maxhyra,ansvarig,status\n";
		this.leads.forEach((lead) => {
			let line = [
				this.sanitize(lead.created),
				this.sanitize(lead.company),
				this.sanitize(lead.fullname),
				this.sanitize(lead.email),
				this.sanitize(lead.phone),
				this.sanitize(lead.access_date),
				this.sanitize(lead.maxsize),
				this.sanitize(lead.minsize),
				this.sanitize(lead.maxrent),
				this.sanitize(lead.user?.fullname),
				this.sanitize(getNote(lead)),
			];
			this.csvContent += line.join(",");
			this.csvContent += "\n";
		});
		this.working = false;
	}
	sanitize(value) {
		if (!value) {
			return;
		} else {
			let sanitized = "";
			const chars = [...String(value)];
			chars.forEach((c) => {
				if (c === '"') {
					c = "'";
				}
				sanitized += c;
			});
			return '"' + sanitized + '"';
		}
	}
}
