import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { PhotoOrderService } from "core/files/photo-order.service";
import { RequestForm } from "views/partials/form-state/form-state.component";

@Component({
	selector: "photo-order",
	templateUrl: "./photo-order.component.html",
	styleUrls: ["./photo-order.component.scss"],
})
export class PhotoOrderComponent {
	@Input()
	item: any;

	form = new RequestForm(true, null);

	constructor(
		private service: PhotoOrderService,
		private change: ChangeDetectorRef
	) {}

	public async createOrder() {
		try {
			this.form.setWorking();
			let order = await this.service.create(this.item);
			this.item.photoOrders.push(order);
			this.form.reset();
		} catch (error) {
			console.log(error);
			this.form.setError(error.statusText ?? error);
		} finally {
			this.change.detectChanges();
		}
	}
}
