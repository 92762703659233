<div class="wrapper" *ngIf="item?.state == 'available'">
	<span
		class="badge {{ getLabel().class }}"
		[matMenuTriggerFor]="changeMenu"
		>{{ getLabel().text }}</span
	>
	<span class="badge badge-danger" *ngIf="errorMessage">{{
		errorMessage
	}}</span>
	<mat-menu #changeMenu="matMenu">
		<button mat-menu-item (click)="setProbability(25)">
			&nbsp;&nbsp;0 - 50 %
		</button>
		<button mat-menu-item (click)="setProbability(50)">50 - 75 %</button>
		<button mat-menu-item (click)="setProbability(75)">75 - 100 %</button>
	</mat-menu>
</div>
<span *ngIf="item?.state == 'new'" class="badge badge-secondary" title="Ny"
	>Ny</span
>
<span *ngIf="item?.state == 'rented'" class="badge badge-primary" title="Uthyrd"
	>Uthyrd</span
>
<span
	*ngIf="item?.state == 'withdrawn'"
	class="badge badge-dark"
	title="Återtagen"
	>Återtagen</span
>
