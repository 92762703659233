import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "invoice-split",
	templateUrl: "./invoice-split.component.html",
	styleUrls: ["./invoice-split.component.scss"],
})
export class InvoiceSplitComponent implements OnChanges {
	@Input("invoice")
	invoice: any;
	totalCommissions: number;
	remainingCommissions: number;

	error: string = null;

	constructor(
		private http: HttpClient,
		public users: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.invoice) {
			this.recalculate();
		}
	}

	addSplit(user: any) {
		this.error = null;
		this.http
			.post(`/api/invoices/${this.invoice.id}/splits`, {
				userId: user.id,
			})
			.subscribe(
				(split: any) => {
					this.invoice.splits.push(split);
					this.recalculate();
				},
				(error) => (this.error = error.error)
			);
	}

	addSpecial() {
		let description = prompt("Ange beskrivning");
		if (!description) {
			return;
		}
		this.error = null;
		this.http
			.post(`/api/invoices/${this.invoice.id}/splits`, {
				description: description,
			})
			.subscribe(
				(split: any) => {
					this.invoice.splits.push(split);
					this.recalculate();
				},
				(error) => (this.error = error.error)
			);
	}

	setAmount(split: any, value: string) {
		split.working = true;
		split.error = null;
		this.http
			.patch(`/api/invoices/${this.invoice.id}/splits/${split.id}`, {
				amount: value,
			})
			.subscribe(
				() => {
					split.amount = value;
					split.editingAmount = false;
				},
				(error) => (split.error = error.error)
			)
			.add(() => {
				split.working = false;
				this.recalculate();
			});
	}

	setSalaryBonus(split: any, salaryBonus: boolean) {
		if (split.completed) {
			return;
		}
		split.working = true;
		split.error = null;
		this.http
			.patch(`/api/invoices/${this.invoice.id}/splits/${split.id}`, {
				salaryBonus: salaryBonus,
			})
			.subscribe(
				(data: any) => {
					split.salaryBonus = data.salaryBonus;
				},
				(error) => (split.error = error.error)
			)
			.add(() => {
				split.working = false;
				this.recalculate();
			});
	}

	completeSplit(split: any) {
		split.working = true;
		split.error = null;
		this.http
			.patch(`/api/invoices/${this.invoice.id}/splits/${split.id}`, {
				completed: true,
			})
			.subscribe(
				(data: any) => {
					split.completed = data.completed;
				},
				(error) => (split.error = error.error)
			)
			.add(() => {
				split.working = false;
				this.recalculate();
			});
	}

	deleteSplit(split: any) {
		split.working = true;
		split.error = null;
		this.http
			.delete(`/api/invoices/${this.invoice.id}/splits/${split.id}`)
			.subscribe(
				() => {
					this.invoice.splits = this.invoice.splits.filter(
						(s: any) => {
							return s.id != split.id;
						}
					);
				},
				(error) => (split.error = error.error)
			)
			.add(() => {
				split.working = false;
				this.recalculate();
			});
	}

	private recalculate() {
		this.totalCommissions = 0;
		this.invoice.commissions.forEach((c: any) => {
			this.totalCommissions += c.amount;
		});
		this.remainingCommissions = this.totalCommissions;
		this.invoice.splits.forEach((s: any) => {
			this.remainingCommissions -= s.amount;
		});
		this.change.detectChanges();
	}
}
