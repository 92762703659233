import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../core/auth";

@Component({
	selector: "login-page",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	today: number = Date.now();
	oAuthUrl: string = null;

	constructor(private change: ChangeDetectorRef, private auth: AuthService) {}

	ngOnInit(): void {
		this.auth.logout();

		this.auth.getOAuthURL().then((url) => {
			this.oAuthUrl = url;
			this.change.detectChanges();
		});
	}
}
