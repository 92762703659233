<div mat-dialog-title>
	<div *ngIf="working">Laddar...</div>
	<div *ngIf="!working">Export färdig</div>
</div>
<hr />
<mat-dialog-content>
	<div *ngIf="error" class="alert alert-danger">{{ error }}</div>
	<div *ngFor="let w of warnings" class="alert alert-warning">{{ w }}</div>
	<pre>{{ text }}</pre>
</mat-dialog-content>
<hr />
<mat-dialog-actions align="center">
	<div *ngIf="!working && !error">
		<span>
			<text-copy-button [value]="text" btnClass="btn btn-primary"
				>Kopiera</text-copy-button
			>
		</span>
		<span *ngIf="type == 'csv'">
			<csv-download-button
				content="{{ text }}"
				filename="items.csv"
				btnClass="btn btn-primary"
				>Ladda ned</csv-download-button
			>
		</span>
	</div>
</mat-dialog-actions>
