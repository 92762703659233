<div class="map-container" id="ol-map">
	<div id="popup" class="item-popup">
		<div *ngIf="overlayItem">
			<div
				class="item-image"
				[style]="
					'background-image: url(/api/items/' +
					overlayItem.id +
					'/thumbnail);'
				"
			></div>
			<div class="item-header">
				<div class="item-title">
					<a href="/items/{{ overlayItem.id }}">{{
						overlayItem.title
					}}</a>
				</div>
				<div class="item-size" *ngIf="overlayItem.size">
					{{ overlayItem.size.text }} m²
				</div>
			</div>
			<div class="item-info" *ngIf="overlayItem.contract?.rent">
				<div class="item-info-label">Avtalad hyra:</div>
				<div>{{ overlayItem.contract?.rent }} kr/m²/år</div>
			</div>
		</div>
	</div>
	<div id="districtPopup" class="district-popup">
		<div *ngIf="selectedDistrict" class="district-name">
			{{ selectedDistrict.get("name") }}
		</div>
	</div>
	<div class="row map-controls">
		<ul>
			<li>
				<div>Adress</div>
				<div>
					<text-search
						placeholder=""
						queryParam="title"
						width="120px"
					></text-search>
				</div>
			</li>
			<li>
				<div>Ansvarig</div>
				<div>
					<user-filter roles="item_user"></user-filter>
				</div>
			</li>
			<li *ngFor="let filter of dropdowns">
				<div>{{ filter.title }}</div>
				<div>
					<dropdown-select
						[options]="filter.options"
						[queryParam]="filter.queryParam"
					></dropdown-select>
				</div>
			</li>
			<li>
				<div>Område</div>
				<div>
					<district-select queryParam="districts"></district-select>
				</div>
			</li>
			<li>
				<div>Typ</div>
				<div>
					<item-type-select queryParam="types"></item-type-select>
				</div>
			</li>
			<li>
				<div>Area m²</div>
				<div>
					<text-search
						placeholder="Min"
						queryParam="minsize"
						width="60px"
					></text-search>
					<text-search
						placeholder="Max"
						queryParam="maxsize"
						width="60px"
					></text-search>
				</div>
			</li>
			<li>
				<div>Hyra per m²</div>
				<div>
					<text-search
						placeholder="Min"
						queryParam="minrent"
						width="60px"
					></text-search>
					<text-search
						placeholder="Max"
						queryParam="maxrent"
						width="60px"
					></text-search>
				</div>
			</li>
			<li>
				<div>Månadshyra</div>
				<div>
					<text-search
						placeholder=""
						queryParam="totalrent"
						width="90px"
					></text-search>
				</div>
			</li>
			<li *ngIf="query.state == 'rented'">
				<div>Tillträdesdatum</div>
				<div>
					<date-search
						placeholder="Startdatum"
						queryParam="startdate"
						width="140px"
					></date-search>
					<date-search
						placeholder="Slutdatum"
						queryParam="enddate"
						width="140px"
					></date-search>
				</div>
			</li>
		</ul>
	</div>
	<div class="export-controls">
		<ul>
			<li *ngIf="exportCoordinates">
				<pre class="export-coordinates">{{ exportCoordinates }}</pre>
			</li>
			<li *ngIf="exportCoordinates">
				<span
					*ngIf="!districtToEdit"
					class="btn btn-secondary"
					(click)="createDistrict()"
					>Skapa område</span
				>
				<span
					*ngIf="districtToEdit"
					class="btn btn-secondary"
					(click)="editDistrict()"
					><i class="fas fa-fw fa-save"></i> Spara ändringar</span
				>
			</li>
			<li *ngIf="districtToEdit">
				<span> Redigerar "{{ districtToEdit.get("name") }}" </span>
			</li>
			<li>
				<span
					*ngIf="selectedDistrict && !districtToEdit"
					title="Redigera område"
					class="btn btn-secondary"
					(click)="beginEditingDistrict()"
					><i class="fas fa-fw fa-pen"></i> Redigera område</span
				>
				<span
					*ngIf="districtToEdit"
					title="Avbryt redigering"
					class="btn btn-secondary"
					(click)="exitEditingDistrict()"
					><i class="fas fa-fw fa-ban"></i> Avbryt redigering</span
				>
			</li>
			<li *ngIf="exportMenuOpen">
				<span>Rita för att markera</span>
			</li>
			<li *ngIf="exportMenuOpen">
				<a
					title="Exportera till e-post"
					class="clickable"
					(click)="exportItems('email')"
					><i class="fas fa-fw fa-envelope"></i> Exportera till e-post
				</a>
			</li>
			<li
				*ngIf="
					exportMenuOpen &&
					!(query.state == 'available' || query.state == 'withdrawn')
				"
			>
				<a
					title="Exportera uthyrda"
					class="clickable"
					(click)="exportItems('rented')"
					><i class="fas fa-fw fa-envelope"></i> Exportera uthyrda
				</a>
			</li>
			<li *ngIf="exportMenuOpen">
				<a
					title="Ladda ned karta"
					class="clickable"
					(click)="exportItemsMap()"
					><i class="fas fa-fw fa-download"></i> Ladda ned karta</a
				>
			</li>
			<li>
				<button
					*ngIf="exportMenuOpen"
					class="btn btn-primary"
					(click)="closeExportMenu()"
				>
					<i class="fas fa-fw fa-chevron-down"></i>
					Exportera
				</button>
				<button
					*ngIf="!exportMenuOpen && !districtToEdit"
					class="btn btn-secondary"
					(click)="openExportMenu()"
				>
					<i class="fas fa-fw fa-chevron-up"></i>
					Exportera
				</button>
			</li>
		</ul>
	</div>
</div>
