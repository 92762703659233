<div class="page-padding">
	<mat-card>
		<mat-card-header>
			<mat-card-title>Företag</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr>
					<th>Namn</th>
					<th>Fortnox kopplat</th>
					<th>&nbsp;</th>
				</tr>
				<tr *ngFor="let company of companies">
					<td>{{ company.name }}</td>
					<td>{{ company.expires ?? "Saknas" }}</td>
					<td>
						<button
							class="btn btn-secondary btn-sm"
							(click)="authorizeFortnox(company)"
						>
							Auktorisera
						</button>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
