import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class InvoiceService {
	types: any[] = [
		{ value: "startup", title: "Uppstart", badge: "primary" },
		{ value: "closing", title: "Avslut", badge: "primary" },
		{ value: "transaction", title: "Transaktion", badge: "primary" },
		{ value: "analysis", title: "Analys", badge: "secondary" },
		{ value: "ongoing", title: "Löpande", badge: "secondary" },
		{ value: "referral", title: "Tips", badge: "secondary" },
		{ value: "other", title: "Övrigt", badge: "secondary" },
	];

	constructor(private http: HttpClient) {}

	public loadInvoices(query: any): Promise<any[]> {
		return new Promise((resolve, reject) => {
			let conditions: string[] = [];
			if (query.user > 0) {
				conditions.push("user=" + query.user);
			}
			if (query.state && query.state != "any") {
				conditions.push("state=" + query.state);
			}
			let url = "/api/invoices";
			if (conditions.length > 0) {
				url += "?" + conditions.join("&");
			}
			this.http.get(url).subscribe(
				(data: any[]) => {
					data.forEach((invoice: any) => {
						this.calculateRemaining(invoice);
					});
					resolve(data);
				},
				(error) => reject(error)
			);
		});
	}

	public refresh(invoice: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http
				.put(`/api/invoices/${invoice.id}/refresh`, null)
				.subscribe(
					(data) => {
						Object.assign(invoice, data);
						this.calculateRemaining(invoice);
						resolve(invoice);
					},
					(error) => reject(error)
				);
		});
	}

	public calculateRemaining(invoice: any) {
		invoice.totalCommissions = 0;
		invoice.commissions.forEach((c: any) => {
			invoice.totalCommissions += c.amount;
		});
		invoice.remainingCommissions = invoice.totalCommissions;
		invoice.splits.forEach((s: any) => {
			if (s.completed) {
				invoice.remainingCommissions -= s.amount;
			}
		});
	}
}
