import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	Output,
	EventEmitter,
} from "@angular/core";

import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";

import { UsersService } from "core/users/users.service";

@Component({
	selector: "item-invoice",
	templateUrl: "./item-invoice.component.html",
	styleUrls: ["./item-invoice.component.scss"],
})
export class ItemInvoiceComponent implements OnInit {
	@Input("title")
	title: string;

	@Input("type")
	type: string;

	@Input("item")
	item: any;

	companies: any[];
	commissions: any[];

	working: boolean = false;
	error: string = null;

	invoiceLink: any;

	invoiceForm = new UntypedFormGroup({
		companyId: new UntypedFormControl("", [
			Validators.required,
			Validators.min(1),
		]),
		invoiceNumber: new UntypedFormControl("", [Validators.required]),
		fee: new UntypedFormControl("", [
			Validators.required,
			Validators.min(1),
		]),
	});

	constructor(
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private userService: UsersService
	) {}

	ngOnInit() {
		this.loadCommissions();
		this.loadCompanies();
	}

	loadCommissions() {
		this.http
			.get(`/api/items/${this.item.id}/commissions?type=${this.type}`)
			.subscribe((commissions: any[]) => {
				this.commissions = commissions;
				this.change.detectChanges();
			});
	}

	loadCompanies() {
		this.http.get("/api/companies").subscribe((data: any[]) => {
			this.companies = data;
			this.change.detectChanges();
		});
		this.invoiceForm.controls.companyId.setValue(3); // NP Group
	}

	addFee() {
		this.working = true;
		this.error = null;
		this.http.post("/api/invoices", this.invoiceForm.value).subscribe(
			(invoice: any) => this.addFeeToInvoice(invoice),
			(error) => {
				this.error = "Registrering ej genomförd: " + error.error;
				this.working = false;
				this.change.detectChanges();
			}
		);
	}

	removeCommission(commission: any) {
		this.http
			.delete(`/api/items/${this.item.id}/commissions/${commission.id}`)
			.subscribe(
				(commissions: any[]) => {
					let index = this.commissions.indexOf(commission);
					if (index >= 0) {
						this.commissions.splice(index, 1);
					}
					this.change.detectChanges();
				},
				(error) => alert(error.error)
			);
	}

	addZeroFee() {
		let commission = {
			type: this.type,
			amount: 0,
			itemId: this.item.id,
		};
		this.http
			.post(`/api/invoices/0/commissions`, commission)
			.subscribe(
				(commission: any) => {
					this.commissions.push(commission);
				},
				(error) =>
					(this.error = "Registrering ej genomförd: " + error.error)
			)
			.add(() => {
				this.working = false;
				this.change.detectChanges();
			});
	}

	private addFeeToInvoice(invoice: any) {
		let commission = {
			type: this.type,
			amount: this.invoiceForm.value.fee,
			itemId: this.item.id,
		};
		this.http
			.post(`/api/invoices/${invoice.id}/commissions`, commission)
			.subscribe(
				(commission: any) => {
					this.commissions.push(commission);
				},
				(error) => {
					this.invoiceLink = invoice;
					this.error = "Registrering ej genomförd: " + error.error;
				}
			)
			.add(() => {
				this.working = false;
				this.change.detectChanges();
			});
	}
}
