import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	FormArray,
	UntypedFormControl,
	Validators,
	FormControl,
} from "@angular/forms";

import { SubscriberService } from "core/subscribers/subscribers.service";

@Component({
	selector: "kt-item-contract",
	templateUrl: "./item-contract.component.html",
	styleUrls: ["./item-contract.component.scss"],
})
export class ItemContractComponent implements OnInit {
	item: any;
	working: boolean = false;
	error: String = null;
	withdrawError: String = null;
	users: any[];

	contractForm = new UntypedFormGroup({
		tenantName: new UntypedFormControl("", [Validators.required]),
		orgNumber: new UntypedFormControl("", [Validators.required]),
		rent: new UntypedFormControl("", [
			Validators.required,
			Validators.min(1),
		]),
		size: new UntypedFormControl("", [
			Validators.required,
			Validators.pattern("^[0-9]*$"),
			Validators.min(1),
		]),
		firstname: new UntypedFormControl("", [Validators.required]),
		lastname: new UntypedFormControl("", [Validators.required]),
		email: new UntypedFormControl("", [
			Validators.required,
			Validators.email,
		]),
		phone: new UntypedFormControl("", [Validators.required]),
		oneShot: new FormControl<boolean>(false),
		start: new UntypedFormControl("", [Validators.required]),
		end: new UntypedFormControl("", [Validators.required]),
		notice: new UntypedFormControl("", [Validators.required]),
		comments: new UntypedFormControl(""),
		tenantAudit: new UntypedFormGroup({
			currentSwedishOffice: new UntypedFormControl("", [
				Validators.required,
			]),
			excessiveBudget: new UntypedFormControl("", [Validators.required]),
			swedishCompany: new UntypedFormControl("", [Validators.required]),
			reasonForMoving: new UntypedFormControl("", [Validators.required]),
		}),
	});

	ctls = this.contractForm.controls;

	constructor(
		public subscribers: SubscriberService,
		private route: ActivatedRoute,
		private http: HttpClient,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe((params) => {
			this.http
				.get("/api/items/" + params.get("itemID"))
				.subscribe((data) => {
					this.item = data;
					if (this.item.size) {
						this.contractForm.controls.size.setValue(
							this.item.size.text
						);
					}
					this.loadUsers();
					this.change.detectChanges();
				});
		});
		this.contractForm.valueChanges.subscribe((value) => {
			this.error = null;
		});
	}

	setEstimatedCommission(value: number, note: string) {
		this.http
			.put(`/api/items/${this.item.id}/estimatedCommission`, {
				estimatedCommission: value,
				commissionNote: note,
			})
			.subscribe(
				(data) => Object.assign(this.item, data),
				(error) => alert(error.error)
			)
			.add(() => this.change.detectChanges());
	}

	createContract() {
		let contract = { ...this.contractForm.value };
		contract.tenantAudit =
			`Kontor i Sverige: ${contract.tenantAudit.currentSwedishOffice}\n` +
			`Onormalt hög budget: ${contract.tenantAudit.excessiveBudget}\n` +
			`Svenskt bolag: ${contract.tenantAudit.swedishCompany}\n` +
			`Anledning till flytt: ${contract.tenantAudit.reasonForMoving}`;
		this.working = true;
		this.error = null;
		this.http
			.post("/api/items/" + this.item.id + "/contract", contract)
			.subscribe(
				(data: any) => {
					this.item.state = "rented";
					this.item.contract = data;
					this.unpublish();
				},
				(error) => {
					this.error = "Fel: " + error.status;
				}
			)
			.add(() => {
				this.working = false;
				this.change.detectChanges();
			});
	}

	canBeWithdrawn(): boolean {
		return (
			this.item &&
			!this.item.contract &&
			["available", "new"].includes(this.item.state)
		);
	}

	withdraw() {
		this.working = true;
		this.withdrawError = null;
		this.http
			.post("/api/items/" + this.item.id + "/withdraw", {})
			.subscribe(
				(data: any) => {
					this.item.state = data.state;
					this.unpublish();
				},
				(error) => {
					this.withdrawError = "Fel: " + error.status;
				}
			)
			.add(() => {
				this.working = false;
				this.change.detectChanges();
			});
	}

	unpublish() {
		if (this.item.publishWeb) {
			this.http
				.delete("/api/items/" + this.item.id + "/publish/web")
				.subscribe();
		}
		if (this.item.publishObjektvision) {
			this.http
				.delete("/api/items/" + this.item.id + "/publish/objektvision")
				.subscribe();
		}
		if (this.item.publishLokalguiden) {
			this.http
				.delete("/api/items/" + this.item.id + "/publish/lokalguiden")
				.subscribe();
		}
	}

	invalid(control: FormControl) {
		return !control.valid && !control.untouched;
	}

	loadUsers() {
		this.http.get("/api/users").subscribe((data: any[]) => {
			this.users = data;
			this.change.detectChanges();
		});
	}
}
