// Angular
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
// Material
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
// CRUD
import { InterceptService } from "../../../core/auth/intercept.service";
// Module components
import { LoginComponent } from "./login/login.component";
import { OAuthComponent } from "./oauth/oauth.component";
// Auth
import { AuthService } from "../../../core/auth";

const routes: Routes = [
	{
		path: "",
		component: LoginComponent,
		data: { returnUrl: window.location.pathname },
		children: [
			{
				path: "login",
				redirectTo: "",
				pathMatch: "full",
			},
		],
	},
	{
		path: "oauth",
		component: OAuthComponent,
		data: { returnUrl: window.location.pathname },
		children: [
			{
				path: "login",
				redirectTo: "",
				pathMatch: "full",
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatCardModule,
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true,
		},
	],
	exports: [],
	declarations: [LoginComponent, OAuthComponent],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [AuthService],
		};
	}
}
