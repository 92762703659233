import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { LeadService } from "core/leads/leads.service";
import { RequestForm } from "../form-state/form-state.component";

@Component({
	selector: "add-lead-form",
	templateUrl: "./add-lead-form.component.html",
	styleUrls: ["./add-lead-form.component.scss"],
})
export class AddLeadFormComponent implements OnInit {
	@Output() created: EventEmitter<any> = new EventEmitter();
	@Input() item: any;

	public form = new RequestForm(
		true,
		new UntypedFormGroup({
			source: new UntypedFormControl(""),
			fullname: new UntypedFormControl("", []),
			message: new UntypedFormControl("", []),
			title: new UntypedFormControl("", []),
			company: new UntypedFormControl("", [Validators.required]),
			email: new UntypedFormControl("", []),
			phone: new UntypedFormControl("", []),
			access_date: new UntypedFormControl("", []),
			minsize: new UntypedFormControl("", [Validators.pattern("[0-9]*")]),
			maxsize: new UntypedFormControl("", [Validators.pattern("[0-9]*")]),
			maxrent: new UntypedFormControl("", [Validators.pattern("[0-9]*")]),
		})
	);

	constructor(
		private changeDetector: ChangeDetectorRef,
		private leadService: LeadService
	) {}

	ngOnInit() {}

	submitLead() {
		this.form.setWorking();
		this.leadService
			.create(this.form.getValue(), this.item)
			.then((lead: any) => {
				this.form.setSuccess("Registrerad");
				this.created.emit(lead);
			})
			.catch((error: any) => this.form.setError(error.error))
			.then(() => this.changeDetector.detectChanges());
	}
}
