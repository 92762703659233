import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { UsersService } from "core/users/users.service";
import { ItemService } from "core/items/items.service";
import { Subscription } from "rxjs";

class Value {
	attribute;
	text;
}

@Component({
	selector: "kt-item-detail",
	templateUrl: "./item-detail.component.html",
	styleUrls: ["./item-detail.component.scss"],
})
export class ItemDetailComponent implements OnInit, OnDestroy {
	item;
	sections: any[];
	values;
	error = null;

	exportTexts = {};

	states = {
		new: { name: "Nytt", label: "badge badge-primary" },
		signed: { name: "Signerat", label: "badge badge-success" },
		active: { name: "Aktivt", label: "badge badge-warning" },
		expired: { name: "Utgånget", label: "badge badge-dark" },
	};

	addValueForm = new UntypedFormGroup({
		attributeId: new UntypedFormControl(0, [
			Validators.required,
			Validators.min(1),
		]),
		text: new UntypedFormControl("", [Validators.required]),
	});
	addValueError = null;
	addValueWorking = false;

	private itemChangedSubscription: Subscription;

	constructor(
		private itemService: ItemService,
		private route: ActivatedRoute,
		private http: HttpClient,
		public users: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.itemChangedSubscription = this.itemService.itemChanged
			.asObservable()
			.subscribe((item: any) => {
				if (this.item) {
					this.item = { ...this.item, ...item };
					this.change.detectChanges();
				}
			});
		this.route.paramMap.subscribe((params) => {
			let itemID = params.get("itemID");
			this.http.get("/api/items/" + itemID).subscribe(
				(data) => {
					this.item = data;
					this.change.detectChanges();
				},
				(error) => {
					console.log("error");
					this.error = "Hämtning misslyckades: " + error.message;
					this.change.detectChanges();
				}
			);
			this.http.get("/api/sections").subscribe((data: any[]) => {
				this.sections = data;
				this.sections.sort((s1, s2) => s1.sort - s2.sort);
				for (let s of this.sections) {
					s.attributes.sort((a1, a2) => a1.sort - a2.sort);
					s.rows = [];
					for (let a of s.attributes) {
						if (!s.rows[a.sort]) {
							s.rows[a.sort] = [];
						}
						s.rows[a.sort].push(a);
					}
				}
				this.change.detectChanges();
			});
			this.http
				.get("/api/items/" + itemID + "/values")
				.subscribe((data: any[]) => {
					this.values = {};
					for (let value of data) {
						this.values[value.attribute.id] = value;
					}
					this.change.detectChanges();
				});
			this.http
				.get(`/api/items/${itemID}/export/all`)
				.subscribe((data) => {
					this.exportTexts = data;
					this.change.detectChanges();
				});
		});
	}

	ngOnDestroy() {
		this.itemChangedSubscription.unsubscribe();
	}

	saveValue(attribute, newText) {
		let current = this.values[attribute.id];
		let currentText = current ? current.text : "";
		if (currentText == newText) {
			return; // No change
		}
		attribute.working = true;
		attribute.error = null;
		let payload = {
			attributeId: attribute.id,
			text: newText,
		};
		this.http
			.put("/api/items/" + this.item.id + "/values", payload)
			.subscribe(
				(data: Value) => {
					this.values[data.attribute.id] = data;
				},
				(error) => {
					attribute.error = "Fel: " + error.status;
				}
			)
			.add(() => {
				attribute.working = false;
				this.change.detectChanges();
			});
	}
}
