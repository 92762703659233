import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { FileService } from "core/files/files.service";
import { UsersService } from "core/users/users.service";
import { RequestForm } from "../../../../partials/form-state/form-state.component";

@Component({
	selector: "user-roles",
	templateUrl: "./user-roles.component.html",
	styleUrls: ["./user-roles.component.scss"],
})
export class UserRolesComponent implements OnInit {
	@Input() user: any;
	error = null;

	roleForm = new RequestForm(
		true,
		new UntypedFormGroup({
			role: new UntypedFormControl("", Validators.required),
		})
	);

	constructor(
		private http: HttpClient,
		private fileService: FileService,
		private userService: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {}

	addRole() {
		if (this.roleForm.working) return;

		this.roleForm.setWorking();
		this.http
			.post(`/api/users/${this.user.id}/roles`, this.roleForm.getValue())
			.subscribe(
				(response: any) => {
					this.user.roles.push(response);
					this.roleForm.reset();
				},
				(error) => this.roleForm.setError(error.error)
			)
			.add(() => this.change.detectChanges());
	}

	removeRole(role: any) {
		if (this.roleForm.working) return;

		this.roleForm.setWorking();
		this.http
			.delete(`/api/users/${this.user.id}/roles/${role.role}`)
			.subscribe(
				() => {
					const index = this.user.roles.indexOf(role, 0);
					if (index > -1) {
						this.user.roles.splice(index, 1);
					}
					this.roleForm.reset();
				},
				(error) => this.roleForm.setError(error.error)
			)
			.add(() => this.change.detectChanges());
	}
}
