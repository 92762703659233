<div class="page-padding">
	<div class="error-list" *ngIf="errors.length > 0">
		<div class="alert alert-danger" *ngFor="let error of errors">
			{{ error }}
		</div>
	</div>

	<goal-stats class="desktop-only"></goal-stats>

	<card-spacer class="desktop-only"></card-spacer>

	<div class="row">
		<div class="col">
			<mat-card *ngIf="todo && me">
				<mat-card-header>
					<mat-card-title>Att göra</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table">
						<tr>
							<th colspan="2">Objekt</th>
						</tr>
						<tr>
							<td>Uthyrda, saknar arvode</td>
							<td class="todoCount">
								<span
									class="badge badge-secondary"
									*ngIf="todo.itemsRentedIncomplete == 0"
									>0</span
								>
								<a
									*ngIf="todo.itemsRentedIncomplete > 0"
									href="/items?user={{ me.id }}&state=rented"
									class="badge badge-primary"
									>{{ todo.itemsRentedIncomplete }}</a
								>
							</td>
						</tr>
						<tr>
							<td>Återtagna, saknar arvode</td>
							<td class="todoCount">
								<span
									class="badge badge-secondary"
									*ngIf="todo.itemsWithdrawnIncomplete == 0"
									>0</span
								>
								<a
									*ngIf="todo.itemsWithdrawnIncomplete > 0"
									href="/items?user={{
										me.id
									}}&state=withdrawn"
									class="badge badge-primary"
									>{{ todo.itemsWithdrawnIncomplete }}</a
								>
							</td>
						</tr>
						<tr>
							<td>Publicerade, saknar lokaltyp</td>
							<td class="todoCount">
								<span
									class="badge badge-secondary"
									*ngIf="todo.itemsMissingType == 0"
									>0</span
								>
								<a
									*ngIf="todo.itemsMissingType > 0"
									href="/items?user={{
										me.id
									}}&state=available&types=Saknas"
									class="badge badge-danger"
									>{{ todo.itemsMissingType }}</a
								>
							</td>
						</tr>
						<tr>
							<th colspan="2">Fakturor</th>
						</tr>
						<tr>
							<td>Förfallna</td>
							<td class="todoCount">
								<span
									class="badge badge-secondary"
									*ngIf="todo.invoicesOverdue == 0"
									>0</span
								>
								<a
									*ngIf="todo.invoicesOverdue > 0"
									href="/invoices?user={{
										me.id
									}}&state=overdue"
									class="badge badge-warning"
									>{{ todo.invoicesOverdue }}</a
								>
							</td>
						</tr>
					</table>
				</mat-card-content>
			</mat-card>

			<card-spacer></card-spacer>

			<subscribe-form></subscribe-form>
		</div>
		<div class="col">
			<mat-card *ngIf="leadsUnanswered">
				<mat-card-header>
					<mat-card-title>Obesvarade förfrågningar</mat-card-title>
				</mat-card-header>
				<mat-card-content
					><table class="table">
						<div
							*ngFor="let lead of leadsUnanswered"
							class="lead hover-link"
						>
							<a href="/leads/{{ lead.id }}">
								<div class="lead-source" *ngIf="lead.source">
									<span class="badge badge-secondary">{{
										lead.source
									}}</span>
								</div>
								<div class="lead-item-title">
									{{ lead.item?.title }}
								</div>
								<div
									class="lead-title"
									*ngIf="!lead.item?.title"
								>
									{{ lead.title }}
								</div>
								<div class="lead-company">
									{{ lead.company }}
								</div>
							</a>
						</div>
					</table></mat-card-content
				>
			</mat-card>

			<card-spacer></card-spacer>

			<mat-card *ngIf="items">
				<mat-card-header>
					<mat-card-title>Nya objekt</mat-card-title>
				</mat-card-header>
				<mat-card-content
					><table class="table">
						<tr *ngFor="let item of items">
							<td>
								<table-link
									path="items"
									[entity]="item"
								></table-link>
							</td>
							<td class="user">
								<span *ngFor="let c of item.contacts">
									<user-link [user]="c.user"></user-link>
								</span>
							</td>
						</tr></table
				></mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
