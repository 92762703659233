import { Component, OnInit } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "kt-item-new",
	templateUrl: "./item-new.component.html",
	styleUrls: ["./item-new.component.scss"],
})
export class ItemNewComponent implements OnInit {
	working = false;
	error = null;

	createForm = new UntypedFormGroup({
		name: new UntypedFormControl("", [Validators.required]),
		property: new UntypedFormControl("", [Validators.required]),
		municipality: new UntypedFormControl("", [Validators.required]),
	});

	constructor(
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit() {}

	submitItem() {
		this.error = null;
		this.working = true;
		this.http
			.post("/api/items", this.createForm.value)
			.subscribe(
				(data: any) => this.router.navigate(["/items", data.id]),
				(error) => (this.error = "Fel: " + error.status)
			)
			.add(() => {
				this.working = false;
				this.change.detectChanges();
			});
	}
}
