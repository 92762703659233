<div mat-dialog-title>
	<div *ngIf="working">Laddar...</div>
	<div *ngIf="!working">Export färdig</div>
</div>
<hr />
<mat-dialog-content>
	<div *ngIf="error" class="alert alert-danger">{{ error }}</div>
	<div *ngFor="let w of warnings" class="alert alert-warning">{{ w }}</div>
	<div
		*ngIf="!working && !error"
		class="img-container"
		style=" background-image: url('{{ mapurl }}');"
	></div>
</mat-dialog-content>
<hr />
<mat-dialog-actions style="justify-content: center">
	<div *ngIf="!working && !error" style="margin: 20px; display: flex">
		<div class="col">
			<form [formGroup]="mapZoomForm" (change)="updateMap()">
				<select formControlName="zoomLevel" class="form-control">
					<option *ngFor="let z of zoomLevels" [value]="z.value">
						{{ z.title }}
					</option>
				</select>
			</form>
		</div>
		<div class="col">
			<a href="{{ mapurl }}" class="btn btn-primary" download
				>Ladda ned</a
			>
		</div>
	</div>
</mat-dialog-actions>
